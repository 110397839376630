import axios from "../axios-interceptor"
import moment from "moment"
import { useState, useEffect } from "react"
import { base_url,date_formats,downloadPdf,api_error_message,random_number, user_maintenance_type } from "../utilities"
import { useHistory, useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { OverlayTrigger,Tooltip } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import {toast } from 'react-toastify';
import withMaintenanceCheck from "../HigherOrderComponents/maintenancePageChecker";
 const PastCourse = () => {
  let [past_courses, setPastCourse] = useState([])
  let [loading_flag, setLoading_flag] = useState(true)
  let [dynamic_badge, SetDynamicBadge] = useState({});
  const history = useHistory();
  useEffect(() => {
    axios.post(base_url + 'courses/get_past_courses', {
      user_id: localStorage.getItem("user_id")
    }).then(res => {
      setPastCourse(res.data.data)
      setLoading_flag(false)
    }).catch(err => {
      setLoading_flag(false)
      console.log(err);
      if(err.response?.statusText=='Unauthorized'){
        localStorage.clear();
        history.push('/')
        window.location.reload();
      }else if(err.message == 'Request aborted'  || err.message=== "Network Error"){
        window.location.reload()
      }else{
        //toast.warn(api_error_message,{toastId:random_number});
window.location.reload()
      }
    })
  }, [])
  const redirectToModulePage = (id, cohort_id) => {
    localStorage.setItem(
      "cohort_id",
      cohort_id
    );
    localStorage.setItem("user_id", localStorage.getItem("user_id"));
    localStorage.setItem(
      "course_id",
      id
    );
    history.push("/modulepage")
  };
  const viewCertificate = () => {
    history.push("/viewcertificate")
  }
  const showInvoice = (payment) => {
    history.push("/invoice", { payment: payment })
  }
  const ShowDynamicBadge =  (id) => { 
    SetDynamicBadge({})
    SetDynamicBadge(prevFlags => ({ ...prevFlags, [id]: true }));
  }

  const HideDynamicBadge =  (id) => {
    SetDynamicBadge(prevFlags => ({ ...prevFlags, [id]: false }));
  }
  return (

    <>
      {loading_flag ? (
    <div>
    <img className="atrium_loader" src="images/atrium_loader.gif" alt="Loader" />
  </div>
      ) : <>
        <div className="container myContainerCls mt-3">

          <img src="/images/top_banner_past_course.svg"></img>
          {
            past_courses.map((item, index) => {
              return (
                <>
                  <div className="container myContainerCls bg-body shadow-sm mt-3" key={index}>
                  <div className="containe form_field_container_cls_online_applictn">
                  <div className="d-flex justify-content-between">
                        <div className="certificate-card-heading">
                          <div>
                            <span className="certificatesHead">
                            {item.course_title}
                            </span>
                          </div>
                          <div className="certificatesSubhead">
                            <span>{"Completed"}</span>{" "}
                            <span className="font-weight-bold">
                            {date_formats.human_date_format(item.course_completion_date)}
                            </span>
                          </div>
                        </div>
                      </div>
                    <div className="row mt-4">
                      <div className="col-4">
                        <p className="blue_text certificate-class mb-0 certificate-text">Certificate</p>
                        <img className="course_certificate_img" src="/images/course_certificate.svg"></img>
                        <div>
                        <>
                          { (item.certificate_url) ? 
                            <button className="btn my_outline_button_cls submit_button fw-bold my_certificate_btn" onClick={() => downloadPdf(item.certificate_url)} download>Download Certificate</button>
                          :
                          <a className="btn my_outline_button_cls submit_button fw-bold my_certificate_btn course_certificate_img" href="/images/course_certificate.svg"  download>Download Certificate</a>
                          }
                        </>
                        </div>
                      </div>
                      <div className="col-4">
                        <p className="blue_text badges-class certificate-badgetext ms-2">Badges</p>
                        <div
                                    className="d-flex"
                                    style={{ marginLeft: "-12px" }}
                                  >
               {item.modules.map(module=>{
                return(
                    <>
                    { ( module.module_badge == 0 ) ? 

                       <> { module.is_module_completed == true ? dynamic_badge["registerTip0" + module._id +module.start_date] ? <OverlayTrigger placement="top" overlay={
                        <Tooltip className="dashboard_tooltip"
                        id={"registerTip0" + module._id +module.start_date}>
                          <span>
                          {module?.module_title}
                          </span>
                          <br />
                          <span>
                          You Missed This Badge
                          </span>
                          
                        </Tooltip>} offset={[0,20]}>
                        <div  onMouseEnter={() => ShowDynamicBadge("registerTip0" + module._id +module.start_date)} className="transparent_isb_badge"  id={"registerTip0" + module._id +module.start_date} onMouseLeave={() => HideDynamicBadge("registerTip0" + module._id +module.start_date)} >
                          <div className="isb_badge_text">
                            <p className="text-center mb-2 white_color">{module?.module_title?.length >= 18 ?  module?.module_title.slice(0, 18)+ '...' : module?.module_title }</p>
                            <p className="text-center white_color">{item?.course_title?.length >= 18 ? item?.course_title.slice(0, 18) + '...' : item?.course_title }</p>
                          </div>
                        </div>
                      </OverlayTrigger> :  
                      <div  onMouseEnter={() => ShowDynamicBadge("registerTip0" + module._id +module.start_date)} className="transparent_isb_badge"  id={"registerTip0" + module._id +module.start_date}>
                        <div className="isb_badge_text">
                          <p className="text-center mb-2 white_color">{module?.module_title}</p>
                          <p className="text-center white_color">{item.course_title}</p>
                        </div>
                      </div>
                     : dynamic_badge["registerTip0" + module._id +module.start_date] ? <OverlayTrigger placement="top" overlay={
                      <Tooltip className="dashboard_tooltip"
                      id={"registerTip0" + module._id +module.start_date}>
                        <span>
                        {module?.module_title}
                        </span>
                        <br />
                        {/* <span>
                        You Missed This Badge
                        </span> */}
                        
                      </Tooltip>} offset={[0,20]}>
                      <div  onMouseEnter={() => ShowDynamicBadge("registerTip0" + module._id +module.start_date)} className="transparent_isb_badge"  id={"registerTip0" + module._id +module.start_date} onMouseLeave={() => HideDynamicBadge("registerTip0" + module._id +module.start_date)} >
                        <div className="isb_badge_text">
                          <p className="text-center mb-2 white_color">{module?.module_title?.length >= 18 ?  module?.module_title.slice(0, 18)+ '...' : module?.module_title }</p>
                          <p className="text-center white_color">{item?.course_title?.length >= 18 ? item?.course_title.slice(0, 18) + '...' : item?.course_title }</p>
                        </div>
                      </div>
                    </OverlayTrigger> :  
                    <div  onMouseEnter={() => ShowDynamicBadge("registerTip0" + module._id +module.start_date)} className="transparent_isb_badge"  id={"registerTip0" + module._id +module.start_date}>
                      <div className="isb_badge_text">
                        <p className="text-center mb-2 white_color">{module?.module_title}</p>
                        <p className="text-center white_color">{item.course_title}</p>
                      </div>
                    </div>}</>
                    :
                    <>{
                      module.is_module_completed == true &&
                    dynamic_badge["registerTip2" + module._id +module.start_date] ? <OverlayTrigger placement="top" overlay={
                      <Tooltip className="dashboard_tooltip"
                      id={"registerTip2" + module._id +module.start_date}>
                        <span>
                          {module?.module_title}
                        </span>
                        <br />
                        <span>
                          You Won The Badge
                        </span>
                      
                      </Tooltip>} offset={[0,20]}>
                      <div  onMouseEnter={() => ShowDynamicBadge("registerTip2" + module._id +module.start_date)} className="isb_badge"  id={"registerTip2" + module._id +module.start_date} onMouseLeave={() => HideDynamicBadge("registerTip2" + module._id +module.start_date)} >
                        <div className="isb_badge_text">
                          <p className="text-center mb-2 white_color">{module?.module_title?.length >= 18 ?  module?.module_title.slice(0, 18)+ '...' : module?.module_title }</p>
                          <p className="text-center white_color">{item?.course_title?.length >= 18 ? item?.course_title.slice(0, 18) + '...' : item?.course_title }</p>
                        </div>
                      </div>
                    </OverlayTrigger> :  
                    <div  onMouseEnter={() => ShowDynamicBadge("registerTip2" + module._id +module.start_date)} className="isb_badge"  id={"registerTip2" + module._id +module.start_date}>
                      <div className="isb_badge_text">
                        <p className="text-center mb-2 white_color">{module?.module_title}</p>
                        <p className="text-center white_color">{item?.course_title}</p>
                      </div>
                    </div>
                    }
                   </>
                     }
                    </>
                )
               })}
               </div>
                      </div>
                      <div className="col-4">
                        <div className="p-5">
                          <p className="blue_text badges-class">Stats</p>
                          <p><b>{item.total_earned_score} </b>/ {item.total_course_score}  Points</p>
                          <p className="blue_text badges-class">Stats</p>
                          <p><b>{Math.floor(moment.duration(item.total_time_spent, 'seconds').asHours()) != 0 ? Math.floor(moment.duration(item.total_time_spent, 'seconds').asHours()) + ' hrs ' + moment.duration(item.total_time_spent, 'seconds').minutes() + ' min(s) ' : moment.duration(item.total_time_spent, 'seconds').minutes() + ' min(s) '}</b></p>
                        </div>
                      </div>

                    </div>
                    {/* <div className="row d-flex justify-content-between">
                      <div className="col-4">
                        <div className="certificate-text">
                          <button className="btn my_btn_cls submit_button" style={{ backgroundColor: "#708FAB" }} onClick={viewCertificate}>View</button> &nbsp;
                          <>
                            { (item.certificate_url) ? 
                              <button className="btn my_btn_cls submit_button fw-bold" onClick={() => downloadPdf(item.certificate_url)} download>Download</button>
                            :
                              <a className="btn my_btn_cls submit_button fw-bold" href="/images/course_certificate.svg" download>Download</a>
                            }
                          </>
                        </div>
                      </div>
                      <div className="col-6">
                      </div>
                      <div className="col-2 text-end">
                        <button className="btn my_btn_cls submit_button" style={{ backgroundColor: "#66266B" }} onClick={() => showInvoice(item.payment_details)}>View Invoice</button>
                      </div>
                    </div> */}
                    </div>
                  </div>
                </>
              )
            })
          }
          {past_courses.length == 0 &&  <div class="mt-5 text-center past_course_center_align">
            <img height="38px" src="./images/BlueBook.png"></img>
            <h5 className="past_course_title text-center mt-4">No past courses yet</h5>
            <p className="text-center ash">You will see your past courses here once you have completed<br></br>at least one course. Click below to return to the dashboard to <br></br> continue your current course.</p>
            <button className="btn my_btn_cls submit_button m-0 px-4 py-2" onClick={() => history.push("/dashboard")}>Go to Dashboard</button>
          </div>}
        </div>
      </>
      }
    </>
  )
}

export default withMaintenanceCheck(PastCourse,user_maintenance_type) 