
import axios from "../../axios-interceptor";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { base_url,api_error_message,random_number, admin_maintenance_type} from "../../utilities";
import { Table } from "react-bootstrap";
import { toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import withMaintenanceCheck from "../../HigherOrderComponents/maintenancePageChecker";
 const EditSLAs = (props)=>{
  const history = useHistory();
  const location = useLocation();
  let [sla_data,setSlaData]=useState(null)
  let [loading_flag,SetLoading_flag]=useState(true)
  let [listOfModules,setListOfModules]=useState({})
    useEffect(() => {
        const SLaData=location.state?.Sla_Data
        if(!SLaData) handleBackCreateSLA()
        setSlaData(SLaData)
        axios.post(base_url+'sla/get_sla_modules_list',{"course_id":SLaData?.course_id ,
        "program_id":SLaData?.program_id })
        .then(res=>{
           let {program_id,program_name}=res?.data?.data
            res.data?.data?.modules?.map(module=>{
                module['program_id']=program_id
                module['program_name']=program_name
            })
            setListOfModules(res?.data?.data)
            SetLoading_flag(false)
        })
        .catch(err=>{
            console.log(err);
            if(err.response?.statusText=='Unauthorized' || err.response?.data?.statusText=="Unauthorized"){
                localStorage.clear();
                history.push('/adminlogin')
                window.location.reload();
            }else if(err.message == 'Request aborted'){
                window.location.reload()
              }else{
                //toast.warn(api_error_message,{toastId:random_number});
window.location.reload()
            }
        })

    }, [])
    const openHeadout=(item)=>
    {
        history.push('/admindashboard/handoutsla',{'handout_item':item,'Sla_Data':sla_data})
    }
    const handleBackCreateSLA=(e)=>{
        history.push('/admindashboard/createsla')
    }
    return(
        <>
        <div className="bg-body container ">
        <div className="d-block mb-4">
                        <span>
                            <button  className="btn  SLA_Edit_Back mt-0" onClick={handleBackCreateSLA}>BACK</button>
                        </span>
                    </div>
           <div className="space-left">
            <div>
               <span className="SLA_Main_heading" data-testid="main_heading">Edit Social Learning Activities</span>
            </div>
            <div className="mt-3">
               <span className="SLA_edit_sub_heading">{sla_data?.course_name} <span><i class="fa-solid fa-chevron-right sla_right_arrow"></i></span> {sla_data?.program_name}</span>
            </div>
           </div>
             <div className="mt-1 table_space">
                {loading_flag?
                <>
                    <div className="minheight50 ">
                        <img className="atrium_loader" src="/images/atrium_loader.gif" alt="Loader" />
                    </div>
                </>:
                <>
                    <Table className="createSLA-table table-striped" bordered={false} id="editSLA_table" striped>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th className="d-none">Set Pre
                                        <br />
                                     Questions</th>
                                    <th>Set 
                                        <br />
                                    Handouts</th>
                                    <th className="d-none">Set Post
                                        <br />
                                     Questions</th>
                                </tr>
                            </thead>
                            <tbody>
                            {listOfModules?.modules?.map((item, index) => {
                                return (
                                <tr key={index} className="Edit_SLA_row ">
                                    <td>{`Module ${index+1} - `}{item.module_name} - {item.lesson_name}</td>
                                    <td className="icon-set d-none">
                                        <div className="Editsla_table_icons">
                                            <span>
                                               <i class="fa-regular fa-pen-to-square cursor-pointer"></i>
                                            </span>
                                            {/* <span>
                                               <i class="fa-solid fa-circle-check circle_icon"></i>
                                            </span> */}
                                        </div>
                                    </td>
                                    <td className="icon-set">
                                        <div className="Editsla_table_icons">
                                            <span>
                                            <img className="memo_img cursor-pointer" src="/images/handouts.svg" alt="" data-testid={`openHead_${index+1}`} onClick={()=>openHeadout(item)} />
                                            </span>
                                            {/* <span>
                                               <i class="fa-solid fa-circle-check circle_icon"></i>
                                            </span> */}
                                        </div>
                                    </td>
                                    <td className="icon-set d-none">
                                        <div className="Editsla_table_icons">
                                            <span>
                                            <i class="fa-regular fa-pen-to-square cursor-pointer"></i>
                                            </span>
                                            {/* <span>
                                               <i class="fa-solid fa-circle-check circle_icon"></i>
                                            </span> */}
                                        </div>
                                    </td>
                                </tr>
                                );
                            })}
                            </tbody>
                </Table>
                </>}
             </div>
        </div>
        </>
    )
}

export default withMaintenanceCheck(EditSLAs,admin_maintenance_type)