import Form from "react-bootstrap/Form";
import { Container } from "react-bootstrap";
import { useRef, useState, useEffect,Fragment} from "react";

import { ActivityElementMultipleChoice } from "./activityElementMultipleChoice";
import { ActivityElementCheckAllApply } from "./activityElementCheckAllApply";
import { ActivityElementShortAnswer } from "./activityElementShortAnswer";
import { ActivityElementOrdering } from "./activityElementOrdering";
import { ActivityElementDragAndDrop } from "./activityElementDragAndDrop";
import { ActivityElementMultipleInput } from "./activityElementMultipleInput";
import {ActivityInput} from './activityInput'
import { VideoComponent } from "./videoComponent";
import { DataComponent } from "./dataComponent";
import { ImageDataComponent } from "./imageDataComponent";
import { ImageComponent } from "./imageComponent";
import { ObjectiveComponent } from "./objectivesComponent";
import { QuoteElement } from "./quoteElement";
import { FeedbackMultipleChoice } from "./feedbackMultipleChoice";
import { FeedbackOpenResponse } from "./feedbackOpenResponse";
import { AboutQuizComponent } from "./aboutQuizComponent";
import { ActivityFileUpload } from "./activityFileUpload";
import axios from "../../axios-interceptor";
import { base_url,api_error_message,random_number, user_maintenance_type} from "../../utilities";
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import withMaintenanceCheck from "../../HigherOrderComponents/maintenancePageChecker";
import { useParams } from "react-router-dom";


 const PreviewPage = (props) => {
  // const { preview_id } = useParams;
  var data = {}, SubTagName = `span`, key = 0, list_content = '<li>';;
  var object_data = [{}];
  var [purpose,setPurpose] = useState('')
  const [final_data, setFinalData] = useState([]);
  const [isNavbarExist, setIsNavbarExist] = useState(false);
  const [valid_json, setValidJson] = useState(true);
  const [preview_json, setPreviewJson] = useState({});
  const location = useLocation();
  const history = useHistory();
  
  useEffect(() => {
    document.getElementById('top-nav-bar').style.display = 'none';
    const fetchData = async () => {
      try {
        axios.post(base_url+'preview_component/get_preview_component',{
          "id":window.location.pathname.split('/').pop()
        })
        .then(res=>{
          let jsonstring = JSON.stringify(res.data.result[0].json_content)
          try{
            JSON.parse(jsonstring);
              setValidJson(true);
              setPreviewJson(res.data.result[0].json_content);
              handleCallback(res.data.result[0].json_content);
          }catch (err){
            setValidJson(false);
            console.error("Invalid JSON");
          }
        })
        .catch(err=>{
          console.log(err);
          if(err.response?.statusText=='Unauthorized'){
            localStorage.clear();
            history.push('/adminlogin')
            window.location.reload();
          }else if(err.message == 'Request aborted'){
            window.location.reload()
          }else{
            //toast.warn(api_error_message,{toastId:random_number});
window.location.reload()
          }
        })
      } catch (error) {
        console.log(error);
          if(error.response?.statusText=='Unauthorized'){
            localStorage.clear();
            history.push('/adminlogin')
            window.location.reload();
          }else if(error.message == 'Request aborted'){
            window.location.reload()
          }else{
            //toast.warn(api_error_message,{toastId:random_number});
window.location.reload()
          }
      }
    };
  
    fetchData();
  }, []);
  
  const handleCallback = (previewData) => {
    data = previewData
    function recursive_title_data(value) {
      if (value?.children) {
        for (const value1 of value.children) {
          recursive_title_data(value1.children)
        }
      } else {
        let str = '';
        for (const value1 of value) {
          str = str + value1.text
        }
        str = str?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")
        return str;
      }
    }
    let str_ul="";
    let str_ul_count=0
    function recursive_ul_data(data){
      for(const value of data){ 
        if(value?.type !== 'li'){
          str_ul='<ul class="summary_uList_cls">';
          str_ul_count++;
          recursive_ul_data(value.children)
        }else{          
          let str_li = '<ul class="summary_uList_cls"><li class="summary_list_cls">';
          for(const value1 of value.children){
            if (value1?.strong) {
              str_li += '<strong class="summary_header_cls">';
              str_li += value1.text;
              str_li += '</strong>';
            }else if (value1?.em) {
              str_li += '<em class="summary_header_cls">';
              str_li += value1.text;
              str_li += '</em>';
            }else{
              str_li += value1.text?value1.text:""
            }
            if (value1?.type === 'a') {
              str_li +=`<a class="Article_link articleWithOutSpan" href='${value1.href}' target='_blank' onclick="localStorage.setItem('artcle_LinkClicked', true)" >`
              if (value1?.children) {
                for (const value2 of value1.children) {
                  str_li +=value2.text?value2.text:""
                }
              }
              str_li +='</a>'
            }
          }
          str_li += '</li>' 
          str_li = str_li?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")
          object_data[key]['str_data'] = object_data[key]?.str_data ? 
          object_data[key].str_data+(str_ul!==""?str_ul:"")+str_li + '</ul>'+(str_ul_count!==0?'</ul>':""): 
          (str_ul!==""?str_ul:"")+ str_li + '</ul>'+(str_ul_count!==0?'</ul>':"");
        }
      }
    }
    function recursive_ol_data(data){
      let str_li = '<ol class="summary_uList_cls">';
      for(const value of data){   
        str_li += '<li class="summary_list_cls">'      
          for(const value1 of value.children){
          
            if (value1?.strong) {
              str_li += '<strong class="summary_header_cls">';
              str_li += value1?.text;
              str_li += '</strong>';
            }else{
              str_li += value1?.text != undefined?value1?.text : ''
            }
            if (value1?.type === 'a') {
              str_li =  str_li + `<a class="Article_link articleWithOutSpan" href='${value1.href}' target='_blank' onclick="localStorage.setItem('artcle_LinkClicked', true)">`
              if (value1?.children) {
                for (const value2 of value1.children) {
                  str_li =  str_li + value2.text
                }
              }
              str_li =  str_li + '</a>'
            }
          }
          str_li += '</li>' 
        }
        str_li = str_li?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")
        object_data[key]['str_data'] = object_data[key]?.str_data ? object_data[key].str_data + str_li + '</ol>' : str_li + '</ol>';
    }
    function recursive_table_data(data){
      let str_table = '<table class="table">'
      for(const value of data){ 
        if(value?.type == 'tr'){
          str_table += '<tr>';
          for(const value1 of value.children){
            if(value1.type == 'td'){
              for(const value2 of value1.children){
                if (value2.children[0]?.strong) {
                  str_table += '<td><strong class="summary_header_cls">';
                  str_table += value2.children[0].text;
                  str_table += '</strong></td>';
                }else{
                  str_table += '<td><span class="summary_header_cls">'+value2.children[0].text+'</span></td>'
                }
              }
            }
          }
          str_table += '</tr>' 
        }
      }
      str_table += '</table>' 
      str_table = str_table?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")
      object_data[key]['str_data'] = object_data[key]?.str_data ? object_data[key].str_data + str_table : str_table;
    }
    function recursive_content_data(data) {
      let str = '';
      if (data?.type === 'p') {
        let itemArr=[]
        const childLength=data.children.length
        data.children.filter(obj=>{
          if(obj.strong)
          {
            itemArr.push("strong")
          }
          else{
            if(obj.text!=="")
            {
              itemArr.push("text")
            }
          }
        })
        const contentHasStrongItem=itemArr.filter(item=>item=="strong")
        let textandAchorTagcombination=false;
        let isTextAnchorTextFormat=false;
        //check whether data.childeren is combined with text and anchor tag
        data.children.filter((obj,index)=>{
          if(obj.type=="a")
          {
            if(data.children[index-1])
            {
              if(data.children[index-1]['text']&&data.children[index-1]['text']!=='')
                {
                  textandAchorTagcombination=true;
                  if(data.children[index+1])
                  {
                    if(data.children[index+1]['text']&&data.children[index+1]['text']!=='')
                    {
                      isTextAnchorTextFormat=true
                    }
                  }
                }
            } 
          }
        })
        for (const [index, value] of data.children.entries()) {
          if(index==data.children.length-1)
          {
            isTextAnchorTextFormat=false;
          }
          if (value?.type === 'a') {
            str = str + `<a class="Article_link ${textandAchorTagcombination?'articleWithSpan':'articleWithOutSpan'}" href='${value.href}' target='_blank' onclick="localStorage.setItem('artcle_LinkClicked', true)">`
            if (value?.children) {
              for (const value1 of value.children) {
                str = str + value1.text
              }
            }
            str = str + '</a>'
          } else {
              if (value?.strong) {
                str += `<strong class="summary_header_cls ${value?.text?.toLocaleLowerCase()?.includes("disclaimer")? "fs-16 font-italic" :""}">`;
                str += '<span> '+value.text+'</span>';
                str += '</strong>';
              }
              else if (value?.em) {
                str += `<em class="summary_header_cls ${data.children[0]?.text?.toLocaleLowerCase()?.includes("disclaimer")? "fs-16" :""}">`;
                str += value.text.charAt(0)=="."||value.text.charAt(0)==","? value.text: " "+value.text
                str += '</em>';
              }
              else {
                str += `<span class="summary_header_cls ${(contentHasStrongItem.length>0&&childLength==3 )? "":(value.text !== "" ?(contentHasStrongItem.length>0&&childLength==2||isTextAnchorTextFormat)?"d-inline pb-4":"d-inline-block pb-4" : "")}">`;
                str += value.text.charAt(0)=="."||value.text.charAt(0)==","? value.text: " "+value.text;
                str += '</span>';
              }
          }
        }
        if(str){       
          str = str?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")
        }
        object_data[key]['str_data'] = object_data[key]?.str_data ? object_data[key].str_data + str + '<br/>' : str + '<br/>';
        if(data.src){
          object_data[key]['video'] = data.src[0].url
        }
      }
      if(data?.type === 'ul'){
        str_ul="";
        str_ul_count=0
        recursive_ul_data(data.children);   
      }
      if(data?.type === 'ol'){
        recursive_ol_data(data.children);   
      }
      if(data?.type === 'table'){
        recursive_table_data(data.children);   
      }
      if (data?.type === 'img') {
        object_data[key]['img'] = data.src
      }
      if (data?.type === 'video') {
        object_data[key]['video'] = data.src[0].url
      }
      if (data?.type === 'iframe') {
        object_data[key]['video'] = data.src
      }
      if(object_data[key]?.str_data === '<span class="summary_header_cls"></span><br/>'){
        object_data[key].str_data = '';
      }
    }

    function recursive_data(value) {
      let h_data = '', s_data = '', l_data = '';
      if (value.type == 'content' && value?.children.length > 0) {
        for (const value1 of value.children) {
          if (value1.type == 'h2' || value1.type == 'h1') {
            h_data = recursive_title_data(value1.children);
            h_data = h_data?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")
            object_data[key]['title_data'] = object_data[key]?.title_data ? object_data[key].title_data + h_data : h_data;
          }
          else {
            recursive_content_data(value1);
          }
        }
      } else if (value.type == 'activity-reference') {
        object_data[key] = {};
        object_data[key]['activity'] = value;
      }
    }
    if (data.type == 'group' && data?.children.length > 0) {
      setPurpose(data.purpose)
   
      for (const value of data.children) {
        recursive_data(value);
        key++;
      }
      setFinalData(object_data);
    }
  };
  
  const handleCallbackChild = (flag) => {
    console.log(flag)
  };
  return (
    <>
  <div className={`preview_page ${isNavbarExist ? "mt-5" : ""}`}>
    {setValidJson ? (
      final_data.map((data, i) => (
        <Fragment key={i}>
          {purpose === "walkthrough" ? (
            <ObjectiveComponent i={props.index} data={data} purpose={purpose} />
          ) : (
            <>
              <div className="container myContainerCls mt-5" key={i}>
                    <div>
                      {data?.activity?.activity_id ? (
                        <>
                            {data.activity.children[0]  && props.data?.purpose != 'feedback' &&
                              data.activity.children[0].subType == "oli_multiple_choice" ? (
                              <ActivityElementMultipleChoice i={props.index}
                                data={data.activity.children[0]}  parentCallback={handleCallbackChild}
                              title={data.title_data} purpose={purpose}></ActivityElementMultipleChoice>
                            ) : data.activity.children[0] &&
                              data.activity.children[0].subType == "oli_check_all_that_apply" ? (
                              <ActivityElementCheckAllApply i={props.index}
                                data={data.activity.children[0]}  parentCallback={handleCallbackChild}
                                title={data.title_data} purpose={purpose} ></ActivityElementCheckAllApply>
                            ) : data.activity.children[0] && props.data?.purpose != 'feedback' && props.data?.purpose != 'labactivity' &&
                              data.activity.children[0].subType == "oli_short_answer" &&  props.data?.purpose != 'manystudentswonder'   ? (
                              <ActivityElementShortAnswer i={props.index}
                                data={data.activity.children[0]}  parentCallback={handleCallbackChild}
                                title={data.title_data} purpose={purpose}></ActivityElementShortAnswer>
                            ) : data.activity.children[0] &&
                              data.activity.children[0].subType == "oli_custom_dnd" ? (
                              <ActivityElementDragAndDrop i={props.index} data={data.activity.children[0]}  parentCallback={handleCallbackChild} title={data.title_data} purpose={purpose}>
                                {" "}
                              </ActivityElementDragAndDrop >
                            ) : data.activity.children[0] && data.activity.children[0].subType == "oli_ordering" ? (
                              <ActivityElementOrdering i={props.index}
                                data={data.activity.children[0]}  parentCallback={handleCallbackChild}
                                title={data.title_data} purpose={purpose}></ActivityElementOrdering>
                            ) : data.activity.children[0] &&
                              data.activity.children[0].subType == "oli_multi_input" ? (
                              <ActivityElementMultipleInput i={props.index} data={data.activity.children[0]}  parentCallback={handleCallbackChild} title={data.title_data} purpose={purpose}></ActivityElementMultipleInput>
                            ) : data && props.data?.purpose == 'feedback' &&
                            data.activity.children[0].subType == "oli_multiple_choice" ?  (
                            <FeedbackMultipleChoice i={props.index} data={data.activity.children[0]}  parentCallback={handleCallbackChild} purpose={purpose}></FeedbackMultipleChoice>
                          ): data && props.data?.purpose == 'feedback' &&
                          data.activity.children[0].subType == "oli_short_answer" ?  (
                          <FeedbackOpenResponse i={props.index} data={data.activity.children[0]}  parentCallback={handleCallbackChild} purpose={purpose}></FeedbackOpenResponse>
                        )
                          :(
                            data && props.data?.purpose == 'about_quiz' &&
                            data.activity.children[0].subType == "about_quiz" ?  (
                            <AboutQuizComponent i={props.index} data={data.activity.children[0]}  parentCallback={handleCallbackChild} purpose={purpose}></AboutQuizComponent>
                          ):data && props.data?.purpose == 'labactivity' && props.data?.children[0]?.children[0]?.content?.inputType != "textarea" &&
                          data.activity.children[0].subType == "oli_short_answer"  ?  (
                          <ActivityInput i={props.index} data={data.activity.children[0]}  parentCallback={handleCallbackChild} purpose={purpose}></ActivityInput>):
                          data && props.data?.purpose == 'manystudentswonder' && props.data?.children[0]?.children[0]?.content?.inputType == "text"  ?  (
                            <ActivityFileUpload i={props.index}
                            data={data.activity.children[0]}  parentCallback={handleCallbackChild}
                            title={data.title_data} purpose={purpose} />):
                         data.activity.children[0] && props.data?.purpose != 'feedback' && props.data?.purpose == 'labactivity' && props.data?.children[0]?.children[0]?.content?.inputType == "textarea" &&
                        data.activity.children[0].subType == "oli_short_answer" ? (
                        <ActivityElementShortAnswer i={props.index}
                          data={data.activity.children[0]}  parentCallback={handleCallbackChild}
                          title={data.title_data} purpose={purpose}></ActivityElementShortAnswer>
                      ):
                              <></>
                            )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {data?.img && !data.title_data && (!data.str_data) && (
                      <ImageComponent data={data} i={props.index} purpose={purpose}></ImageComponent>
                    )}
                    {data?.img && data?.title_data && (!data.str_data) && (props.data?.purpose != "example") &&(
                      <ImageDataComponent data={data} i={props.index} purpose={purpose}></ImageDataComponent>
                    )}
                    {data?.img && (data?.str_data) && (props.data?.purpose != "example") && (
                       
                      <ImageDataComponent data={data} i={props.index} purpose={purpose}></ImageDataComponent>              
                    )}
                    {data?.img && (data?.str_data) && (props.data?.purpose == "example") && (
                        <QuoteElement data={data} i={props.index} purpose={purpose}></QuoteElement>
                                 
                    )}
                    {data?.str_data && (!data.img) && !data.video &&  (
                      <DataComponent data={data} i={props.index} purpose={purpose} />
                    )}
                     {/* {props.data?.purpose == 'manystudentswonder'  && data?.str_data && (!data.img) && !data.video &&  (
                      <ActivityFileUpload data={data} i={props.index} purpose={purpose} />
                    )} */}
                    {data?.video && (
                      <VideoComponent data={data} i={props.index} />
                    )}
                  </div>
            </>
          )}
        </Fragment>
      ))
    ) : (
      <div>Not a Valid Json</div>
    )}
  </div>
</>

  );
};

export default withMaintenanceCheck(PreviewPage,user_maintenance_type)
