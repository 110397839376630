import axios from "../../axios-interceptor";
import { useEffect, useState } from "react";
import { base_url, random_number,api_error_message, admin_maintenance_type } from "../../utilities";
import { toast } from 'react-toastify';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"; 
import { useLocation,useHistory } from "react-router-dom";
import withMaintenanceCheck from "../../HigherOrderComponents/maintenancePageChecker";
import { CSVLink } from "react-csv";

const ProgrammeSla = (props) =>{
  const history = useHistory();
  const location=useLocation()
    let propsData=location.state?.sla_Data
    let [roleList,setRoleList]=useState([])
    let [studentsList,setStudentsList]=useState([])
    let [studentListData,setStudentListData]=useState([])
    let [loading_flag,SetLoading_flag]=useState(true)
    let [saveOrResetloading_flag,SetsaveOrResetloading_flag]=useState(false)
    let [groupsCount,setGroupsCount]=useState(0)
    let [groups,setGroups]=useState(0)
    const [assignedStudents, setAssignedStudents] = useState([]);
    let [columns, setColumns] = useState({});
    const [groupMemebersData,SetGroupMemebersData] =useState([])
    const [rolesData,setRolesData] =useState([])
    const [groupedCsvData, setGroupedCSVData] = useState({});
  useEffect(()=>{
    fetchDataforDragndrop()
},[])

 const fetchDataforDragndrop=()=>{
  let programRoleAssign=JSON.parse(localStorage.getItem('SelectedProgramRoleAssign'))
  if(programRoleAssign) propsData=programRoleAssign
  localStorage.removeItem('SelectedProgramRoleAssign')
localStorage.setItem('programRoleAssign',true)
if(propsData)
{
  SetsaveOrResetloading_flag(true)
    axios.post(base_url+"sla/get_sla_roles",
    { "program_id":propsData?.program_id, "course_id":propsData?.course_id, "module_id":propsData?.module_id })
     .then(roleData=>{
        setRoleList(roleData.data?.result)
        let roleDataArr=[]
        roleData.data?.result.map(role=>{
          roleDataArr.push({roleName:role.name,roleId:role._id})
        })
        setRolesData(roleDataArr)
        axios.post(base_url+"user/get_program_students",
        { "program_id":propsData?.program_id, "course_id":propsData?.course_id})
         .then(studentres=>{
              const uniqueStudentArray = studentres.data?.result.filter((obj, index, self) => {
                const id = obj.user_id && obj.user_id._id;
                return id ? index === self.findIndex(o => o.user_id && o.user_id._id === id) : true;
              });
           setStudentsList(studentres.data?.result)
           //  setRoleList(res.data?.result)
            let names=[]
            let userData=[]
            uniqueStudentArray.map((item,index)=>{
              if(item.user_id)
              {
                const {_id,first_name,last_name,email,user_roles}=item?.user_id
                  names.push(first_name)
                  userData.push({id:_id,first_name:first_name,isUserAuditLearner:(user_roles[0]==3?true:false),last_name:last_name,role:"",role_id:"",email:email,cohort_student_id:item._id})
              }
           })
           setGroups(Math.round(names.length/roleData.data?.result.length))
           setStudentListData(userData)
           let auditUsersCount=userData.filter(item=>item.isUserAuditLearner);
           let groupCount=Math.round(names.length/roleData.data?.result.length)
           setGroupsCount( groupCount );
          let columnData={
            "Unassigned Pool":{
              groupName:"unassigned pool",
              items:userData
            }
           }
           let itemsArr=[]
           roleDataArr.map(roleItem=>{
              itemsArr.push({"id":generateRandomString(),"first_name":"raju",type:"Dummy",role:roleItem.roleName,role_id:roleItem.roleId,group:""})
           })

           for (let i = 1; i <= groupCount; i++) {
             columnData[`Group-${i}`]={groupName:`Group-${i}`,isauditlearnergroup:(i==groupCount&&auditUsersCount.length>0)?true:false,roles:roleDataArr, items:itemsArr}
            }

           axios.post(base_url+"sla/get_group_members",{
            "sla_id": propsData.id
            })
              .then(res=>{
                if(res.data?.data.length>0)
                {
                  // res.data?.data.map(item=>{
                  //   item.isauditlearnergroup=false;
                  // })

                  let groupNumbers=res.data?.data.map(item=>item.group_name)
                  const numbers = groupNumbers.map(element => parseInt(element.split("-")[1]));
                  // Find the maximum number from the array of extracted numbers
                  const maxNumber = Math.max(...numbers);
                  for (let i = groupCount==0?1:groupCount; i <= maxNumber; i++) {
                    columnData[`Group-${i}`]={groupName:`Group-${i}`,roles:roleDataArr, items:itemsArr}
                   }

                   if(groupCount>maxNumber)
                   {
                    let i=groupCount
                    while (i>maxNumber) {
                      delete columnData[`Group-${i}`]
                      i--
                    }
                   }
                   setGroupsCount(maxNumber)
                   setGroups(maxNumber)
                  res.data?.data.forEach(item=>{
                    for (const key in columnData) {
                      if (Object.hasOwnProperty.call(columnData, key)) {
                        if(key===item.group_name)
                        {
                          columnData[key]['isauditlearnergroup']=item.isauditlearnergroup
                          columnData[key]['groupId']=item.group_id
                              item.group_members.forEach(groupMemberObj=>{
                                if(Object.keys(groupMemberObj).length>0)
                                {
                                  let {first_name,last_name,role,role_id,user_id,email,isUserAuditLearner}=groupMemberObj
                                  let singleGrpItems=[...columnData[key].items] 
                                  let userData=columnData["Unassigned Pool"].items.filter(usr=>usr.id==user_id)
                                    if (userData.length>0) {
                                        singleGrpItems.unshift({id:user_id,first_name:first_name,isUserAuditLearner:isUserAuditLearner,last_name:last_name,role:role,role_id:role_id,email:email,cohort_student_id:userData[0].cohort_student_id,groupId:item.group_id})
                                    }
                                    columnData[key].items=singleGrpItems
                                  }
                              })
                        }
                      }
                    }
                  })
                  res.data?.data.map(item=>{
                    item.group_members.map(grpmem=>{
                      columnData["Unassigned Pool"].items=columnData["Unassigned Pool"].items.filter(usr=>usr.id!=grpmem.user_id)
                    })
                  })
                  columnData['Unassigned Pool']['items'].push({"id":generateRandomString(),"first_name":"raju",type:"Dummy"}) 
                  columnData["Unassigned Pool"].items.sort((a, b) => (a.isUserAuditLearner === b.isUserAuditLearner ? 0 : a.isUserAuditLearner ? 1 : -1));
                  setColumns(columnData) 
                  SetGroupMemebersData(res.data?.data);

                  const sortedSlaData = res.data?.data.slice().sort((a, b) => {
                    const groupA = parseInt(a.group_name.match(/\d+/)[0]);
                    const groupB = parseInt(b.group_name.match(/\d+/)[0]);
                  
                    return groupA - groupB;
                  });
                  

                  // const sortedSlaData = res.data?.data.sort((a, b) => {
                  //   const groupA = a.group_name.toUpperCase();
                  //   const groupB = b.group_name.toUpperCase();
              
                  //   if (groupA < groupB) {
                  //     return -1;
                  //   }
                  //   if (groupA > groupB) {
                  //     return 1;
                  //   }
              
                  //   return 0;
                  // });
              
                  // Group the sorted data by group names
                  const newData = {};
                  sortedSlaData.forEach((item) => {
                    const nonEmptyFirstNames = item.group_members.filter(
                      (member) => member.first_name !== ""
                    );
                    if (nonEmptyFirstNames.length > 0) {
                      newData[item.group_name] = nonEmptyFirstNames;
                    }
                  });
              
                  // To further exclude groups with empty arrays or all empty objects
                  for (const groupName in newData) {
                    if (
                      newData[groupName].every((member) => Object.keys(member).length === 0)
                    ) {
                      delete newData[groupName];
                    }
                  }
                  setGroupedCSVData(newData);
                }
                else{
                  columnData['Unassigned Pool']['items'].push({"id":generateRandomString(),"first_name":"raju",type:"Dummy"}) 
                  setColumns(columnData)
                }
                SetsaveOrResetloading_flag(false)
                SetLoading_flag(false)
              })
              .catch(err=>{
                  console.log(err);
                  SetsaveOrResetloading_flag(false);
                  if(err.response?.statusText=='Unauthorized'){
                    localStorage.clear();
                    history.push('/adminlogin')
                    window.location.reload();
                  }else if(err.message == 'Request aborted'){
                    window.location.reload()
                  }else{
                    //toast.warn(api_error_message,{toastId:random_number});
window.location.reload()
                  }
              })
          })
         .catch(err=>{
            console.log(err);
            SetsaveOrResetloading_flag(false);
            if(err.response?.statusText=='Unauthorized'){
              localStorage.clear();
              history.push('/adminlogin')
              window.location.reload();
            }else if(err.message == 'Request aborted'){
              window.location.reload()
            }else{
              //toast.warn(api_error_message,{toastId:random_number});
window.location.reload()
            }
         })
     })
     .catch(err=>{
        console.log(err);
        SetsaveOrResetloading_flag(false);
        if(err.response?.statusText=='Unauthorized'){
          localStorage.clear();
          history.push('/adminlogin')
          window.location.reload();
        }else if(err.message == 'Request aborted'){
          window.location.reload()
        }else{
          //toast.warn(api_error_message,{toastId:random_number});
window.location.reload()
        }
     })
}
}

const groupNamesRow = Object.keys(groupedCsvData).map((groupName) => groupName);
const names_row = Object.keys(groupedCsvData).map((groupName) =>
  groupedCsvData[groupName].map((element) => `${element.first_name} (${element.role})`)
);

// Transpose the csvData to display group names vertically
const transposedData = groupNamesRow.map((name, index) => {
  return [name, ...names_row[index]];
});

// Rearrange the transposedData to display names vertically and add an empty cell after each group
const verticalData = [];
const maxNames = Math.max(...transposedData.map((group) => group.length + 1)); // Add 1 for the empty cell

for (let i = 0; i < maxNames; i++) {
  const rowData = [];
  transposedData.forEach((group, groupIndex) => {
    if (i === 0) {
      // Display group names in the first row
      rowData.push(group[0]);
    } else if (i <= group.length) {
      // Display names for each group if available
      rowData.push(group[i]);
    } else {
      // Add an empty cell for rows beyond the group length
      rowData.push("");
    }

    // Add an empty cell after each group (except the last group)
    if (groupIndex !== transposedData.length - 1) {
      rowData.push("");
    }
  });
  verticalData.push(rowData);
}

const csvData = verticalData;




const  generateRandomString=()=> {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz';

  for (let i = 0; i < 5; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
}

  const resetAll = () =>{
    // localStorage.setItem('SelectedProgramRoleAssign',JSON.stringify(propsData))
    //  window.location.reload()
    SetsaveOrResetloading_flag(true)
    fetchDataforDragndrop()
  } 
  const onSave =() =>{
    SetsaveOrResetloading_flag(true)
    let savobj={"groups_data":[]}
    for (const key in columns) {
      if (Object.hasOwnProperty.call(columns, key)) {
        if(key!=="Unassigned Pool")
        {
          const singleGroup = columns[key];
          const groupName=singleGroup.groupName;
          const singleItems=singleGroup?.items
          let singleObj={
            "group_name": groupName,
            "isauditlearnergroup":singleGroup.isauditlearnergroup,
            "sla_id": propsData.id,
            "group_members":[]
          }
          if(singleGroup.groupId)
          {
            singleObj["_id"]=singleGroup.groupId
          } 
          if(singleItems.length>1)
          {
            singleItems.map(item=>{
              if(item?.type==undefined)
              {
                singleObj.group_members.push(
                {
                  "cohort_student_id": item.cohort_student_id,
                  "user_id": item.id,
                  "isUserAuditLearner": item.isUserAuditLearner,
                  "role_id": item.role_id,
                }
                )
              }
            })

            savobj.groups_data.push(singleObj)
          }
          else if(singleObj._id){
            savobj.groups_data.push(singleObj)
          }
        }
        
      }
    }
    const allMembersNotEmpty = savobj.groups_data.filter(item => item.group_members.length > 0);
    if(allMembersNotEmpty.length > 0)
    {
      axios.post(base_url+"groups/save_groups",savobj)
      .then(res=>{
          toast.success(res.data?.message,{toastId:random_number})
          fetchDataforDragndrop()
      })
      .catch(err=>{
          console.log(err);
          SetsaveOrResetloading_flag(false);
          if(err.response?.statusText=='Unauthorized'){
            localStorage.clear();
            history.push('/adminlogin')
            window.location.reload();
          }else if(err.message == 'Request aborted'){
            window.location.reload()
          }else{
            //toast.warn(api_error_message,{toastId:random_number});
window.location.reload()
          }
      })
    }
    else{
      SetsaveOrResetloading_flag(false)
      toast.warning("Groups must be filled..!",{toastId:random_number})
    }
  }

const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId.split("_")[0]];
      const destColumn = columns[destination.droppableId.split("_")[0]];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      removed.role=destination.droppableId.split("_")[1]
      removed.role_id=destination.droppableId.split("_")[2]
      removed.group=destination.droppableId.split("_")[0]
      if(source.droppableId.split("_")[0] !== destination.droppableId.split("_")[0])
      {
        if(removed.isUserAuditLearner==true)
        {
          if(destColumn.isauditlearnergroup==true)
          {
            if(destColumn.groupName.includes("Group"))//&&roleBasedRecords.length==1
            {
              destItems.splice(destination.index, 0, removed);
              setColumns({
                ...columns,
                [source.droppableId.split("_")[0]]: {
                  ...sourceColumn,
                  items: sourceItems
                },
                [destination.droppableId.split("_")[0]]: {
                  ...destColumn,
                  items: destItems
                }
              });
            }
          }
          if(destColumn.groupName=="unassigned pool")
          {
            destItems.splice(destination.index, 0, removed);
            setColumns({
              ...columns,
              [source.droppableId.split("_")[0]]: {
                ...sourceColumn,
                items: sourceItems
              },
              [destination.droppableId.split("_")[0]]: {
                ...destColumn,
                items: destItems
              }
            });
          }
        }
        else{
      // let roleBasedRecords=destItems.filter(item=>item.role===destination.droppableId.split("_")[1])

      if(removed.isUserAuditLearner==false)
      {
        if(destColumn.isauditlearnergroup==false)
        {
          if(destColumn.groupName.includes("Group"))//&&roleBasedRecords.length==1
          {
            destItems.splice(destination.index, 0, removed);
            setColumns({
              ...columns,
              [source.droppableId.split("_")[0]]: {
                ...sourceColumn,
                items: sourceItems
              },
              [destination.droppableId.split("_")[0]]: {
                ...destColumn,
                items: destItems
              }
            });
          }
        }
        if(destColumn.groupName=="unassigned pool")
        {
          destItems.splice(destination.index, 0, removed);
          setColumns({
            ...columns,
            [source.droppableId.split("_")[0]]: {
              ...sourceColumn,
              items: sourceItems
            },
            [destination.droppableId.split("_")[0]]: {
              ...destColumn,
              items: destItems
            }
          });
        }
      }
        }
      }
      else{
        const foundIndex = destItems.findIndex(obj => obj.id === removed.id);
        destItems[foundIndex]=removed
        setColumns({
          ...columns,
          [destination.droppableId.split("_")[0]]: {
            ...destColumn,
            items: destItems
          }
        });
      }

    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      });
    }
  };
  const getMarginBottmByRolesCount=(items,roleId)=>{
    let itemCount=items.filter(item=>item.role_id===roleId)
    if(itemCount.length>1)
    {
      return '6px'
    }
    else{
      return '0px'
    }

  }
  const getBorder=(items,roleId)=>{
    let itemCount=items.filter(item=>item.role_id===roleId&&!item.type)
    if(itemCount.length==0)
    {
      return '1px dashed #000000'
    }
    else{
      return ''
    }
  }


 // const [columns, setColumns] = useState({});
const groupsIncDecHandler=(e)=>{
  if(e.key==='Enter')
  {
  SetLoading_flag(true)
  let updatedColumns = { ...columns };
   let itemsArr=[]
   rolesData.map(roleItem=>{
      itemsArr.push({"id":generateRandomString(),"first_name":"raju",type:"Dummy",role:roleItem.roleName,role_id:roleItem.roleId,group:""})
   })

   if(+groupsCount>groups)
   {
    //get count of audit learners in pool
    let auditLearners= updatedColumns['Unassigned Pool']['items'].filter(item=>item.isUserAuditLearner)
    // get count of audit learners groups
    let auditgroupCount=0;
    for (const key in updatedColumns) {
      if (Object.hasOwnProperty.call(updatedColumns, key)) {
        let singleGroup=updatedColumns[key]
        if(key!=="Unassigned Pool")
        {
          if(singleGroup.isauditlearnergroup)
          {
            auditgroupCount+=1
          }
        }
      }
    }

    for (let i = +groups+1; i <= +groupsCount; i++) {  //groups is old value and groupCount is input value

      if(auditLearners.length>0&&auditgroupCount==0&&+groupsCount==i)
      {
        updatedColumns[`Group-${i}`]={groupName:`Group-${i}`,isauditlearnergroup:true,roles:rolesData, items:itemsArr}
      }
      else{
        updatedColumns[`Group-${i}`]={groupName:`Group-${i}`,isauditlearnergroup:false,roles:rolesData, items:itemsArr}
      }
      }
    }
    else{ //when groupCount is less than groups
     for (let i =groups; i> parseInt(groupsCount); i--) {  //groups is old value and groupCount is input value
       let itemCount=updatedColumns[`Group-${i}`].items.filter(item=>!item.type)
       if(itemCount.length>0)
       {
        itemCount.forEach(item=>{
          item.groupId= "";
          item.role= "";
          item.role_id= "";
          updatedColumns['Unassigned Pool'].items.push(item)
        })
       }
      delete updatedColumns[`Group-${i}`]
      }
      //get count of audit learners in pool
      let auditLearners = updatedColumns['Unassigned Pool']['items'].filter(item => item.isUserAuditLearner)
      // get count of audit learners groups
      let auditgroupCount = 0;
      for (const key in updatedColumns) {
        if (Object.hasOwnProperty.call(updatedColumns, key)) {
          let singleGroup = updatedColumns[key]
          if (key !== "Unassigned Pool") {
            if (singleGroup.isauditlearnergroup) {
              auditgroupCount += 1
            }
          }
        }
      }

      if (auditLearners.length > 0 && auditgroupCount == 0) //update the group as audit group if audit learners exited and no audit group should available
      {
        if (updatedColumns[`Group-${parseInt(groupsCount)}`]) {
          updatedColumns[`Group-${parseInt(groupsCount)}`] = { groupName: `Group-${parseInt(groupsCount)}`, isauditlearnergroup: true, roles: rolesData, items: itemsArr }
        }
      }
    }

    setColumns(updatedColumns)
    setGroups(+groupsCount)
    SetLoading_flag(false)
    toast.success(`Groups count is updated`,{toastId:random_number})
  }
  }

  const pushBackTopoolHandler=()=>{

  }

  const  handleChange=(e)=>{
    const target = e.target;
    const name = target.name;
    const value = target.value;
    if(name=='groupsCount')
    {
      const pattern = /^(?!0)\d+$/;      ///^[0-9]+$/;
      if (value === "" || pattern.test(value)) {
        setGroupsCount(value);
        if(value==="")
        {

          // if(handoutItem.sla_id.length ==0){
            // handleRemoveInput()
            // }
          }
          else{
            // handleAddButtonInput(parseInt(value))
            // handleAddInput(parseInt(value))

        }
      }
      // value===""?save_button.disabled = true:save_button.disabled = false
    }
}
const randomAllocate = () => {
  let updatedColumns = JSON.parse(JSON.stringify(columns))
  doRandomAllocate2(updatedColumns)
}
// let newColumns=[]
// let call=0
// const doRandomAllocate=(updatedColumns)=>{
//   for (const key in updatedColumns) {
//     if (Object.hasOwnProperty.call(updatedColumns, key)) {
//       let singleGroup=updatedColumns[key]
//       if(key!=="Unassigned Pool")
//       {
//         if(updatedColumns['Unassigned Pool']['items'].length>1)
//         {
//             singleGroup['roles'].map(item=>{
//           let roleItems=singleGroup['items'].filter(items=> item.roleId==items.role_id&&!items.type)
//               if(singleGroup['items'].length==1)
//               {
//                 singleGroup.isauditlearnergroup=true
//               }
//               if(roleItems.length==0 &&call==0)
//               {
//                 let spliceIndex=0
//                 if(updatedColumns['Unassigned Pool']['items'][0]?.type)
//                 {
//                   spliceIndex+=1
//                 }
//                 let removedElement =undefined;
//                 if(updatedColumns['Unassigned Pool']['items'][spliceIndex].isUserAuditLearner)
//                 {
//                   if(singleGroup.isauditlearnergroup)
//                   {
//                     removedElement= updatedColumns['Unassigned Pool']['items'].splice(spliceIndex, 1)[0];
//                   }
//                 }
//                 if(updatedColumns['Unassigned Pool']['items'][spliceIndex].isUserAuditLearner==false)
//                 {
//                   if(singleGroup.isauditlearnergroup==false)
//                   {
//                     removedElement= updatedColumns['Unassigned Pool']['items'].splice(spliceIndex, 1)[0];
//                   }
//                 }
//                 if(removedElement)
//                 {
//                   if(!removedElement.type)
//                   {
//                   //console.log(removedElement,"re");
//                   let replaceItem={...removedElement}
//                   replaceItem['group']=singleGroup.groupName;
//                   replaceItem['role']= item.roleName;
//                   replaceItem['role_id']= item.roleId;
//                   newColumns.push(replaceItem)
//                   }
//                 }
//               }
//               else if(call!==0)
//               {
//                 let spliceIndex=0
//                 if(updatedColumns['Unassigned Pool']['items'][0]?.type)
//                 {
//                   spliceIndex+=1
//                 }
//                 // let removedElement = updatedColumns['Unassigned Pool']['items'].splice(spliceIndex, 1)[0];
//                 let removedElement =undefined;

//                 if(updatedColumns['Unassigned Pool']['items'][spliceIndex].isUserAuditLearner)
//                 {
//                   if(singleGroup.isauditlearnergroup)
//                   {
//                     removedElement= updatedColumns['Unassigned Pool']['items'].splice(spliceIndex, 1)[0];
//                   }
//                 }
//                 if(updatedColumns['Unassigned Pool']['items'][spliceIndex].isUserAuditLearner==false)
//                 {
//                   if(singleGroup.isauditlearnergroup==false)
//                   {
//                     removedElement= updatedColumns['Unassigned Pool']['items'].splice(spliceIndex, 1)[0];
//                   }
//                 }
//                 console.log(updatedColumns['Unassigned Pool']['items'][spliceIndex],singleGroup,"601")
//                 if(removedElement)
//                 {
//                   if(!removedElement.type)
//                   {
//                     //console.log(removedElement,"re");
//                     let replaceItem={...removedElement}
//                     replaceItem['group']=singleGroup.groupName;
//                     replaceItem['role']= item.roleName;
//                     replaceItem['role_id']= item.roleId;
//                     newColumns.push(replaceItem)
//                   }
//                 }
//               }
      
//             })
//         }
//       }
//     }
//   }
//   //recursion logic
//   let auditLearners= updatedColumns['Unassigned Pool']['items'].filter(item=>item.isUserAuditLearner)
//   let normalLearners= updatedColumns['Unassigned Pool']['items'].filter(item=>!item.isUserAuditLearner&&!item.type)

//   let freeGroupOrisauditgroup=false
//   //search for free group or group with isauditlearnergroup==true
//   for (const key in updatedColumns) {
//     if (Object.hasOwnProperty.call(updatedColumns, key)) {
//       let singleGroup=updatedColumns[key]
//       if(key!=="Unassigned Pool")
//       {
//         if(singleGroup['items'].length==1||singleGroup.isauditlearnergroup)
//         {
//           freeGroupOrisauditgroup=true
//         }
//       }
//     }
//   }

//   console.log(normalLearners.length>0,auditLearners.length>0&&freeGroupOrisauditgroup)
//   if(normalLearners.length>0||auditLearners.length>0&&freeGroupOrisauditgroup)
//   {
//       call+=1
//       doRandomAllocate(updatedColumns)
//   }
//   else{
//     console.log(newColumns,"kjdgshjfkhjk598")
//     for (let index = 0; index < newColumns.length; index++) {
//       const item = newColumns[index];
//       for (const key in updatedColumns) {
//         if (Object.hasOwnProperty.call(updatedColumns, key)) {
//           let singleGroup=updatedColumns[key]
//       if(key==item['group'])
//       {
//         singleGroup['items'].unshift(item)
//       }
//     }
//   } 
//     }
// setColumns(updatedColumns)
//     call=0
//     newColumns=[]
//   }
// }




let newColumns2=[]
let call2=0
const doRandomAllocate2=(updatedColumns)=>{
  for (const key in updatedColumns) {
    if (Object.hasOwnProperty.call(updatedColumns, key)) {
      let singleGroup=updatedColumns[key]
      if(key!=="Unassigned Pool")
      {
        if(updatedColumns['Unassigned Pool']['items'].length>1)
        {
            singleGroup['roles'].map(item=>{
              let roleItems=singleGroup['items'].filter(items=> item.roleId==items.role_id&&!items.type)
              if(roleItems.length==0&&call2==0)
              {
                if(singleGroup.isauditlearnergroup)//true
                {
                  let removedElement= undefined;
                  for (let index = 0; index < updatedColumns['Unassigned Pool']['items'].length; index++) {
                    const poolItem = updatedColumns['Unassigned Pool']['items'][index];
                    if(poolItem.isUserAuditLearner)
                    {
                      removedElement=updatedColumns['Unassigned Pool']['items'].splice(index, 1)[0]
                      break;
                    }
                  }
  
                  if(removedElement)
                  {
                    if(!removedElement.type)
                    {
                    //console.log(removedElement,"re");
                    let replaceItem={...removedElement}
                    replaceItem['group']=singleGroup.groupName;
                    replaceItem['role']= item.roleName;
                    replaceItem['role_id']= item.roleId;
                    newColumns2.push(replaceItem)
                    }
                  }
                }
                else{//false
                  let auditLearners= updatedColumns['Unassigned Pool']['items'].filter(item=>item.isUserAuditLearner)
  
                  let removedElement= undefined;
                  for (let index = 0; index < updatedColumns['Unassigned Pool']['items'].length; index++) {
                    const poolItem = updatedColumns['Unassigned Pool']['items'][index];
                    if(poolItem.isUserAuditLearner==false)
                    {
                      removedElement=updatedColumns['Unassigned Pool']['items'].splice(index, 1)[0]
                      break;
                    }
                  }
  
                  if(removedElement)
                  {
                    if(!removedElement.type)
                    {
                    //console.log(removedElement,"re");
                    let replaceItem={...removedElement}
                    replaceItem['group']=singleGroup.groupName;
                    replaceItem['role']= item.roleName;
                    replaceItem['role_id']= item.roleId;
                    newColumns2.push(replaceItem)
                    }
                  }
                }
              }
              else if(call2!==0){
                if(singleGroup.isauditlearnergroup)//true
                {
                  let removedElement= undefined;
                  for (let index = 0; index < updatedColumns['Unassigned Pool']['items'].length; index++) {
                    const poolItem = updatedColumns['Unassigned Pool']['items'][index];
                    if(poolItem.isUserAuditLearner)
                    {
                      removedElement=updatedColumns['Unassigned Pool']['items'].splice(index, 1)[0]
                      break;
                    }
                  }
  
                  if(removedElement)
                  {
                    if(!removedElement.type)
                    {
                    //console.log(removedElement,"re");
                    let replaceItem={...removedElement}
                    replaceItem['group']=singleGroup.groupName;
                    replaceItem['role']= item.roleName;
                    replaceItem['role_id']= item.roleId;
                    newColumns2.push(replaceItem)
                    }
                  }
                }
                else{//false
                  let auditLearners= updatedColumns['Unassigned Pool']['items'].filter(item=>item.isUserAuditLearner)
  
                  let removedElement= undefined;
                  for (let index = 0; index < updatedColumns['Unassigned Pool']['items'].length; index++) {
                    const poolItem = updatedColumns['Unassigned Pool']['items'][index];
                    if(poolItem.isUserAuditLearner==false)
                    {
                      removedElement=updatedColumns['Unassigned Pool']['items'].splice(index, 1)[0]
                      break;
                    }
                  }
  
                  if(removedElement)
                  {
                    if(!removedElement.type)
                    {
                    //console.log(removedElement,"re");
                    let replaceItem={...removedElement}
                    replaceItem['group']=singleGroup.groupName;
                    replaceItem['role']= item.roleName;
                    replaceItem['role_id']= item.roleId;
                    newColumns2.push(replaceItem)
                    }
                  }
                }
              }
       
            })
        }
      }
    }
  }
  //recursion logic
  let auditLearners= updatedColumns['Unassigned Pool']['items'].filter(item=>item.isUserAuditLearner)
  let normalLearners= updatedColumns['Unassigned Pool']['items'].filter(item=>!item.isUserAuditLearner&&!item.type)

  let freeGroupOrisauditgroup=false
  //search for free group or group with isauditlearnergroup==true
  for (const key in updatedColumns) {
    if (Object.hasOwnProperty.call(updatedColumns, key)) {
      let singleGroup=updatedColumns[key]
      if(key!=="Unassigned Pool")
      {
        if(singleGroup['items'].length==1||singleGroup.isauditlearnergroup)
        {
          freeGroupOrisauditgroup=true
        }
      }
    }
  }

  if(normalLearners.length>0||auditLearners.length>0&&freeGroupOrisauditgroup)
  {
      call2+=1
      doRandomAllocate2(updatedColumns)
  }
  else{
    for (let index = 0; index < newColumns2.length; index++) {
      const item = newColumns2[index];
      for (const key in updatedColumns) {
        if (Object.hasOwnProperty.call(updatedColumns, key)) {
          let singleGroup=updatedColumns[key]
      if(key==item['group'])
      {
        singleGroup['items'].unshift(item)
      }
    }
  } 
    }
setColumns(updatedColumns)
    call2=0
    newColumns2=[]
}
}

    return(
    <>
   <div className="bg-body container gap-4">
    <div className="notification_heading">

    {propsData?.program_name} &gt; {propsData?.course_name} &gt; {propsData?.module_name}
    </div> 
    {loading_flag?
    <>
      <div>
        <img className="atrium_loader_custom" src="/images/atrium_loader.gif" alt="Loader" />
      </div>
    </>:
    <>
      <div className="row">
        <div className="col-lg-3">
        {groupMemebersData.length == 0 ? (
            <button className="btn my_btn_cls mt-0 download_exportCSV rounded" disabled>
              Export CSV
            </button>
          ) : (
            <CSVLink data={csvData} filename={`${propsData?.course_name}_${propsData?.module_name}_sla.csv`}>
              <button className="btn my_btn_cls mt-0 download_exportCSV rounded">
                Export CSV
              </button>
            </CSVLink>
        )}
        </div>
        <div className="col-lg-6 pl-5">
        <div className="row">
            <div className="col-lg-3 px-0 mt-2 pt-1 NumberOfGroups">
            <span className="font-weight-bold">Number of groups</span>
            </div>
            <div className="col-lg-2 px-0">
            <span><input onKeyDown={groupsIncDecHandler} onChange={handleChange} minLength="1" maxLength={'2'} value={groupsCount} type="text"  name="groupsCount" className="form-control rounded PSLA_GroupInput mt-1"/></span>
            </div>
            <div className="col-lg-4 px-0">
            <button  className="my_btn_cls p-2 mt-0 programslatop_btn_update  mt-1 rounded" onClick={onSave}>Update
            </button>
            </div>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="row">
            <div className="col-4">
                {/* <button disabled={saveOrResetloading_flag} className="my_btn_cls p-2 mt-0 program_btn mr-2" onClick={resetAll}>
        Reset
                </button> */}

        <button  className="btn p-2 mt-0 programslatop_btn_reset  my_outline_button_cls mt-1 rounded" onClick={resetAll}>Reset</button>

            </div>
            <div className="col-8">

            <div className="float-right mr-1">
        <button  className="my_btn_cls p-2 mt-0 programslatop_btn_allot mt-1 rounded" onClick={randomAllocate}>Random Allot</button>

    {/* <button disabled={saveOrResetloading_flag} className="my_btn_cls p-2 mt-0 program_btn mr-2" onClick={resetAll}>
    Reset
            </button>
            <button disabled={saveOrResetloading_flag} className="my_btn_cls p-2 mt-0 program_btn " onClick={onSave}>
    Save
            </button> */}
            </div>
            </div>
          </div>
        </div>
      </div>
 
    <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
    <div className="row">
        <div className="col-3 p-2 pr-5">
            <p className="assign_role_text">Unassigned Pool</p>
            <div className={`p-3 role_name_class `} id="targetdiv">
            {Object.entries(columns).map(([columnId, column], index) => {
            return (
                <>
                 {index==0&&
                    <>
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            background: snapshot.isDraggingOver&& "lightblue",
                            // padding: 4,
                            // width: 250,
                            //  minHeight: 500,
                             height: '51.5vh',
                             overflow:columns['Unassigned Pool']['items'].length>1?'auto':'',
                             paddingRight: '15px'                         
                          }}
                        >
                          {column.items.map((item, index) => {
                            return (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={{
                                        visibility:item?.type=="Dummy"?"hidden":"visible",
                                        userSelect: "none",
                                        padding:item?.type=="Dummy"?0:" 0px 0px 5px 20px",
                                        margin:item?.type=="Dummy"?"0 0 0 0":"0 0 22px 0",
                                        height:item?.type=="Dummy"&&"0px",
                                        backgroundColor: snapshot.isDragging? "#ffffff": "#ffffff",
                                        border: snapshot.isDragging&&"1px solid #000000",
                                        color: "#000000",
                                        fontWeight: 400,
                                        fontSize: "18px",
                                        lineHeight: "39px",
                                        ...provided.draggableProps.style
                                      }}
                                    >
                                      {item.first_name}{" "}{ item.last_name} {item.isUserAuditLearner&&"(Audit)"}
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                    </>
                 } 
                </>
            )
          })}
            </div>
        </div>
        <div className="col-1 visible-hidden">fdf</div>
        <div className="col-8 p-2">
            <div className="d-flex justify-content-around ml-5">
                {roleList.map(item=>{
                    return(
                        <div className="assign_role_text">{item.name}</div>
                    )
                })}
            </div>
            <div className="mt-3 groupsContainer">

            {Object.entries(columns).map(([columnId, column], indexx) => {
                          return (
                              <>
                      {indexx>0&&
                              <div className="d-flex justify-content-around" id='inputdivs'> 
                    <div className="mt-5 assign_role_text" key={indexx} 
                    style={column.isauditlearnergroup ? { color: '#008000' } : {}}>{column.groupName} </div> 
                    <div className="group_wise_roles d-flex justify-content-around" id="parent_div">
                              {column.roles.map(role=>{
                                return(
                                      <>
                                      <Droppable droppableId={columnId+"_"+role.roleName+"_"+role.roleId} key={columnId+"_"+role.roleName}>
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          {...provided.droppableProps}
                                          className="GroupAndRoleContainer"
                                          ref={provided.innerRef}
                                          style={{
                                            background: snapshot.isDraggingOver
                                              ? "lightblue"
                                              : "rgba(255, 255, 255, 0.5)",
                                            border:getBorder(column.items,role.roleId),
                                            width:"180px",
                                            minHeight: "44px"
                                            }}
                                        >
                                          {column.items.map((item, index) => {
                                            return (
                                              <>
                                              {item.role_id===role.roleId&&<>
                                                <Draggable
                                                key={item.id}
                                                draggableId={item.id}
                                                index={index}
                                              >
                                                {(provided, snapshot) => {
                                                  return (
                                                    <div
                                                      ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                      style={{
                                                        marginBottom:item?.type=="Dummy"&&"0px",
                                                        visibility:item?.type=="Dummy"?"hidden":"visible",
                                                        userSelect: "none",
                                                        padding:item?.type=="Dummy"?0:10,
                                                        height:item?.type=="Dummy"&& "0px",
                                                        border: snapshot.isDragging&&"1px solid #000000",
                                                        backgroundColor: snapshot.isDragging
                                                          ? "#ffffff"
                                                          : "#ffffff",
                                                        color: "#000000",
                                                        textAlign: "center",
                                                        fontSize:"18px",
                                                        ...provided.draggableProps.style
                                                      }}
                                                    >
                                                      {item.first_name}{" "}{item.isUserAuditLearner&&'(Audit)'}
                                                    </div>
                                                  );
                                                }}
                                              </Draggable>
                                              </>}
                                              </>
                                            );
                                          })}
                                          {provided.placeholder}
                                        </div>
                                      );
                                    }}
                                  </Droppable>
                                    </> 
                                )
                              })}
                    </div>
                    </div>
                         }
                      </>
                       )
                  })}
        </div>
        </div>
    </div>
    {saveOrResetloading_flag &&
    <>
      <div>
        <img className="atrium_loader" src="/images/atrium_loader.gif" alt="Loader" />
      </div>
    </>}
    </DragDropContext>
    </>}
   </div>
    </>
    )
}
export default withMaintenanceCheck(ProgrammeSla,admin_maintenance_type) 

