import React, { useEffect, useState } from "react";
import { base_url, api_error_message, random_number, storefront_url, date_formats } from "../../utilities";
import { toast } from "react-toastify";
import axios from "../../axios-interceptor";
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
const ListFreeLesson = () => {
    const history = useHistory()
    let [data,setData] = useState([])
    const [loading_flag, setLoaderFlag] = useState(true);
    const columns = [
        {
            name: 'Programme Name',
            selector: row => row.learning_track,
            sortable: true,
            allowOverflow:false,
            wrap:true
        },
       
        {
            name: 'Course',
            selector: row => row.course,
            sortable: true,
            allowOverflow:false,
            wrap:true
        },
      
        {
            name: 'Module',
            selector: row => row.module,
            sortable: true,
            allowOverflow:false,
            wrap:true
        },
       
        {
            name: 'Lesson',
            selector: row => row.lesson,
            sortable: true,
            allowOverflow:false,
            wrap:true
        },
        
        {
            name: 'Edit',
            selector: row => row.edit,
            sortable: true,
            allowOverflow:false,
            wrap:false,
            grow:0
          
        },
        {
            name: 'URL',
            selector: row => row.url,
            sortable: true,
            allowOverflow:false,
            wrap:false,
            grow:0
        },
    
    ];
   
    useEffect(()=>{
      setLoaderFlag(true)
        axios
        .post(base_url + "lessons/get_free_lessons_list", {})
        .then((res) => {
          
          if(res.data.length !== 0 ) setLoaderFlag(false)
            res.data.data.map((item, index) => {
                let table_data = {
                  id: index + 1,
                  learning_track: item.learning_track_title+' - '+date_formats.human_date_format(item.program_start_date),
                  course: item.course_title,
                  module: item.module_title,
                  lesson: item.lesson_title,
                  edit: 
                    <div onClick={() => editHeroImage(item)} data-testid="Edit_Hero_Image">
                      <img
                        className="cursor-pointer"
                        src="/images/assign_roles.svg"
                      ></img>
                    </div>
                  ,
                  url: 
                    <div onClick={() => openURL(item)} data-testid="Open_Link">
                      <img className="cursor-pointer" src="/images/link.svg"></img>
                    </div>
                  ,
                };
                setData((current) => [...current, table_data]);
                
              });
        })
        .catch((err) => {
            console.log(err);
            if(err.response?.statusText=='Unauthorized' || err.response?.data?.statusText=='Unauthorized'){
              localStorage.clear();
              history.push('/adminlogin')
              window.location.reload();
            }else if(err.message == 'Request aborted' || err.message=== "Network Error"){
              window.location.reload()
            }else{
              //toast.warn(api_error_message,{toastId:random_number});
window.location.reload()
            }
          });
       
    },[])
    const editHeroImage = (item) => {
        history.push("/admindashboard/updateHeroImage", { item: item });
      };
      const createFreeLesson = () => {
        history.push("/admindashboard/updateHeroImage", { item: "" });
      };
      const openURL = (item) => {
        let learning_track_name = item.learning_track_title.replace(/ /g, "_");
        window.open(
          `${storefront_url}freeLessonpage/${item.program_id}/${item.learning_track_id}/${item.course_id}/${learning_track_name}`,
          "_blank"
        );
      };
    return(
        <>
       
        <div className="bg-body container ">
        <div className="d-flex justify-content-between">
            <div className="heading_div">
              <span className="heading-span" data-testid="Main_Heading">List Free Lesson</span>
            </div>
            <div className="">
              <button data-testid="Create_Free_Lesson_button" className="btn my_btn_cls submit_button btn-block mt-0 px-4 py-2"  onClick={createFreeLesson}>
              Create Free Lesson
              </button>
            </div>
          </div>
          {loading_flag ? (
          <>
            <div className="mt-5">
              <img
                className="atrium_loader_custom"
                src="/images/atrium_loader.gif"
                alt="Loader"
              />
            </div>
          </>
        ) : (<>
          {data.length !=0?
          <div className="mt-3" data-testid="FreeLessonTable">
          <DataTable className="active_program-table" columns={columns} data={data}  />
          <div data-testid="Table_Length" className="d-none">{data.length}</div>
          </div>
          :<div className="text-center fw-bold">No Records Found</div>}
          </>)}
          </div>
          </>
    )
}
export default ListFreeLesson;