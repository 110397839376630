import axios from "../../axios-interceptor";
import moment from "moment";
import { useState, useEffect } from "react"
import { useRef,useLayoutEffect} from "react";
import Carousel from 'react-bootstrap/Carousel';
import { base_url,api_error_message,random_number, quiz_name, learn_by_doing, check_point, did_i_get_this, formatSentence } from "../../utilities";
import { SliderComponent } from "./sliderComponent";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
export const ActivityElementShortAnswer = (props) => {
  const history = useHistory();
  var [answer_key, setAnswer] = useState('')
  var [button_name, setButtonName] = useState('Submit')
  var [flag, setFlag] = useState(false)
  let [submit_flag,setSubmitFlag] = useState(true)
  var [msg, setMsg] = useState('')
  let [answer_data, seAnswerData] = useState()
  let [points_data,setPointsData] = useState()
  let [wordCount, setWordCount] = useState(0)
  let [responseValue, serResponseValue] = useState('')
  let [slider_data,setSliderData] =useState([])
  let [button_flag,setButtonFlag] = useState(true)
  let [option_flag,setOptionFlag] =useState(false)
  const [activityInitiatedTime, setActivityInitiatedTime] = useState(moment());
  let text = '', images = [];
  let facultyResponse=''
  let title = props.title;
  const activity_Element_Short_answer_Component=useRef(null)
 useLayoutEffect(() => {
  props.i==0?
 setTimeout(() => {
     window.scrollTo({
       top: window.scrollY,
       left: 0,
       behavior: "smooth",
     })
   }, 350)
  :
   setTimeout(() => {
       window.scrollTo({
       top:  activity_Element_Short_answer_Component?.current?.offsetTop-40,
         left: 0,
          behavior: "smooth",
       });
    }, 350)
}, []);
  let purpose = props.purpose;
  for (const value of props.data.content.stem.content) {
    if (value?.children) {
       if(value?.type=='ol')
      {
        recursive_ol_data(value.children);
      }
      else if(value?.type=='ul')
      {
        recursive_ul_data(value.children);
      }
      else if(value?.type=='p')
      {
        recursive_p_data(value)
      }
      for (const value1 of value.children) {
        if (value1.type === 'a') {
          text += `<a target= 'blank' href='${value1.href}'>${value1.children[0].text}</a>`
        }
        else if (value.type === 'img') {
          images.push(value.src);
        }
        else if (value1.type === 'img') {
          images.push(value1.src);
        }
        else if (value.type === 'h2') {
          text += `<h2 class="question-title">${value1.text}</h2>`
        }
        else if(value.type!=='p') {
          if(value1?.text ){
            text += `<span class="summary_header_cls ${(value.text !== "" ? "d-block pb-4" : "")}">`;
            if(value1.em){
              text += `<em class="summary_header_cls ${(value.text !== "" ? "d-block pb-4" : "")}">`;
              text += value1?.text ? value1.text : ''
              text += '</em>';
            }else if (value1?.strong) {
              text += `<strong class="summary_header_cls ${(value.text !== "" ? "d-block pb-4" : "")}">`;
              text += value1?.text ? value1.text : ''
              text += '</strong><br/>';
            }
            else{
              text += value1?.text ? value1.text : ''
              text += '</span>';
            }
          }
        
        }
      }
    }
  }

  function recursive_ol_commmon_data(value,str_li){
    if (value?.strong) {
      str_li += '<li class="summary_list_cls"><strong class="summary_header_cls">';
      str_li += value.text;
      str_li += '</strong>';
    }else{
      str_li += '<li class="summary_list_cls">'+value.text
    }
    return str_li;
  }
  function recursive_ol_data(data){
    let str_li = '<ol class="summary_uList_cls">';
    for(const value of data){         
        for(const value1 of value.children){
          if(value1?.children){
            for(const value2 of value1?.children){
              str_li = recursive_ol_commmon_data(value2,str_li);
            }
          }else{
            str_li = recursive_ol_commmon_data(value1,str_li);
          }
        }
        str_li += '</li>' 
      }
     text+=str_li+ '</ol>';
  }


  function recursive_ul_commmon_data(value,str_li){
    if (value?.strong) {
      str_li += '<strong class="summary_header_cls">';
      str_li += value.text;
      str_li += '</strong>';
    }else{
      str_li += value.text
    }
    return str_li;
  }
  function recursive_ul_data(data){
    for(const value of data){ 
      if(value?.type !== 'li'){
        recursive_ul_data(value.children)
      }else{          
        let str_li = '<ul class="summary_uList_cls"><li class="summary_list_cls">';
        for(const value1 of value.children){
          if(value1?.children){
            for(const value2 of value1?.children){
              str_li = recursive_ul_commmon_data(value2,str_li);
            }
          }else{
            str_li = recursive_ul_commmon_data(value1,str_li);
          }
          }
        str_li += '</li>' 
        text +=str_li+ '</ul>';
      }
    }
  }
  function recursive_p_data(value)
  {
    text += `<span class="summary_header_cls d-block pb-4 questionText">`;
    for (const value1 of value.children) {
      if(value1?.sup)
      {
        text += '<sup>';
        text += (value1?.text ? value1.text : '')
        text += '</sup>';
      }
      else if(value1.em){
        text += `<em>`;
        text += value1?.text ? value1.text : ''
        text += '</em>';
      }
      else if (value1?.strong) {
        text +=`<strong>`;
        text += value1?.text ? value1.text : ''
        text += '</strong>';
      }
      else{
        text +=(value1?.text ? value1.text : '')
      }
    }
    text+='</span>';
  }
  text=text?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")
  props.data.final_text = text;
  if (images.length > 0) {
    props.data.className = 'col-8'
  } else {
    props.data.className = 'col-12'
  }
  props.data.images = images;
  var value = ''
  var rule_value = props.data.content.authoring.parts[0].responses[0].rule.match(/{(.*)}/)[1]
  useEffect(() => {
    if(localStorage.getItem('re_attempt')){
      props.data.is_answered = false
    }
    if (props.data.is_answered) {
      setButtonFlag(props.data.is_answered)
      setOptionFlag(true)
      props.parentCallback('false');
      if(JSON.parse(localStorage.getItem('sla_time'))){
        setFlag(false)
      }else{
        setFlag(true)
      }
      setSubmitFlag(true)
      serResponseValue(props.data.user_answer)
      const result = props.data.user_answer
      .split(/\s+/)
      .map(element => element.trim())
      .filter(element => element !== '')
      setWordCount(result.length)
      let sliderDataArr=[]
      if(props.data.content.authoring.parts[0].responses[0].feedback.content.length>0)
      {
        let elementHasnoContent=false;
        for (const value of props.data.content.authoring.parts[0].responses[0].feedback.content) {
          if (value?.children) {
             if(value?.type=='ol')
            {
              // recursive_ol_data_feedback(value.children);
            }
            else if(value?.type=='ul')
            {
               recursive_ul_data_feedback(value.children);
               elementHasnoContent=true
            }
            facultyResponse += '<span class="summary_header_cls d-block">';
            for (const value1 of value.children) {
               if (value.type === 'p') {
                if(value1?.text!=="")
                {
                  elementHasnoContent=true
                }
                if(value1?.sup)
                {
                  facultyResponse += '<sup>';
                  facultyResponse += (value1?.text ? value1.text : '')
                  facultyResponse += '</sup>';
                }
                else if(value1.em){
                  facultyResponse += `<em>`;
                  facultyResponse += value1?.text ? value1.text : ''
                  facultyResponse += '</em>';
                }
                else if (value1?.strong) {
                  facultyResponse += '<strong>';
                  facultyResponse += value1.text;
                  facultyResponse += '</strong>';
                }else{
                  facultyResponse += value1?.text ? value1.text : ''
                }
              }
            }
            facultyResponse += '</span>'
          }
        }
        //adding default text -Professor
        facultyResponse+='<span class="blue_text user_data_span" id="SliderProfessor">';
        facultyResponse+='- Faculty'
        facultyResponse+='<span/>'
        facultyResponse=facultyResponse?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")
        if(elementHasnoContent)
        {
          sliderDataArr.unshift(facultyResponse)  
        }
      }
      if(props.data.peer_response){
        props?.data?.peer_response.map(item=>{
         let peerResponseText=''
        peerResponseText+='<p>';
        peerResponseText+=item.user_answer?item.user_answer:'';
        peerResponseText+='</p>';

        peerResponseText+='<span class="blue_text user_data_span">';
        peerResponseText+=item.first_name? '- ' + formatSentence(item.first_name):"" 
        peerResponseText+=item.city? ', '+formatSentence(item.city):""
        peerResponseText+='<span/>';
        peerResponseText=peerResponseText?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")
        sliderDataArr.push(peerResponseText)
      })
      }
       setSliderData(sliderDataArr)
    }
  }, [props.data])


  function recursive_ul_commmon_data_feedback(value,str_li){
    if (value?.strong) {
      str_li += '<strong class="summary_header_cls">';
      str_li += value.text;
      str_li += '</strong>';
    }else{
      str_li+='<span class="summary_header_cls">'
      str_li += value.text
      str_li+='</span>'
    }
    return str_li;
  }


  function recursive_ul_data_feedback(data){
    for(const value of data){ 
      if(value?.type !== 'li'){
        recursive_ul_data_feedback(value.children)
      }else{          
        let str_li = '<ul class="summary_uList_cls"><li class="summary_list_cls">';
        for(const value1 of value.children){
            if(value1?.children){
              for(const value2 of value1?.children){
                str_li = recursive_ul_commmon_data_feedback(value2,str_li);
              }
            }else{
              str_li = recursive_ul_commmon_data_feedback(value1,str_li);
            }
          }
        str_li += '</li>' 
        facultyResponse +=str_li+ '</ul>';
      }
    }
 
  }

  const getAnswer = (e) => {
   
    if (e.target.value.split(/\s+/).map(element => element.trim()).filter(element => element !== '').length < 301) {
      value = e.target.value
      if(value.match(/^\s*$/) || value.replace(/\s/g, '').length < 5){
        setSubmitFlag(true);
      }else{
        setSubmitFlag(false);
      }
      const result = value
        .split(/\s+/)
        .map(element => element.trim())
        .filter(element => element !== '')
     
      setWordCount(result.length)
      serResponseValue(value)

      return true
    }
    else {
      return false
    }
  }
  const onSubmitAnswer = () => {
    // let elementHTML = document.getElementById('target-element')?.innerHTML;
    // const container = document.createElement('div');
    // container.innerHTML = elementHTML;
    // const h2Element = container.querySelector('h2.question-title')?.textContent;
    // const divElement = container.querySelector('span')?.innerHTML;

    const container = document.createElement('div');
    container.innerHTML = props.data.final_text;
    const h2Text = container.querySelector('h2.question-title')?.innerText?.trim();
    const text = container.querySelectorAll('.summary_header_cls');
    const liElements = document.querySelectorAll('#short-answer .summary_list_cls');
    const headerTexts = Array.from(text).map(element => element.textContent.trim())
    const liTexts = Array.from(liElements).map(li => li.textContent.trim());
    const combinedText = [...headerTexts,...liTexts,'Type in your response'].join('. ');

    let is_correct
  
    if (!flag ) {
    
      if (responseValue.trim().length >= 5 ) {
        if(JSON.parse(localStorage.getItem('sla_time'))){
          setFlag(false)
        }else{
          setFlag(true)
        }
        setOptionFlag(true)
        setSubmitFlag(true);
        setTimeout(() => {
          window.scrollTo({
            top: window.scrollY +window.innerHeight,
            left: 0,
            behavior: "smooth",
          });
        }, 300);
        if (/^[a-zA-Z0-9\s]*$/.test(responseValue)) {
         is_correct = true
          setMsg('yes')
          setAnswer(props.data.content.authoring.parts[0].responses[0].feedback.content[0].children[0].text)
        } else {
          is_correct = false
          setMsg('no')
          setAnswer(props.data.content.authoring.parts[0].responses[1].feedback.content[0].children[0].text)
        }
      
      const activityEndTime=moment()
        var body = {
          user_id: localStorage.getItem("user_id"),
          course_id: props.data.course_id,
          module_id: props.data.module_id,
          lesson_id: props.data.lesson_id,
          cohort_schedule_id: props.data.cohort_sch_id,
          activity_id: props.data.activity_id,
          activity_entity_type: props.data.acitivity_entity_type,
          is_question: true,
          user_answer:responseValue,
          is_correct: is_correct,
          question_type:props.data.subType,
          answer_label: "Test",
          is_quiz: purpose ==quiz_name?  true: false,
          time_spent_on_activity:activityEndTime.diff(activityInitiatedTime,'seconds'),
          current_title:h2Text??'',
          current_text: combinedText??'',
          prev_title:localStorage.getItem('prev_title')??'',
          prev_text:localStorage.getItem('prev_text')??'',
          activity_submitted_platform: "ISBo"
        };
        if(localStorage.getItem("user_id") && window.location.pathname.toLowerCase().includes('freelessonpage') == false&&window.location.pathname.toLowerCase().includes('freelessonbdlink') == false){
        axios
          .post(base_url + "user/progress_tracking", body)
          .then((res) => {
            setActivityInitiatedTime(moment())
            props.parentCallback('false');
            let sliderDataArr=[]
            let result=[...res.data.result]
            if(typeof(res.data.points_break_down) == 'object'){
              setPointsData(res.data.points_break_down)
            }
            if(props.data.content.authoring.parts[0].responses[0].feedback.content.length>0)
            {
              let elementHasnoContent=false;
              for (const value of props.data.content.authoring.parts[0].responses[0].feedback.content) {
                if (value?.children) {
                   if(value?.type=='ol')
                  {
                    // recursive_ol_data_feedback(value.children);
                  }
                  else if(value?.type=='ul')
                  {
                     recursive_ul_data_feedback(value.children);
                     elementHasnoContent=true
                  }
                  facultyResponse += '<span class="summary_header_cls d-block">';
                  for (const value1 of value.children) {
                     if (value.type === 'p') {
                      if(value1?.text!=="")
                      {
                        elementHasnoContent=true
                      }
                      if(value1?.sup)
                      {
                        facultyResponse += '<sup>';
                        facultyResponse += (value1?.text ? value1.text : '')
                        facultyResponse += '</sup>';
                      }
                      else if(value1.em){
                        facultyResponse += `<em>`;
                        facultyResponse += value1?.text ? value1.text : ''
                        facultyResponse += '</em>';
                      }
                      else if (value1?.strong) {
                        facultyResponse += '<strong>';
                        facultyResponse += value1.text;
                        facultyResponse += '</strong>';
                      }else{
                        facultyResponse += value1?.text ? value1.text : ''
                      }
                    }
                  }
                  facultyResponse += '</span>'
                }
              }
              //adding default text -Professor
              facultyResponse+='<span class="blue_text user_data_span" id="SliderProfessor">';
              facultyResponse+='- Faculty'
              facultyResponse+='<span/>'
              facultyResponse=facultyResponse?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")
              if(elementHasnoContent)
              {
                sliderDataArr.unshift(facultyResponse)  
              }
            }

              if(result){
                    result.map(item=>{
                    let peerResponseText=''
                    peerResponseText+='<p>';
                    peerResponseText+=item.user_answer?item.user_answer:'';
                    peerResponseText+='</p>';
            
                    peerResponseText+='<span class="blue_text user_data_span">';
                    peerResponseText+=item.first_name? '- ' + formatSentence(item.first_name):"" 
                    peerResponseText+=item.city? ', '+formatSentence(item.city):""
                    peerResponseText+='<span/>';
                    peerResponseText=peerResponseText?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")
                    sliderDataArr.push(peerResponseText)
                  })
              }
            setSliderData(sliderDataArr)
          })
          .catch((err) => {
            console.log(err);
            if(err.response?.statusText=='Unauthorized'){
              localStorage.clear();
              history.push('/')
              window.location.reload();
            }else if(err.message == 'Request aborted'){
              window.location.reload()
            }else{
              setSubmitFlag(false)
              setOptionFlag(false)
              setFlag(false)
              props.parentCallback('');
              //toast.warn(api_error_message,{toastId:random_number});
window.location.reload()
            }
          });
        }else{
          props.parentCallback('false');
          let sliderDataArr=[]
          if(props.data.content.authoring.parts[0].responses[0].feedback.content.length>0)
          {
            let elementHasnoContent=false;
            for (const value of props.data.content.authoring.parts[0].responses[0].feedback.content) {
              if (value?.children) {
                 if(value?.type=='ol')
                {
                  // recursive_ol_data_feedback(value.children);
                }
                else if(value?.type=='ul')
                {
                   recursive_ul_data_feedback(value.children);
                   elementHasnoContent=true
                }
                facultyResponse += '<span class="summary_header_cls d-block">';
                for (const value1 of value.children) {
                   if (value.type === 'p') {
                    if(value1?.text!=="")
                    {
                      elementHasnoContent=true
                    }
                    if(value1?.sup)
                    {
                      facultyResponse += '<sup>';
                      facultyResponse += (value1?.text ? value1.text : '')
                      facultyResponse += '</sup>';
                    }
                    else if(value1.em){
                      facultyResponse += `<em>`;
                      facultyResponse += value1?.text ? value1.text : ''
                      facultyResponse += '</em>';
                    }
                    else if (value1?.strong) {
                      facultyResponse += '<strong>';
                      facultyResponse += value1.text;
                      facultyResponse += '</strong>';
                    }else{
                      facultyResponse += value1?.text ? value1.text : ''
                    }
                  }
                }
                facultyResponse += '</span>'
              }
            }
            //adding default text -Professor
            facultyResponse+='<span class="blue_text user_data_span" id="SliderProfessor">';
            facultyResponse+='- Faculty'
            facultyResponse+='<span/>'
            facultyResponse=facultyResponse?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")
            if(elementHasnoContent)
            {
              sliderDataArr.unshift(facultyResponse)  
            }
            setSliderData(sliderDataArr)
          }
        }
      }
      else {
        is_correct = false
        setMsg('no')
        setAnswer(props.data.content.authoring.parts[0].responses[1].feedback.content[0].children[0].text)
        setOptionFlag(false)
        setButtonFlag(false)
      }
       
    }
  }

  useEffect(() => {
    //To remove the gap between the paragraph and a link
    const elementsWithClass = document.getElementsByClassName('shortAnswers_Dynamic_content');
    // Loop through each element with the class "shortanswers"
    for (let i = 0; i < elementsWithClass.length; i++) {
      const element = elementsWithClass[i];
      // Find the anchor tag within the current element
      const anchorTag = element.querySelector('a');
      // If an anchor tag is found
      if (anchorTag) {
        // Get the previous sibling of the anchor tag
        let sibling = anchorTag.previousElementSibling;

        // Loop through all previous siblings until we reach the <span> elements
        while (sibling) {
          // Check if the current sibling is a <span> element
          if (sibling.tagName.toLowerCase() === 'span') {
            // Remove the class 'pb-4' and add 'pb-0' to the <span> element
            sibling.classList.remove('pb-4');
            sibling.classList.add('pb-0');
          }
          // Move to the previous sibling
          sibling = sibling.previousElementSibling;
        }
      }
    }
  }, [props.data.final_text])
  

  return (
    <>
    <div data-testid="ActivityElementShortAnswer_content">
      <div ref={activity_Element_Short_answer_Component}  className="col-12 p-0 custm_shadow_cls bg-body">

          <div className="row data-component">
            <div className="d-flex flex-row justify-content-between align-items-end" >
              <strong data-testid="activity_name" className=" mb-0 align-items-end" style={{ letterSpacing: "1px", opacity: "50%", }}>
                {purpose == learn_by_doing ? <p className="mb-0">
                  <img style={{ opacity: "50%" }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAA7ElEQVRIieWVMQrCMBhGH3Vyap1FcBYcPIpCr1Do0mPoFTyFCOIJqoIO3sLiWMShqFCH/IVQaEkhmfpBaBOS99ImJNCnDIE7cHYl2AClFOuZAx9XAg+4aHDrgkSgDxeCCfAS6NKFYC/Ag9StCkKB5cC4JqiXJxB1gQdAJoNjrT1tkfwA31SwlUEn1C5qywwoUNs4MIGPpHMhg9viySRKmZRRfNTnNv2KVOsbS1tmOvsqEWrhmiSgFj6XetgF3hZdsJP3oy24LljJ8w1MXQiqYyOxCdcFJXADBq4EX2BhG64L1i7goK7LK+r67En+G2pg6MDEsykAAAAASUVORK5CYII=" />

                  PRIMING ACTIVITY</p> : (purpose == check_point? <p>CHECK POINT</p> : (purpose == quiz_name ? <p>QUESTION</p> : (purpose == did_i_get_this ? <p>DID I GET THIS?</p> : '')))}
              </strong>
            </div>
            <div id="target-element" className="d-flex flex-row justify-content-between align-items-end">
                <div className="shortAnswers_Dynamic_content" id="short-answer" dangerouslySetInnerHTML={{ __html: props.data.final_text }}></div>
            </div>
            <div data-testid="typeInResponse" className="summary_header_cls pb-0"><i>Type in your response</i></div>
            <div className={`col-12 pt-2 ${flag && (slider_data.length !=0)&& 'padb-sm-5'}`}>
              <div className="d-flex flex-column ">
                <textarea data-testid="activityShortTextArea" rows="5" cols="50" autoFocus={true} value={responseValue} onChange={(e) => getAnswer(e)} placeholder="Type your response here" id="text-area" className="my_text_area" disabled={option_flag} onPaste={(e) => { e.preventDefault(); return false; }} onCopy={(e) => { e.preventDefault(); return false; }}></textarea>
                {responseValue.replace(/\s/g, '').length >= 1 && responseValue.replace(/\s/g, '').length < 5 && <span  data-testid="response_error_msg" className="span-error-color">The response should contain at least five characters</span>}
                <div className="row align-items-end">
                  <div className="col-md-6 col-lg-6 coln-4">
                     <button data-testid="activityShortAnsSubmit" className="btn review_button submit_button mt-4" disabled={submit_flag} onClick={onSubmitAnswer}>
                      <b>SUBMIT</b>
                    </button>
                  </div>
                  <div className="col-md-6 col-lg-6 coln-8">
                    <div className="my_word_count">
                      <span className="float-right mr-0" data-testid="activityShortAnsCWC">Current Word Count <span style={{ fontWeight: "bold" }}>{wordCount}</span></span><br />
                      <span className="float-right short_ans_word_limit mr-0" data-testid="activityShortAnsWLT">Word Limit <span style={{ fontWeight: "bold" }}>300</span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
       
      </div>
      <div className="peer-resonse-short-answer">

        {flag && (slider_data.length !=0 && <><SliderComponent user_answer={responseValue} activityData={props.data} data={slider_data} points={points_data}></SliderComponent></>)}
      </div>
      </div>
    </>
  )
}