
import axios from "../../axios-interceptor";
import { useState, useEffect } from "react";
import { useRef, useLayoutEffect } from "react";
import { base_url,api_error_message,random_number} from "../../utilities";
import { toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
export const AboutQuizComponent  =(props) =>{
  const history = useHistory();
    const about_quiz=useRef(null)
    useLayoutEffect(() => {
        props.i==0?
       setTimeout(() => {
           window.scrollTo({
             top: window.scrollY,
             left: 0,
             behavior: "smooth",
           });
         }, 350):
         setTimeout(() => {
             window.scrollTo({
            top:  about_quiz.current.offsetTop-40,
            left: 0,
               behavior: "smooth",
             });
           }, 350)
      }, []);
      const onSubmit = ()=>{
        var activity_id = null;
        var activity_entity_type = null;
        var body = {
          user_id: localStorage.getItem("user_id"),
          course_id: props.data.course_id,
          module_id: props.data.module_id,
          lesson_id: props.data.lesson_id,
          cohort_schedule_id: props.data.cohort_sch_id,
          activity_id: activity_id,
          activity_entity_type: activity_entity_type,
          is_question: false,
          user_answer: "",
          is_correct: null,
          question_type: "test",
          answer_label: "Test",
          is_quiz: false,
          time_spent_on_activity: 0,
          activity_submitted_platform: "ISBo"
        }
        if(!JSON.parse(localStorage.getItem('quiz_start_time')) && localStorage.getItem('quiz_lesson')!=props.data.lesson_id&&window.location.pathname.toLowerCase().includes('freelessonbdlink') == false ){
          axios
          .post(base_url + "user/progress_tracking", body)
          .then((res) => {
            props.parentCallback('quiz_false');
          })
          .catch((err) => {
            console.log(err);
            props.parentCallback("");
            if(err.response?.statusText=='Unauthorized'){
              localStorage.clear();
              history.push('/')
              window.location.reload();
            }else if(err.message == 'Request aborted'){
              window.location.reload()
            }else{
              //toast.warn(api_error_message,{toastId:random_number});
window.location.reload()
            }
          });
        }else{
          props.parentCallback('quiz_false');
        }
       
       
      }
return(
    <>
   <div data-testid="AboutQuizComponent_content" ref={about_quiz} className="data-component col-12 custm_shadow_cls bg-body" >
    <span className="heading_span">{props.data.heading}</span>
    <div>
        <span className="quiz_text_span">{props.data.text}</span>
    </div>
    {<button
              className="btn review_button submit_button mt-3" 
              onClick={onSubmit}
            >
             <b>START QUIZ</b>
            </button>}
    </div>
  
    </>
)
}