import axios from "../../axios-interceptor";
import { useState, useEffect } from "react";
import { base_url,api_error_message,random_number } from "../../utilities";
import { BarChart } from "./barchart";
import { MessageComponent } from "./messageComponent";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { useRef, useLayoutEffect } from "react";
export const FeedbackOpenResponse = (props) => {
  let value;
  const history = useHistory();
  let [wordCount, setWordCount] = useState(0);
  let [responseValue, setResponseValue] = useState("");
  let [option_flag, setOptionFlag] = useState(false);
  let [flag,setFlag] = useState(false)
  const feedback_open_response = useRef(null);
  useLayoutEffect(() => {
    props.i == 0 
      ? setTimeout(() => {
          window.scrollTo({
            top: window.scrollY,
            left: 0,
            behavior: "smooth",
          });
        }, 350)
      : setTimeout(() => {
          window.scrollTo({
            top: feedback_open_response.current.offsetTop - 40,
            left: 0,
            behavior: "smooth",
          });
        }, 350);
  }, []);
  useEffect(() => {
    if (props.data.is_answered) {
      setOptionFlag(true)
      props.parentCallback('false');
      setFlag(true)
      setResponseValue(props.data.user_answer)
    }
  }, []);
 
  const getAnswer = (e) => {
    if (
      e.target.value
        .split(/\s+/)
        .map((element) => element.trim())
        .filter((element) => element !== "").length < 101
    ) {
      value = e.target.value;
      const result = value
        .split(/\s+/)
        .map((element) => element.trim())
        .filter((element) => element !== "");

      setWordCount(result.length);
      setResponseValue(value);

      return true;
    } else {
      return false;
    }
  };
  const onSubmit = () => {
    if(!flag){
    if (responseValue.trim().length != 0) {
      setOptionFlag(true);
      setFlag(true)
      setTimeout(() => {
        window.scrollTo({
          top: window.scrollY +window.innerHeight,
          left: 0,
          behavior: "smooth",
        });
      }, 300);
      let body = {
        user_id: localStorage.getItem("user_id"),
        course_id: localStorage.getItem("course_id"),
        lesson_id:
          localStorage.getItem("lessonId") == 1
            ? localStorage.getItem("cohort_id")
            : localStorage.getItem("lessonId"),
        cohort_schedule_id: localStorage.getItem("cohort_id"),
        type: localStorage.getItem("lessonId") == 1 ? 1 : 3,
        feedback: responseValue,
        question_id:props.data.question_id
      };
      axios
        .post(base_url + "feedback/save_feedback", body)
        .then((res) => {
          props.parentCallback("false");
        })
        .catch((err) => {
          console.log(err);
          props.parentCallback("");
          if(err.response?.statusText=='Unauthorized'){
            localStorage.clear();
            history.push('/')
            window.location.reload();
          }else if(err.message == 'Request aborted'){
            window.location.reload()
          }else{
            //toast.warn(api_error_message,{toastId:random_number});
window.location.reload()
          }
        });
    }
  }
  };
  return (
    <>
      <div
        ref={feedback_open_response}
        className="data-component col-12 custm_shadow_cls bg-body"
        data-testid="FeedbackOpenResponse_content"
      >
        {props.data.type == 3 && (
          <>
            <h2 className="question-title">Feedback</h2>
            <span className="summary_header_cls">{props.data.question}</span>
          </>
        )}
        {props.data.type == 1 && (
          <>
            <h2 className="question-title">{props.data.question}</h2>
          </>
        )}
        <div className="col-12">
          <div className="d-flex flex-column ">
            <textarea
              rows="5"
              cols="50"
              autoFocus={true}
              value={responseValue}
              disabled={option_flag}
              onChange={(e) => getAnswer(e)}
              placeholder="Type your response here"
              className="my_text_area"
              id="text-area"
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
            ></textarea>
            <div className="row">
              <div className="col-md-6 col-lg-6 col-sm-12">
                {/* <button className="btn my_btn_cls submit_button mt-4" style={{backgroundColor:"#735FC8",marginRight:"20px"}} disabled={flag} onClick={reviewPeerResponse}>Review</button> */}
                <button
                  className="btn my_btn_cls submit_button mt-4"
                  onClick={onSubmit} disabled={flag}
                >
                  Submit
                </button>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-12">
                <div className="mt-4 my_word_count">
                  <span style={{ float: "right" }}>
                    Current Word Count{" "}
                    <span style={{ fontWeight: "bold" }}>{wordCount}</span>
                  </span>
                  <br />
                  <span style={{ float: "right", lineHeight: 1.6 }}>
                    Word Limit <span style={{ fontWeight: "bold" }}>100</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
