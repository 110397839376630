import axios from "../../axios-interceptor";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { base_url, random_number,api_error_message, admin_maintenance_type } from "../../utilities";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import withMaintenanceCheck from "../../HigherOrderComponents/maintenancePageChecker";
import Select from 'react-select';

 const CreateProgram = ()=>{
  const history = useHistory();
    let [learning_tracks, setLearningTracks] = useState([]); 
    let [learning_track_id,setLearningTrackId]  = useState()
    let [course_flag,setCourseFlag] = useState(false)
    let [track_flag,setTrackFlag] =useState(true)
    let [selected_LearningTrack,setSelected_LearningTracks]=useState('')
    const [selectedOption, setSelectedOption] = useState(null);

   useEffect(() => {
    localStorage.removeItem("learning_track_selected");
    localStorage.removeItem("learning_track_name");
    localStorage.removeItem("save_obj");
    axios
      .post(base_url + "create/get_learning_track", {})
      .then((res) => {
        setLearningTracks(res.data.result);
      })
      .catch((err) => {
        console.log(err);
        if(err.response?.statusText=='Unauthorized' || err.response?.data?.statusText=='Unauthorized'){
          localStorage.clear();
          history.push('/adminlogin')
          window.location.reload();
        }else if(err.message == 'Request aborted'){
          window.location.reload()
        }else{
          //toast.warn(api_error_message,{toastId:random_number});
window.location.reload()
        }
      });
      if(localStorage.getItem('Selected_LearningTrack'))
      {
        setTrackFlag(true)
        setLearningTrackId(localStorage.getItem('Selected_LearningTrack'))

        localStorage.removeItem('Selected_LearningTrack')
      }
  }, []);
// const getLearningTrack = (e) =>{
//   setTrackFlag(true)
//   setCourseFlag(false)
//   setLearningTrackId(e.target.value)
// }
const goToProgram = () =>{
 
  if(track_flag){
    localStorage.setItem("learning_track_selected","true")
    if(learning_track_id ){
      history.push('/courseslist',{programme_id:undefined,learning_track_id:learning_track_id})
    }else{
   toast.error("Please select learning track",{toastId:random_number})
    }
  }else if(course_flag){
    localStorage.setItem("learning_track_selected","false");
    history.push('/courseprogram')
  }else{
    toast.error("Please select learning track or course to a program",{toastId:random_number})
  }

 
}
const getRadioChoice = (e) =>{
   if(e.target.id == "course"){
    localStorage.setItem("learning_track_selected", "false");
    setCourseFlag(true)
    setTrackFlag(false)
  }else{
    localStorage.setItem("learning_track_selected", "true");
    setCourseFlag(false)
    setTrackFlag(true)
  }
}
const options = learning_tracks.map((app) => ({
  value: app._id,
  label: app.learning_track_name
}));

const handleLearningTrackChange = (selectedOption) => {
  setTrackFlag(true);
  setCourseFlag(false);
  setLearningTrackId(selectedOption.value);
};


    return(
        <>
        <div className="bg-body container  mt-5  gap-4 vh-100">
         <div className="row ">
            <div className=" col-12 mb-4 program_option_heading">Select Your Preferred Option</div>
         
            <div className="d-flex justify-content-center gap-5">
              <div className="program_create_div">Create from</div>
              <div className="row">
                  <div className="col-lg-1 mt-1 mr-2">
                    <input className="program_input" data-testid="learningTrackRadio" id="learning_track" type="radio" checked={track_flag} name="program" onChange={getRadioChoice}></input>&nbsp;&nbsp;
                  </div>
                  <div className="col-lg-10">
                  <Select
                  id="inputState"
                  data-testid="learningTrack"
                  className="learningtrack_select"
                  options={learning_tracks.map((app) => ({
                    value: app._id,
                    label: app.learning_track_name
                  }))}
                  placeholder="Select Learning Track"
                  onChange={handleLearningTrackChange}
                  isSearchable
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '300px', // Set the desired width here
                    }),
                  }}
                  classNamePrefix="react-select-Programme"
                />
                </div>
            </div>
            <div className="mt-2">
            <input className="program_input" id="course" data-testid="courseRadio" type="radio" checked={course_flag} name="program" onChange={getRadioChoice}></input>&nbsp;&nbsp;
          <span className="course_span">Course</span>
            </div>
            </div>
         </div>
         <div className="text-right float-end"> <button className="my_btn_cls proceed_button" data-testid="ProceedButton"  onClick={goToProgram} style={{ marginTop: "0px" }}>
           Proceed
          </button></div>
        
        </div>
        </>
    )
}
export default withMaintenanceCheck(CreateProgram,admin_maintenance_type) 