
import axios from '../../axios-interceptor';
import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { base_url,api_error_message ,random_number, user_maintenance_type} from '../../utilities';
import UserCertificates from './UserCertificates';
import UserProfile from './UserProfile';
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory,useLocation } from "react-router-dom";
import withMaintenanceCheck from '../../HigherOrderComponents/maintenancePageChecker';


const UserProfileMain = () => {
    const history = useHistory();
    let location = useLocation()
    let [loading_flag, setLoading_flag] = useState(true)
    let [user_Details,SetUser_Details]=useState(null)
    let [user_Info,SetUser_Info]=useState([])
    let [user_Editable,setUserEditable]=useState(true)
    useEffect(() => {
        if(localStorage.getItem('user_id')){
            axios.post(base_url+'isb/user/get_user_details',{"user_id": localStorage.getItem('user_id')})
            .then(res=>{
                setLoading_flag(false)
                SetUser_Info(res.data?.result)
                SetUser_Details(res.data?.result[0])
            })
            .catch(err=>{
            console.log(err);
                if(err.response?.statusText=='Unauthorized' || err.response?.data?.statusText=='Unauthorized'){
                    localStorage.clear();
                    history.push('/')
                    window.location.reload();
                }else if(err.message == 'Request aborted' || err.message=== "Network Error"){
                    window.location.reload()
                  }else{
                    //toast.warn(api_error_message,{toastId:random_number});
window.location.reload()
                }
                setLoading_flag(false)
            })
        }else{
            localStorage.clear();
            history.push('/')
            window.location.reload();
        }
      }, [])

      const handleEditUserDetails=()=>{
        document.getElementById('Edit_Icon').style.color='#057092';
        setUserEditable(false)
      }

    const get_is_profile_mandatory = () => {
        if (localStorage.getItem('is_profile_mandatory')== 'true') {
            return true
        } else if(localStorage.getItem('is_profile_mandatory')=='undefined' || localStorage.getItem('is_profile_mandatory')=='null') {
            return true
        } else if(localStorage.getItem('is_profile_mandatory')== 'false') { 
            return false
        }
    }

  return (
    <>
    {loading_flag ? (
    <div>
    <img className="atrium_loader" src="images/atrium_loader.gif" alt="Loader" />
  </div>
      ) : <>
  
    <div className="">
        <div className="container container-onlineApp mt-5">
            <div className='background-white'>
            <div className="row p-2">
                {/* <div className="col-lg-2">
                <div className="profile_card">
                    <div className="d-flex flex-row-reverse align-items-center">
                    <div className="ms-3">
                        <img className="user_avatar_profile_aa" src="/images/user_profile_avatar.png" alt="" />
                        <div className='user_profile_change'>
                        <i class="fa-solid fa-camera vertical-align-middle profile_change_icon"></i>
                        </div>
                    </div>
                    </div>
                </div>
                </div> */}
                <div className="col-lg-8">
                    <div className='p-2'>
                    <div>
                        <span className='user_profile_name'>{user_Details?.first_name}</span>
                    </div>
                    <div>
                        <span className='user_profile_gmail'>{user_Details?.email}</span>
                    </div>
                    <div className='mt-1'>
                        <span className='pr-1'><i className="fa-solid fa-location-dot location_icon"></i></span>
                        <span className={`${window.innerWidth>993?'':'mobile_user_profile_address'} user_profile_address`}> {user_Details?.city}{user_Details?.city&&", "}{user_Details?.state}{user_Details?.state&&", "}{user_Details?.country}</span>
                    </div>
                    </div>
                </div>
            </div>
            </div>

        <div className='mt-4'>
            <Tabs
            defaultActiveKey={get_is_profile_mandatory()?(location?.state?.key):'Certificates-tab'}
            id="fill-tab-example"
            className="mb-3 my_toggle_tabs user_profile_tabs"
            >
         {get_is_profile_mandatory()&&<Tab eventKey="profile" title="My Profile">
            {user_Info.length>0 && <UserProfile userData={user_Info} isEditable={user_Editable} />}
            </Tab>}
            <Tab eventKey="Certificates-tab" title="My Certificates">
            <UserCertificates/>
            </Tab>
            </Tabs>
        </div>
        </div>
        </div>
        </>}
          </>
  )
}

export default withMaintenanceCheck(UserProfileMain,user_maintenance_type) 