import React, { useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Controller, useForm } from "react-hook-form";
import axios from "../../axios-interceptor";
import { base_url,random_number,api_error_message } from "../../utilities";
import Select from "react-select";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { studentenrollmentValidations } from "../../formValidationsRules";
//import 'tinymce/tinymce.css';
// import { useForm } from 'react-hook-form';
// import 'react-select/dist/react-select.css';
const StudentEnrollment = () => {
  const history = useHistory();
  const [content, setContent] = useState("");
  let [emailResult,setEmailResult]=useState([])
  let [loading_flag,SetLoading_flag]=useState(false)
  let [programsList, setProgramsList] = useState([]);
  let [emailsSentProgress, setEmailsSentProgress] = useState({numerator: 0, denominator: 0});
  const [selectedOption, setSelectedOption] = useState([]);
  const [selecteduserOption, setSelecteduserOption] = useState('exusers');
  let [selectedProgramme,setSelectedProgramme]= useState([]);
  let [options, setOptions] = useState([]);
  let [usersList, setUsersList] = useState([]);
  let [submit_flag,setSubmit_flag]=useState(false)
  const handleEditorChange = (content) => {
    setContent(content);
    setValue("body", content);
  };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    formState: { errors, isSubmitting, isValid },
  } = useForm({ mode: "all" });
 
  useEffect(() => {
    // reset();
    getAllProgrames()
    getAllUserLSt()
  }, []);

const getAllUserLSt=()=>{
axios.get(base_url+"isb/user/get_users")
.then(res=>{
let users=res?.data?.data
setUsersList(users)
setOptions(
   users.map(({ _id, first_name,email }) => ({ value: _id, label: (first_name+" ("+email+")") }))
 );
})
.catch(err=>{

  console.log(err);
})

  }

  const getAllProgrames=()=>{
    axios
    .post(base_url + "get_programes_for_admin")
    .then((res) => {
      setProgramsList(res.data.result);
      // Setloading_flag(false)
    })
    .catch((err) => {
      console.log(err);
      if(err.response?.statusText=='Unauthorized' || err.response?.data?.statusText=='Unauthorized'){
        localStorage.clear();
        history.push('/adminlogin')
        window.location.reload();
      }else if(err.message == 'Request aborted' || err.message=== "Network Error"){
        window.location.reload()
      }else{
        // alert("catch")
        //toast.warn(api_error_message,{toastId:random_number});
window.location.reload()
      }
    });
  }

  // const isInputValid = (input) => {
  //   try {
  //     const json = JSON.parse(input);
  //     if (Array.isArray(json)) {
  //       for (const obj of json) {
  //         if (!obj.hasOwnProperty("name") || !obj.hasOwnProperty("email")) {
  //           return "JSON is not valid";
  //         }
  //       }
  //       return true;
  //     }
  //   } catch (e) {}
  //   return "JSON is not valid";
  // };

  const isInputValid = (input) => {
    try {
      const json = JSON.parse(input);
      if (Array.isArray(json)) {
        for (const obj of json) {
          if (!obj.hasOwnProperty("name") || obj.name.trim() === "") {
            return "JSON is not valid: 'name' property is missing or empty";
          }
          if (!obj.hasOwnProperty("email") || obj.email.trim() === "") {
            return "JSON is not valid: 'email' property is missing or empty";
          }
        }
        return true;
      }
    } catch (e) {}
    return "JSON is not valid";
  }
  
  
  
  async function processEmails(emails) {
    if (emails.length === 0) {
      // Base case: all emails have been processed
      SetLoading_flag(false)
      setSubmit_flag(false)
      //reset email progress counter
      setEmailsSentProgress({numerator: 0, denominator: 0});
      return;
    }
  
    const email = emails[0];
    const { body, subject } = email;
    // Call the API with the current email object
    try {
      const response =await axios.post(base_url + "sfdc/student_enrollment_with_email_template", email);
      // Push the successful email to the emailResult state
      if(response.data?.message==="User already applied for these program")
      {
        email['status']="Applied"
        setEmailResult(prevEmailsRecords => [...prevEmailsRecords, email]);
      }
      else{   
        email['status']="Success"
        setEmailResult(prevEmailsRecords => [...prevEmailsRecords, email]);
      }

      setEmailsSentProgress(prevProgress => ({
        numerator: prevProgress.numerator+1,
        denominator: prevProgress.denominator
      }));
  
      // Call the function recursively with the remaining emails
      await processEmails(emails.slice(1));
    } catch (error) {
      // Push the failed email to the emailResult state
      email['status']="Failed"
      setEmailResult(prevEmailsRecords => [...prevEmailsRecords, email]);

      setEmailsSentProgress(prevProgress => ({
        numerator: prevProgress.numerator+1,
        denominator: prevProgress.denominator
      }));
      // Call the function recursively with the remaining emails
      await processEmails(emails.slice(1));
    }
  }
  const onSubmit = (formData) => {
    setSubmit_flag(true)
    setEmailResult([])
    const multiEmails=[]
    let {body,emailsto,subject,Users,programme}=formData    
    if(selecteduserOption=="exusers")
    {
      Users.forEach(item=>{
        usersList.map(userItem=>{
          if(item.value===userItem._id)
          {
            userItem["program_id"]=programme.value
            userItem['subject']=subject
            userItem['body']=body
            multiEmails.push(userItem)
          }
        })
      })
    }
    else{
      let emailsLst=emailsto==""?[]:JSON.parse(emailsto);
      emailsLst.forEach(item=>{
            let saveitem={...item}
            saveitem['first_name']=saveitem.name
            saveitem["program_id"]=programme
            saveitem['subject']=subject
            saveitem['body']=body
            delete saveitem.name
          multiEmails.push(saveitem)
      })
    }

  // Call the function to process the emails recursively
  setEmailsSentProgress(prevProgress => ({
    numerator: prevProgress.numerator,
    denominator: prevProgress.denominator+multiEmails.length
  }));
  
     SetLoading_flag(true)
     processEmails(multiEmails)
  };
  const handleSelectChange = (newValue) => {
    setSelectedOption(newValue)
    setValue("Users", newValue ? newValue: []);
};
const handleProgramChange=(newValue)=>{
  setSelectedProgramme(newValue)
  setValue("programme", newValue ? newValue: []);
//console.log(e.target.value);

}
const optionsP = programsList.map((item) => ({
  value: item.product_code,
  label: item.learning_track_name,
}));
const handleUserOptionChange = (event) => {
  setSelecteduserOption(event.target.value);
  setValue("emailsto","")
  setValue("Users","")
  setSelectedOption([])
}



  return (
    <>
  
      <div className="d-block text-center mb-4">
        <h3 className="custm_txt_clr mb-5">Student Enrollment</h3>
      </div>
      
      {loading_flag&&<>
                <div className="mt-5">
                  <img className="atrium_loader_StudentEnroll" src="/images/atrium_loader.gif" alt="Loader" />
                   <div className="mailCountStatus"><span>{emailsSentProgress.numerator}/{emailsSentProgress.denominator}</span></div>
                </div>
             </>}

      <div>
        <form data-testid="form">
          <div className="container">
          <div className="row">
              <div className="col text-center">
              <input type="radio" class="form-check-input" name="userRadio" value="exusers" checked={selecteduserOption=="exusers"} onChange={handleUserOptionChange} id="UserConsent1" data-testid="radio_element1"/>
                <label htmlFor="UserConsent1" class="d-inline pl-1 checkbox-wrap checkbox-primary"> Existing Users </label>
              </div>
              <div className="col text-center">
                <input type="radio" class="form-check-input" value="newuser" name="userRadio" checked={selecteduserOption=="newuser"} onChange={handleUserOptionChange}  id="UserConsent2" data-testid='radio_element2'/>
                <label htmlFor="UserConsent2" class="d-inline pl-1 checkbox-wrap checkbox-primary"> New Users </label>
              </div>
          </div>
             <br />
             {selecteduserOption=="exusers" &&
             <>
          <div className="row">
            <div className="col-lg-2">
            <label htmlFor="exampleInput">
                     Users <span className="requiredInput">*</span> :
                  </label>
            </div>
          <div className="col-lg-10">
            <div className="userdropdownstyle">
            <Controller
                    as={Select}
                    name="Users"
                    placeholder="users"
                    options={options}
                    isMulti
                    control={control}
                    rules={{ required:selecteduserOption=='exusers'?true:false }}
                    render={({ onChange, value, name }) => (
                    <Select
                    classNamePrefix="react-select-Student"
                    onChange={handleSelectChange}
                    value={selectedOption}
                    name={name}
                    isDisabled={selecteduserOption!=='exusers'}
                    options={options}
                    isMulti
                    />
                    )}
                    />
                    <select
                   id="programms"
                   aria-label=".form-select example"
                   name="programme_name"
                  data-testid="Programme_Dropdown"
                  className="form-select d-none"
                  multiple
                  role="presentation" autoComplete="off"
                //   value={pr}
                  // required
                  
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    const selectedOption = options.find(option => option.value === selectedValue);
                    const transformedOption = selectedOption;
                    handleSelectChange([transformedOption]); // Transform the selected option into an array and pass it to the handler
                  }}>
                  <option value=''>Select the Programme</option>
                  {options.map((item,index) => {
                     return <option  key={index} value={item.value} >{item.label}</option>;
                  })}
                </select>
                    {errors.Users && errors.Users.type === 'required'&&selecteduserOption=='exusers' && selectedOption.length==0 && <span className="form-label-color">user is required</span>}
            </div>
              </div>           
              </div>
             </>}

            {selecteduserOption=="newuser"&&
            <>
            <div className="row ml-1">
              <div className="col-lg-2">
                <label htmlFor="emailsto">To <span className="requiredInput">*</span> :</label>
              </div>
              <div className="col-lg-10">
                <textarea
                disabled={selecteduserOption=='exusers'}
                  id="emailsto"
                  name="emailsto"
                  data-testid='text_area2'
                  rows="4"
                  className="textarea_class rounded mt-0"
                  {...register("emailsto", {
                    required:studentenrollmentValidations.emailto,
                    validate: isInputValid,
                  })}
                ></textarea>
                {errors.emailsto?.message && selecteduserOption=='newuser'&& (
                  <span style={{ color: "red" }}>
                    {errors.emailsto?.message}
                  </span>
                )}
              </div>
            </div>
            <br />
            </>}

            {/* <div className="row">
              <div className="col-lg-2">
                <label htmlFor="emailtype" className="mt-2">
                  Email Type :
                </label>
              </div>
              <div className="col-lg-10">
                <input
                  id="emailtype"
                  name="emailtype"
                  type="text"
                  className="form-control"
                  {...register("emailtype", {
                    required: "Email Type is required",
                  })}
                />
                {errors.emailtype?.message && (
                  <span style={{ color: "red" }}>
                    {errors.emailtype?.message}
                  </span>
                )}
              </div>
            </div> */}
            <br />
            {/* <div className="row">
              <div className="col-lg-2">
                <label htmlFor="roletype" className="mt-2">
                  Role Type :
                </label>
              </div>
              <div className="col-lg-10">
                <input
                  id="roletype"
                  name="roletype"
                  type="text"
                  className="form-control"
                  {...register("roletype", {
                    required: "Role Type is required",
                  })}
                />
                {errors.roletype?.message && (
                  <span style={{ color: "red" }}>
                    {errors.roletype?.message}
                  </span>
                )}
              </div>
            </div> */}
            <br />

            <div className="row">
              <div className="col-lg-2">
                <label htmlFor="programme">Programme <span className="requiredInput">*</span> :</label>
              </div>
              <div className="col-lg-10 pl-4">
  <Controller
                    as={Select}
                    name="programme"
                    id="programme"
                    options={optionsP}
                    control={control}
                    rules={{ required: "programme is required" }}
                    render={({ onChange, value, name }) => (
                    <Select
                    value={selectedProgramme}
                    options={optionsP}
                    name={name}
                    placeholder="Select..."
                    onChange={handleProgramChange}
                    isSearchable={true}
                    isClearable
                    classNamePrefix="react-select-Student"
                    />
                    )}
                    />
                     <select
                   id="programms"
                   aria-label=".form-select example"
                   name="programme_name"
                  data-testid="Programme_Dropdown2"
                  className="form-select d-none"
                  
                  role="presentation" autoComplete="off"
                //   value={pr}
                  // required
                  
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    const selectedOption1 = optionsP.find(option => option.value === selectedValue);
                    const transformedOption = selectedOption1;
                    handleProgramChange([transformedOption]); // Transform the selected option into an array and pass it to the handler
                  }}>
                  <option value=''>Select the Programme</option>
                  {optionsP.map((item,index) => {
                     return <option  key={index} value={item.value} >{item.label}</option>;
                  })}
                </select>

                {errors.programme?.message && <span style={{ color: "red" }}>{errors.programme?.message}</span>}
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="col-lg-2">
                <label htmlFor="subject" className="mt-2">
                  Subject <span className="requiredInput">*</span> :
                </label>
              </div>
              <div className="col-lg-10 pl-4">
                <input
                  id="subject"
                  name="subject"
                  type="text"
                  className="form-control"
                  data-testid='input_change'
                  {...register("subject", studentenrollmentValidations.subject)}
                />
                {errors.subject?.message && (
                  <span style={{ color: "red" }}>
                    {errors.subject?.message}
                  </span>
                )}
              </div>
              <br />
            </div>
          </div>

          <div className="mt-4">
            <label htmlFor="">Body :</label>
            <Editor
              apiKey="1jl4n1cka72zftyk5y5sq27ioibmxki6jukqbch7ysalixae"
              cloudChannel="5-stable"
              disabled={false}
              id='tiny-editor'
              initialValue="Text"
              inline={false}
              onEditorChange={handleEditorChange}
              plugins=""
              tagName="div"
              textareaName="body"
              toolbar="undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat forecolor backcolor"
              value={content}
              outputFormat="html"
              data-testid="editor"
              init={{
                height: 500,
                language: "en",
                //menubar: false,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                convert_urls: false
              }}
              // Add aria-required attribute
              aria-required={true}
            />
             <textarea id="messageTextArea" data-testid="new_editor" className="d-none" name="textarea" value={content} onChange={(e)=>{
              const new_=e.target.value;
              handleEditorChange(new_)
             }} />
            {content == "" && (
              <span style={{ color: "red" }}>{"Email Body is required"}</span>
            )}

            <div className="d-block text-center">
              <div>
                <button
                  className="btn my_btn_cls mt-5 col-sm-3 col-lg-2 col-md-2 online_app_submit"
                  onClick={handleSubmit(onSubmit)}
                  disabled={content == ""|| loading_flag || submit_flag}
                  data-testid='handle_Submit'
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <br />
      {emailResult.length>0&&
      <>
      <div className="col bg-body custm_shadow_cls p-4 m-1">
        <div className="row">
          <div className="col">
            <h4 className="fw-bold mb-5">Mail Status</h4>
          </div>
          <div className="col">
            <button className="btn my_outline_button_cls submit_button fw-bold my_certificate_btn m-0 float-right" onClick={()=>{setEmailResult([])}} data-testid='email_id'>Clear</button>
          </div>
        </div>
    <div className="row pm_course_element">
    <div className="col-lg-4 fw-bold bg-isbBlue text-white pt-2 pb-2">Name</div>
    <div className="col-lg-6 fw-bold bg-isbBlue text-white pt-2 pb-2">Email</div>
    <div className="col-lg-2 fw-bold bg-isbBlue text-white pt-2 pb-2 pl-5">Status</div>

         {emailResult?.map(item =>{
            return(
                <>
                <div className="row pt-3"> 
                  <div className="col-lg-4">{item.first_name}</div>
                  <div className="col-lg-6"> {item.email}</div>
                  <div className="col-lg-2 text-center fw-bold"><span className={item.status=="Success"?'text-success':(item.status=="Applied"?'text-warning':'text-danger')}> {item.status}</span></div>
                 </div>
                </>   
                )
              })}    
         </div>
      </div>
      </> }
    </>
  );
};

export default StudentEnrollment;
