import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { base_url,date_formats, random_number,api_error_message, admin_maintenance_type } from "../../utilities";
import { Controller, useForm } from "react-hook-form";
import axios from "../../axios-interceptor";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isbuserspageValidations } from "../../formValidationsRules";
import withMaintenanceCheck from "../../HigherOrderComponents/maintenancePageChecker";

 const IsbUser = () => {
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState([]);
  let [values, setValues] = useState(null);
  let [options, setOptions] = useState([]);
  let [password, setPassword] = useState("");
  let [email, setEmail] = useState("");
  const {
    register,
    handleSubmit,
    setValue,
    reset,control,
    formState: { errors },
  } = useForm({ mode: "all",defaultValues: {first_name:'',last_name:'',email:'', courses: [] }});
  let [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    courses: [],
  });
  let [final_course, setFinalCourse] = useState([]);
  useEffect(() => {
    axios
      .post(base_url + "courses/get_all_courses")
      .then((res) => {
        let courses = res.data.courses.filter((item, index) => {
          if (item.cohort_schedules.length) {
            return item.cohort_schedules[0].is_deleted == 0;
          }
        });
        let final_array = [];
        setOptions(
          courses.map(({ id, title }) => ({ value: id, label: title }))
        );
        courses.map((item1) => {
          if (item1.cohort_schedules.length !== 0) {
            let samp_array = item1.cohort_schedules.sort((a, b) => {
              return b.start_date - a.start_date;
            });
            for (var item2 of samp_array) {
              if (item2.start_date < date_formats.convert_current_date_utc_format()) {
                final_array.push(
                  Object.assign(
                    {},
                    {
                      ["cohort_schedule_id"]: item2._id,
                      ["course_id"]: item2.course_id,
                    }
                  )
                );
                break;
              }
            }
          }
        });
        setFinalCourse(final_array);
      })
      .catch((err) => {
        console.log(err);
        if(err.response?.statusText=='Unauthorized'  || err.response?.data?.statusText=='Unauthorized'){
          localStorage.clear();
          history.push('/adminlogin')
          window.location.reload();
        }else if(err.message == 'Request aborted' || err.message=== "Network Error"){
          window.location.reload()
        }else{
          //toast.warn(api_error_message,{toastId:random_number});
window.location.reload()
        }
      });
  }, []);
  
  const handleChangeInput = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    for (const item in userData) {
      if (item == name) {
        setUserData({ ...userData, [item]: value });
      }
    }
  };
  const handleSelectChange = (newValue) => {
    setSelectedOption(newValue)
    setValue("courses", newValue ? newValue: []);
};
  const onSubmit = (data) => {
    let courses=[]
    final_course.map(course=>{
      data.courses.map(obj => {
        if(obj.value==course.course_id)
        {
          courses.push(course)  
        }
    });
    })
    data.courses=courses
    axios
      .post(base_url + "create/isb/user/course_create", data)
      .then((res) => {
        setUserData({
          first_name: "",
          last_name: "",
          email: "",
          courses: [],
        });
        setValues(null);
        setSelectedOption([]);
        setEmail(res.data.result.email);
        setPassword(res.data.result.password);
        toast.success("The user was created for this course...",{toastId:random_number});
      })
      .catch((err) => {
        if(err.response?.statusText=='Unauthorized'){
          localStorage.clear();
          history.push('/adminlogin')
          window.location.reload();
        }else if(err.message == 'Request aborted' || err.message=== "Network Error"){
          window.location.reload()
        }else{
          //toast.warn(api_error_message,{toastId:random_number});
window.location.reload()
        }
      });
  };

  return (
    <>
        <form>
          <div className="row align-items-center flex-column">
            <div className=" col-lg-6 mt-3">
              <label htmlFor="exampleInput">  
                First Name <span className="form-label-color">*</span>
              </label>
              <input
                {...register("first_name", isbuserspageValidations.first_name)}
                type="text"
                className="form-control"
                id="first_name"
                data-testid="first_name_input"
                name="first_name"
                value={userData.first_name}
                placeholder="Enter First Name"
                required
                role="presentation"
                autocomplete="off"
                onChange={handleChangeInput}
              />
              {errors.first_name?.message && (
                <span className="form-label-color">
                  {errors.first_name?.message}
                </span>
              )}
            </div>
            <div className=" col-lg-6 mt-3">
              <label htmlFor="exampleInput">
                Last Name <span className="form-label-color">*</span>
              </label>
              <input
                {...register("last_name", isbuserspageValidations.last_name)}
                type="text"
                className="form-control"
                id="last_name"
                data-testid="last_name_input"
                name="last_name"
                value={userData.last_name}
                placeholder="Enter Last Name"
                required
                role="presentation"
                autocomplete="off"
                onChange={handleChangeInput}
              />
              {errors.last_name?.message && (
                <span className="form-label-color">
                  {errors.last_name?.message}
                </span>
              )}
            </div>
            <div className=" col-lg-6 mt-3">
              <label htmlFor="exampleInput">
                Email <span className="form-label-color">*</span>
              </label>
              <input
                {...register("email", isbuserspageValidations.email)}
                type="text"
                className="form-control"
                data-testid="emai_user"
                id="email"
                name="email"
                value={userData.email}
                placeholder="Enter Email"
                required
                role="presentation"
                autocomplete="off"
                onChange={handleChangeInput}
              />
              {errors.email?.message && (
                <span className="form-label-color">
                  {errors.email?.message}
                </span>
              )}
            </div>
            {/* <div className="col-lg-6">
              <label htmlFor="exampleInput">
                Couse Name<span className="form-label-color">*</span>
              </label>
              <Select
                isMulti
                id="courses"
                name="courses"
                value={values}
                placeholder="Select Course Name"
                defaultValue={selectedOption}
                onChange={(e) => getCourses(e)}
                options={options}
              />
              {userData.courses.length == 0 && (
                <span className="form-label-color">
                  Course Name is required
                </span>
              )}
            </div> */}

            <div className="col-lg-6">
                  <label htmlFor="exampleInput">
                      Couse Name <span className="form-label-color">*</span>
                  </label>
                  <Controller
                    as={Select}
                    name="courses"
                    placeholder="Course Name"
                    options={options}
                    isMulti
                    control={control}
                    rules={{ required: true }}
                    render={({ onChange, value, name }) => (
                    <Select
                    onChange={handleSelectChange}
                    value={selectedOption}
                    name={name}
                    options={options}
                    isMulti
                    classNamePrefix="react-select-Student"
                    />
                    )}
                    />
                    <select
                          id="Courses"
                          aria-label=".form-select example"
                          name="Courses"
                          data-testid="course_select"
                          className="form-select d-none"
                          role="presentation" autoComplete="off"
                          onChange={handleSelectChange}>
                          {options.map((item,index) => {
                            return <option  key={index} value={item.value} >{item.label}</option>;
                          })}
                      </select>

                    {errors.courses && errors.courses.type === 'required' && selectedOption.length==0 && <span className="form-label-color">{isbuserspageValidations.course_name}</span>}
                </div>                  
          </div>
        </form>
        <div className=" mt-3 d-flex justify-content-center " >
          <button
            className="btn my_btn_cls ms-2" data-testid="submit_button_user"
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </button>
        </div>
        {password != "" && email != "" && (
          <div className=" mt-3 d-flex justify-content-center " >
            <span>Your Email is: {email} </span>&nbsp;&nbsp;&nbsp;
            <span>Your Password is: {password} </span>
          </div>
        )}
    </>
  );
};
export default withMaintenanceCheck(IsbUser,admin_maintenance_type) 