import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Popover } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../axios-interceptor';
import { admin_maintenance_type, api_error_message, base_url, date_formats, random_number } from "../../utilities";
import withMaintenanceCheck from "../../HigherOrderComponents/maintenancePageChecker";
const ProjectManagement = () => {
  const [checkbox, setCheckbox] = useState(false);
  const [userdata, setUserdata] = useState([]);
  let [usersList,setUsersList]=useState([])
  let [calenderList, setCalenderList] = useState([]);
  let [userScore,setUserScore]=useState(null)
  const [userID, setUserID] = useState([])
  const [cohortId, setCohortId] = useState([])
  let [totalGap, setTotalGap] = useState(0)
  const [selectedOption, setSelectedOption] = useState([]);
  let [options, setOptions] = useState([]);
  let [loadingFlag,setLoadingFlag]=useState(true)
  let [program_message,setProgramMessage] = useState(false)
  let [selectedprogram_name,setSelectedprogram_name] = useState('')
  let [courses,setCourses] = useState([])
  const {
    register,
    handleSubmit,
    setValue,
    reset,control,
    formState: { errors },
  } = useForm({ mode: "all"});
  const history = useHistory();

  const getdashboarddata = (result) =>{
    let userList=[];
    setLoadingFlag(true)
    axios.post(base_url+"program_manager/get_dashboard_data",
    {},
    // {
    //   headers: {
    //     'Authorization': `Bearer ${localStorage.getItem('Admin_Acesstoken')}`,
    //     'Content-Type': 'application/json',
    //   },
    // }
    ).then((res) => {
      if (res.data.data.programs) {
        let programs;
        if(result == "all"){
          programs = res.data?.data?.programs;
        }else{
          programs = res.data?.data?.programs.filter(element => date_formats.convert_current_date_utc_format() <= element.end_date);
        }
        let userList = [];
        programs.map(item => {
          item?.courses?.map(courseobj => {
            courseobj?.cohorts?.map(item2 => {
              let sortedArr = item2.users.sort((a, b) => (b.modules_left) - (a.modules_left));
              item2.users = sortedArr;
              if (item2.users.length > 0) {
                item2.users.forEach(usr => {
                  userList.push(usr);
                });
              }
          });
        });
      });
    
      //remove duplicates
      const ids = userList.map(o => o.id)
      const filtered = userList.filter(({id}, index) => !ids.includes(id, index + 1))
      const allOption={name:"All Users", id:"ALL"}
      filtered.unshift(allOption)
      setUsersList(filtered);
      setOptions(
        filtered.map(({ id, name }) => ({ value: id, label: name }))
        );
      setSelectedOption({label:"All Users", value:"ALL"})
        
        
      //find least date and max date in list
      let calender_start_date = programs.map(d => moment(d.calender_start_date))
        let minDate = moment.min(calender_start_date)
        let calender_end_date = programs.map(d => moment(d.calender_end_date))
       let maxDate = moment.max(calender_end_date)
        let dateStart = moment(minDate);
        let dateEnd = moment(maxDate);
        let interim = dateStart.clone();
        let timeValues = [];
        while (dateEnd > interim || interim.format('M') === dateEnd.format('M')) {
          timeValues.push(moment(interim).startOf('month').valueOf());
          interim.add(1,'month');
        }
        programs.map((item)=>{
        item['UsersDotsCount']=0
        item?.courses?.map(courseobj=>{
          courseobj?.cohorts?.map((item2)=>{
              item2.users.map(usr=>{
                usr['cohort_id']=item2.id
              })
              item2['dividedUsers']=[]
              item2['daysDiff']= moment(item2.end_date).diff(item2.start_date, 'days')
              //==0?15:moment(item2.end_date).diff(item2.start_date, 'days')
              item2['monthDiff']=moment(item2.start_date).diff(timeValues[0],'months')+1
              item2['start_date_days']=+moment(item2.start_date).format('DD')
              const perChunk = 25 // items per chunk    
              const inputArray = item2.users
              const result = inputArray.reduce((resultArray, item, index) => { 
              const chunkIndex = Math.floor(index/perChunk)
                if(!resultArray[chunkIndex]) {
                  resultArray[chunkIndex] = [] // start a new chunk
                }
                resultArray[chunkIndex].push(item)
                return resultArray
              }, [])
              item2['dividedUsers']=result
              if(result.length>0) item['UsersDotsCount']+=1
            })
            courseobj?.cohorts?.map((item2,index)=>{
              item2.dividedUsers.map((urslst,idx)=>{
                  const perChunk = 5 // items per chunk    
                  const inputArray = urslst
                  const result = inputArray.reduce((resultArray, item, index) => { 
                  const chunkIndex = Math.floor(index/perChunk)
                    if(!resultArray[chunkIndex]) {
                      resultArray[chunkIndex] = [] // start a new chunk
                    }
                    resultArray[chunkIndex].push(item)
                    return resultArray
                  }, [])
                  item2.dividedUsers[idx]=result
              })
          })
        })
        })
        let startMonth = moment(timeValues[0]).startOf('month');
        let currentMonth = moment()
        setTotalGap(currentMonth.diff(startMonth, 'days')+1)
        setCalenderList(timeValues) 
        setUserdata(programs);
        
      }else{
        setProgramMessage(true)
      }
      setLoadingFlag(false)
    }).catch((err) => {
      setLoadingFlag(false)
      if(err.response?.statusText=='Unauthorized'){
        localStorage.clear();
        history.push('/adminlogin')
        window.location.reload();
      }else if(err.message == 'Request aborted'){
        window.location.reload()
      }else{
        //toast.warn(api_error_message,{toastId:random_number});
window.location.reload()
      }
      console.log(err);
    });
  }

  useEffect(() => {
    getdashboarddata('active');
  }, []);
  const currentdays = new Date();
  const day = currentdays.getDate()
  function daysInThisMonth() {
    var now = new Date();
    return new Date(now.getFullYear(), now.getMonth()+1, 0).getDate();
  }
  const onhoverItem=(item,program)=>{
    setSelectedprogram_name(program.title)
    setCourses(program.courses)
    setUserID(item.id)
    setCohortId(item?.cohort_id)
    setUserScore(null)
   axios.post(base_url+'user/get_program_management_details',{
    "user_id":item?.id,
    "cohort_schedule_id": item?.cohort_id
   })
   .then((res)=>{
    setUserScore(res.data.result)
   })
   .catch((err)=>{
      console.log(err);
      if(err.response?.statusText=='Unauthorized'){
        localStorage.clear();
        history.push('/adminlogin')
        window.location.reload();
      }else if(err.message == 'Request aborted'){
        window.location.reload()
      }else{
        //toast.warn(api_error_message,{toastId:random_number});
window.location.reload()
      }
   })
  }
  const handleCheckboxChange = async (e) => {
    const isChecked = e.target.checked;
    setCheckbox(isChecked);
    if(e.target.checked == true){
      getdashboarddata('all');
    }else{
      getdashboarddata('active');
    }
  };

  const handleSelectChange = (newValue) => {
    if(newValue)
    {
      setSelectedOption(newValue)
      setValue("users", newValue ? newValue: []);
      filterProgrammsbyUser(newValue.value)
    }
    else{
      let defaultOption={label:"All Users", value:"ALL"}
      setSelectedOption(defaultOption)
      setValue("users", defaultOption);
      filterProgrammsbyUser("ALL")
    }
  };

const filterProgrammsbyUser=(selectedOptionvalue)=>{
  setLoadingFlag(true)
  let allProgramms=[...userdata]
  allProgramms.map((item)=>{
    item['UsersDotsCount']=0
    item?.courses?.map(courseobj=>{
      courseobj?.cohorts?.map((item2)=>{
          item2['dividedUsers']=[]
          const perChunk = 25 // items per chunk  
          let filteredUsers=[]
          if(selectedOptionvalue!=="ALL")
          {
            item2.users.map(singleUsr=>{
              if(singleUsr.id==selectedOptionvalue)
              {
                filteredUsers.push(singleUsr)
              }
            })
          }
          else{
            filteredUsers=item2.users
          }
          const inputArray = filteredUsers
          const result = inputArray.reduce((resultArray, item, index) => { 
          const chunkIndex = Math.floor(index/perChunk)
            if(!resultArray[chunkIndex]) {
              resultArray[chunkIndex] = [] // start a new chunk
            }
            resultArray[chunkIndex].push(item)
            return resultArray
          }, [])
          item2['dividedUsers']=result
          if(result.length>0) item['UsersDotsCount']+=1
        })
        courseobj?.cohorts?.map((item2,index)=>{
          item2.dividedUsers.map((urslst,idx)=>{
              const perChunk = 5 // items per chunk    
              const inputArray = urslst
              const result = inputArray.reduce((resultArray, item, index) => { 
              const chunkIndex = Math.floor(index/perChunk)
                if(!resultArray[chunkIndex]) {
                  resultArray[chunkIndex] = [] // start a new chunk
                }
                resultArray[chunkIndex].push(item)
                return resultArray
              }, [])
              item2.dividedUsers[idx]=result
          })
      })
    })
    })
    setUserdata(allProgramms);
    setLoadingFlag(false)
}


  const sendDataToViewFullProfile=()=>{
   history.push("/viewfullprofile", {
       id: userID,
       dates:calenderList,
       cohortId:cohortId,
       program_name:selectedprogram_name,
       courses:courses
     });
  }
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <div className="p-2">
          <div className="d-block float-right">
            <span><i className="fa-solid fa-xmark clock_clr cursor-pointer" onClick={() => document.body.click()} title="close"></i></span>
          </div>
          {userScore!==null?
          <>
          <div className="">
            <p className="mb-0 blue_text fs-4 fw-bold cursor-pointer user_name"  onClick={sendDataToViewFullProfile}>{userScore?.cohort_student?.first_name} {userScore?.cohort_student?.last_name}<img className="ms-1" width="22px" height="22px" src="/images/forward_icon.svg" alt="" /></p>
            <p className="mb-0 black fs-5 mt-1">{userScore?.cohort_student?.email}</p>
            <p className="mb-0 black fs-5">{userScore?.cohort_student?.mobile}</p>
          </div>
          <div  className="my-4">  
            <span><i className="fa-solid fa-diamond clock_clr me-3"></i></span> 
            <span className="profileInfo_text me-5">{userScore?.user_cohort_score}/{userScore?.maximim_course_score}</span>
            <span><i className="fa-regular fa-clock clock_clr me-3"></i></span>
            <span className="profileInfo_text">{userScore?.last_logged_in_days} {userScore?.last_logged_in_days>1?'days':'day'} ago</span>
          </div>
          <div>
            <table className="table borderless mb-0"  style={{marginLeft: userScore.lessons.length !=0 ? "-8px" : ""}}>  
              <thead>
                <tr>
                  <th className="fw-bold">Recent Activity</th>
                  <th className="fw-bold">Points</th>
                  <th className="fw-bold">Last Activity</th>
                </tr>
              </thead>
              <tbody>
                {userScore!==null&&userScore.lessons.map((item,index)=>{
                  return(
                    <Fragment key={index}>
                    <tr>
                      <td className="custon_td_item">
                        <span className="custom_title">{item.lesson_title}</span></td>
                      <td >{item.lesson_user_score}/{item.lesson_maximum_score}</td>
                      <td >{date_formats.human_date_time_format(item.lesson_tracking_created_date)}</td>
                    </tr>
                    </Fragment>
                  )
                })}
              </tbody>
            </table>
          </div>  
          </>
          :
            <div className="spinner-padding">
              <div>
               <img className="custom_atrium_loader_project" src="/images/atrium_loader.gif" alt="Loader" />
              </div>
            </div>
          }
        </div>
      </Popover.Body>
    </Popover>
  );
  

    return (
      <>
       {loadingFlag?<>
        <div>
        <img className="atrium_loader" src="/images/atrium_loader.gif" alt="Loader" />
      </div>
      </>:<>
      {program_message? <h3>No Programs Available</h3>:
     
    <div className=" mt-2 p-2 ">
      <div className="d-flex flex-row justify-content-between ">
        <div>
          <h3 className="custm_txt_clr mb-5">Current Programs</h3>
        </div>

        <div className="project_search">
                  <Controller
                    as={Select}
                    name="users"
                    placeholder="Course Name"
                    options={options}
                    isClearable={selectedOption?.value=="ALL"? false:true}
                    control={control}
                    rules={{ required: true }}
                    render={({ onChange, value, name }) => (
                    <Select
                    onChange={handleSelectChange}
                    value={selectedOption}
                    name={name}
                    options={options}
                    isClearable={selectedOption?.value=="ALL"? false:true}
                    />
                    )}
                    />
                    {/* {errors.courses && errors.courses.type === 'required' && selectedOption.length==0 && <span className="form-label-color">Course Name is  required</span>} */}
        </div> 
        


        <div className="d-flex flex-row justify-content-between  title_tags mt-1">
          <span>
            <img className="project_status" src="/images/green.svg" alt="" /> Active and on time.
          </span>
          <span>
            <img className="project_status" src="/images/yellow.svg" alt="" />
            Missed 1 Deadline
          </span>
          <span>
            <img className="project_status" src="/images/red.svg" alt="" />
            Missed &gt; 1 Deadline
          </span>
          <div className="ms-2 mt-1 visually-hidden">Last Refreshed 9:49 PM 8 Dec 2022</div>
        </div>
      </div>
      <div className="d-flex flex-row mt-2 ">
        <label htmlFor="allprogramms" className="me-4 blue_text fw-bold" style={{marginTop:"-3px"}} >All Programs</label>
        <input
          id="allprogramms"
          type="checkbox"
          className="program_checkbox"
          checked={checkbox}
          onChange={handleCheckboxChange}
        />
      </div>
      <div>
      

        <div className="projectmanager_dates">

        {calenderList.map((dtitem,idx)=>{
             return (
               <Fragment key={idx}>
               <div className="custom_width">{new Date(dtitem).toLocaleDateString('en-GB', {
    month : 'short',
    year : 'numeric'
})}
               </div>
              </Fragment>)
        })} 
        {<>
       
          <div className="custom_width visible-hidden">Date 2023
          </div>
         </>}
        </div>
        <div>      
      <img className="pointer_arrow" style={{marginLeft:(totalGap*8+250)+'px', marginBottom:"-40px"}} src="/images/pointer_arrow.svg" alt="" />
        </div>

        <>
          {userdata.map((item,idx)=>{
          return(<Fragment key={idx}>
          {item.UsersDotsCount>0&&(<>
            <div className="mt-4 projectManager_cohortcard" style={{width:((calenderList.length+1)*250)+100+'px',minWidth:'1000px'}}>

          <div className="Project_heading">
          {item?.title}{"-"} <br />
          {item?.program_id}

          </div> 

          {item?.courses.map((course,courseidx)=>{
            return(
              <Fragment key={courseidx}>
                 {course?.cohorts.map((cohort,cohortidx)=>{
                return(
                  <Fragment key={cohortidx}>
                  {cohort.dividedUsers.length>0&&(<>
                  <div style={{marginLeft: (cohort.monthDiff*250+20)+(cohort.start_date_days*8)+'px'}} className="pb-4">
                   <div className="project_title">
                     <span> {course?.title}</span> <span>| Available: {date_formats.human_date_format(item.available_date)} | Duration: {date_formats.human_date_format(cohort.start_date)} - {date_formats.human_date_format(cohort.end_date)}</span>
                    </div>
                  <div className="projectManger_cohort" style={{width:(cohort.dividedUsers.length>1?cohort.dividedUsers.length*140:(cohort.daysDiff*8))+'px',minWidth:'150px'}}>
                      <div className="projectManger_dots">
                      {cohort.dividedUsers.map((usr,index1)=>{
                        return (
                          <Fragment key={index1}>
                         
                          <div className="ml-3">
                            {usr.map((singleusr,suseridx)=>{
                            return(
                              <Fragment key={suseridx}>
                              <div className="projectmangament_dots">
                              {singleusr.map((row,rindex)=>{
                                return(
                                  <Fragment key={rindex}>
                                  {row.modules_left!==0&&row.modules_left!==1 && 
                                    <OverlayTrigger rootClose onEnter={()=>onhoverItem(row,item)} trigger="click" placement="auto" overlay={popover}>
                                       <span className="projectManager_point">
                                          <img className="dotsimgs" src="/images/red.svg" alt="" />
                                       </span>
                                    </OverlayTrigger>
                                    }
                                  { row.modules_left==1 && 
                                  <OverlayTrigger rootClose onEnter={()=>onhoverItem(row,item)} trigger="click" placement="auto" overlay={popover}>
                                     <span className="projectManager_point">
                                      <img className="dotsimgs"   src="/images/yellow.svg" alt="" />
                                    </span>
                                      </OverlayTrigger>
                                   }
                                   {row.modules_left==0 &&
                                  <OverlayTrigger rootClose  onEnter={()=>onhoverItem(row,item)}  trigger="click" placement="auto" overlay={popover}>
                                    <span className="projectManager_point" >
                                       <img  className="dotsimgs"   src="/images/green.svg" alt="" />
                                    </span>
                                    </OverlayTrigger>
                                    }
                                  </Fragment>
                                )
                              })}
                              </div>
                              </Fragment>
                            )
                          })}
                            </div>
                          </Fragment>
                        )
                      })}
                      </div>
                  </div>
                </div>
                  </>)}
                  </Fragment>
                )
              })}
              </Fragment>
            )
          })}
          </div>
          </>)}
          </Fragment>)
        })}
        </>
      </div>
    </div>}</>
}
    </>
  );
};

export default withMaintenanceCheck(ProjectManagement,admin_maintenance_type) 
