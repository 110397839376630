import axios from "../../axios-interceptor";
import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { base_url,api_error_message,random_number, user_maintenance_type} from "../../utilities";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import withMaintenanceCheck from "../../HigherOrderComponents/maintenancePageChecker";
import { crypto_secret_key } from "../../utilities";
import CryptoJS from "crypto-js";

const IsbLogin = () => {
  var [errorFlag, setErrorFlag] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const [banned, setBanned] = useState(false);
  // const {email}=props
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const {
    register,
    handleSubmit,setValue,
    formState: { errors },
  } = useForm({ mode: "all" });
  const history = useHistory();
  const onSubmitLogin = (data) => {
    let obj = {
      Email: data.Email.trim(),
      Password: data.Password.trim(),
    };

    let hashedpassword = CryptoJS.AES.encrypt(
      obj.Password,
      crypto_secret_key
    ).toString();

    axios
      .post(base_url + "login/isb/user", {
        email: obj.Email,
        password: hashedpassword,
      })
      .then((res) => {
        if(localStorage.paymentdashboard == "true"){
          localStorage.setItem("user_id", res.data.result._id);
          localStorage.setItem('User_Accesstoken',res.data.access_token)
          localStorage.setItem("isLoggedIn","true");
            history.push("/paymentdashboard")
        }else{
        if(res.data.result.is_banned){
          setBanned(res.data.result.is_banned)
          return false;
        }else{
          const {access_token,auth_refresh_token}=res.data
          if (res.data.result.length != 0) {
            setErrorFlag(false);
            localStorage.setItem("audit_user", false);
            localStorage.setItem("user_id", res.data.result._id);
            localStorage.setItem('User_Accesstoken',access_token)
            localStorage.setItem('User_Refreshtoken',auth_refresh_token)
            localStorage.setItem('isLoggedIn','true')
            localStorage.setItem('User_Name',res.data.result.first_name)
            localStorage.setItem('User_Email',res.data.result.email)
            localStorage.setItem('is_profile_mandatory',res.data.result.is_profile_mandatory)
            localStorage.setItem('is_verizon_student',res.data.result.is_verizon_student??0)
            // props.sessionProp.onLogin()
            userSession()
            axios
              .post(base_url + "login_tracking", {
                user_id: res.data.result._id,
                is_login: 1,
              })
              .then((res) => {
                localStorage.setItem('login_tracking',true)
                if(localStorage.getItem('setPassword_UserId'))
                {
                  history.push("/addressapplicationform");
                window.location.reload();
                }
                else{
                  
                  if(localStorage.getItem('free_lesson_url'))
                  {
                    let url = localStorage.getItem('prev_url')
                    history.push(url.split(window.location.host)[1])
                  }
                  else if(!localStorage.getItem('apply')){
                    history.push("/dashboard");
                    // window.location.reload();
                  }else{
                    history.push('/onlineapplication') 
                    localStorage.removeItem('apply')
                  window.location.reload();
                  }
                
                }
              })
              .catch((err) => {
                console.log(err);
                if(err.response?.statusText=='Unauthorized' ||err.response?.data?.statusText=='Unauthorized'){
                  history.push('/')
                }else if(err.message == 'Request aborted'){
                  window.location.reload()
                }else{
                  //toast.warn(api_error_message,{toastId:random_number});
window.location.reload()
                }
              });            
          } else {
            setErrorFlag(true);
          }
        }}
      })
      .catch((err) => {
        console.log(err);
        if(err.response?.statusText=='Unauthorized' ||err.response?.data?.statusText=='Unauthorized'){
          localStorage.clear();
          history.push('/')
          window.location.reload();
        }else if(err.message == 'Request aborted'){
          window.location.reload()
        }else{
          //toast.warn(api_error_message,{toastId:random_number});
window.location.reload()
        }
      });
  };

  const userSession=()=>{
    setInterval(() => {
      handleUserSessions()
    }, 50*60*1000);
  }
  const handleUserSessions=()=>{
    axios.post(base_url+"isb/user/refreshtoken",{
      "auth_refresh_token":localStorage.getItem('User_Refreshtoken')
    })
    .then(res=>{
      localStorage.setItem('User_Accesstoken',res.data?.access_token)
    })
    .catch(err=>{
      if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
        localStorage.clear();
        history.push('/')
        window.location.reload();
      }else if(err.message == 'Request aborted'){
        window.location.reload()
      }else{
        //toast.warn(api_error_message,{toastId:random_number});
window.location.reload()
      }
    })
  }

  useEffect(() => {
    if(localStorage.getItem("setPassword_UserId") == "undefined"){
      localStorage.removeItem("setPassword_UserId")
    }
    if (localStorage.getItem("User_Accesstoken") && localStorage.getItem('isLoggedIn')=='true' )
    {
      if(localStorage.getItem("paymentdashboard")=="true"){
        history.push("/paymentdashboard");
      }else if(!localStorage.getItem('setPassword_UserId'))
      {
        history.push("/dashboard");
      }else{
        let url = localStorage.getItem('prev_url')
        // history.push(url.split(window.location.host)[1])
      }
    } 
    else if(localStorage.getItem("Admin_Acesstoken")) {
      if(localStorage.getItem('Admin_role')==1)
      {
        history.push("/AdminDashboard");
      }
      if(localStorage.getItem('Admin_role')==2){
        history.push("/programmanagementdashboard");
      }
    }
    if(localStorage.getItem('leadform_email'))
    {
      setValue('Email',localStorage.getItem('leadform_email'),{ shouldDirty: true })
    }

    let setPassword_UserId=localStorage.getItem('setPassword_UserId')
    let setPassword_programId=localStorage.getItem('setPassword_programId');
    if(setPassword_UserId !== "undefined" && setPassword_UserId)
    {
      axios.post(base_url+'isb/user/get_user_details',{"user_id": setPassword_UserId})
      .then(res=>{
        setValue('Email', res.data?.result[0]?.email, { shouldDirty: true })
      })
      .catch(err=>{
        console.log(err);
        if(err.response?.statusText=='Unauthorized' ||err.response?.data?.statusText=='Unauthorized'){
          localStorage.clear();
          history.push('/')
          window.location.reload();
        }else if(err.message == 'Request aborted'){
          window.location.reload()
        }else{
          //toast.warn(api_error_message,{toastId:random_number});
window.location.reload()
        }
      })

    }
    if(setPassword_programId)
    {

    }
  }, []);

 const navToPasswordReset =() =>{
  history.push('/passwordreset')
 // window.location.reload()
 }

  return (
    <>
      <div>
        {
          <div style={{ width: "99%" }}>
            <div className="row justify-content-center mt-5">
              <div className="col-md-6 col-lg-4">
                <div className="isblogin-wrap p-4 p-md-5">
                  <div className="my_form_heading mb-2">
                    <span data-testid="Login_heading" className="loginPageHeading">Login to ISB Online</span>
                  </div>
                  {errorFlag && (
                    <div>
                      <span data-testid="Invalid_email_msg" className="text-danger ">
                        Invalid Email or Password
                      </span>
                    </div>
                  )}
                  {banned && (<div><span data-testid="banned_user_msg" className="text-danger">Your access is blocked due to non-payment. Please reach out to online_support@isb.edu for clearing your payment.</span></div>)}
                  <form
                    onSubmit={handleSubmit(onSubmitLogin)}
                    autoComplete="new-password"
                    className="login-form mt-2"
                  >
                    <div className="form-group mt-3 ">
                      <label htmlFor="Email">Email </label>
                      <input
                        {...register("Email", {
                          required: "Email is required",
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\s]{2,}))$/,
                            message: "Please enter a valid email",
                          },
                        })}
                        className="form-control rounded-left border-radius-none"
                        placeholder="Email"
                        type="email"
                        data-testid="Email"
                        id="Email"
                        name="Email"
                        autoFocus
                        role="presentation"
                        autoComplete="off"
                      />
                      {errors.Email?.message && (
                        <span  role="alert" style={{ color: "red" }}>
                          {errors.Email?.message}
                        </span>
                      )}
                    </div>
                    <div className="form-group mt-3">
                      <label htmlFor="Password">Password </label>
                      <input
                        {...register("Password", {
                          required: "Password is required",
                        })}
                        type={passwordType}
                        id="Password"
                        data-testid="Password"
                        name="Password"
                        className="form-control rounded-left border-radius-none"
                        placeholder="Password"
                      />
                      {passwordType === "password" ? 
                      
                      
                      <span className={`password-icon  ${errors.Password?.message? 'eyeIconTop' :''}`} role="button" data-testid="eye_button_hide_toggle" onClick={togglePassword}><i data-testid="eye_button_hide" className="fa-regular fa-eye-slash fs-sm  mr-2 mt-2 ml-1"></i> </span> : <span className={`password-icon  ${errors.Password?.message? 'eyeIconTop' :''}`} role="button" data-testid="eye_button_show_toggle" onClick={togglePassword}><i data-testid="eye_button_show" className="fa-regular fa-eye mr-2 mt-2 ml-1" /></span>}
                      {errors.Password?.message && (
                        <span  role="alert" style={{ color: "red" }}>
                          {errors.Password?.message}
                        </span>
                      )}
                    </div>
                    <div className="my_form_heading mt-1">
                      <button data-testid="submit-button" className="btn my_btn_cls my_btn_login my_submit_btn">
                        Login
                      </button>
                    </div>
                    <br />
                    <div className="justify-content-center d-flex mt-3">
                      <span data-testid="forgotPassword_textLink" className="forgotpassword">
                        Forgot Password ?{" "}
                        <span className="text-underline cursor-pointer" data-testid="password_reset" onClick={navToPasswordReset} >
                          Send Reset Email
                        </span>
                      </span>
                    </div>
                  </form>
                  {/* for test cases */}
                  <span className="d-none" data-testid="sessionCaller" onClick={handleUserSessions}></span>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default withMaintenanceCheck(IsbLogin,user_maintenance_type)
