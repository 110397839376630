
import React from 'react'
import { useState, useEffect } from "react";
import { base_url,api_error_message,random_number, admin_maintenance_type} from "../../utilities";
import { courseUploadValidations} from "../../formValidationsRules";
import { useForm } from "react-hook-form";
import axios from "../../axios-interceptor";
import { useHistory } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from 'react-select';
import withMaintenanceCheck from '../../HigherOrderComponents/maintenancePageChecker';


const CourseUpload = () => {
    const history=useHistory()
    const { register, handleSubmit, setValue,reset, formState: { errors } } = useForm({ "mode": "all" });
    let [LearningTrackList, setlearningTracksList] = useState([])
    let [LearningTracksScheduleOption, setLearningTracksScheduleOption] = useState(null)
    let [coursename, setCoursename] = useState(null)
    let [coursecost, setCourseCost] = useState('')
    let [courseimage, setCourseUrl] = useState(null)
    let [coursetakeaway, setCourseTakeAway] = useState(null)
    let [course_durt, setCourseDurt] = useState(null)
    let [course_durt_per_week, setCourseDurtPerWeek] = useState(null)
    let [coursedescription, setCourseDescription] = useState(null)
    var [timer_flag, setTimeFlag] = useState(true);
    let [file, setFile] = useState()
  
    useEffect(() => {
        let selectedTab=localStorage.getItem("selected_tab")
        if(selectedTab)
        {
           // document.getElementById(selectedTab).click()  
        }
        localStorage.removeItem("admin_course")
        // getting LearningTrack schedules list
        axios.post(base_url + "create/get_learning_track", {})
            .then((res) => {
                setlearningTracksList(res.data.result)
            }).catch(err => {
                console.log(err);
                if(err.response?.statusText=='Unauthorized'){
                    localStorage.clear();
                    history.push('/adminlogin')
                    window.location.reload();
                }else if(err.message == 'Request aborted'){
                    window.location.reload()
                  }else{
                  //toast.warn(api_error_message,{toastId:random_number});
window.location.reload()
                }
            })

    }, [])

    // const handleChange = (e) => {
    //     const target = e.target;
    //     const name = target.learning_track_name;
    //     const value = target.value;
    //     if (name == 'LearningTrack') {
    //         if (value !== "") {
    //             LearningTrackList.map(obj => {
    //                 if (value == obj._id) {
    //                     setLearningTracksScheduleOption(value)
    //                 }
    //             })
    //             // setValue('pronoun', value, { shouldDirty: true })
    //         }
    //     }
    // }
    const handleChange = (selectedOption) => {
        if (selectedOption) {
          const value = selectedOption.value;
    
          LearningTrackList.forEach(obj => {
            if (value === obj._id) {
              setLearningTracksScheduleOption(value);
            }
          });
        }
      };
    

    const handleChangeInput = (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;
     if(name=='course_title'){
        setCoursename(value)
     }
     if(name=='course_cost'){
        const pattern = /^[0-9]*$/;
        if (value === "" || pattern.test(value)) {
            setCourseCost(value)
        }
     }
     if(name=='image_url'){
        setCourseUrl(value)
     }
     if(name=='course_takeaway'){
        setCourseTakeAway(value)
     }
     if(name=='description'){
        setCourseDescription(value)
     }
     if(name == 'durtnweeks'){
        setCourseDurt(value)
     
     }
     if(name == 'durtnrsperweek'){
  
        setCourseDurtPerWeek(value)
     }

    }

    const handleChangeFileUpload = (event) => {
        setFile(event.target.files[0])
    }
    useEffect(() => {
        let locStore=localStorage.getItem('adminSelectedTab')
        if(locStore)
        {
            if(locStore=='create-program')
            {
              //  document.getElementById('Programs-open-tab').click()
            }
            if(locStore == 'list-course')
            {
              //  document.getElementById('ListCourses-tab').click()
            }
        }
 
 
      }, [])

    const  UploadCourse=(event)=> {
        const url = base_url + 'files/save_file_jsons';
        let formData = new FormData();
        if(LearningTracksScheduleOption !=null){
            formData.append('learning_track_id', LearningTracksScheduleOption); 
        }
        formData.append('file', file);
        formData.append('course_title', coursename);
        formData.append('course_cost', coursecost);
        formData.append('courseimage',courseimage);
        formData.append('course_takeaway',coursetakeaway);
        formData.append('durtnweeks',course_durt);
        formData.append('durtnrsperweek',course_durt_per_week)
        formData.append('description', coursedescription);
        setTimeFlag(false)
        axios.post(url, formData)
        .then((response) => {
            setTimeFlag(true);
            setLearningTracksScheduleOption(null)
            setCourseCost('')
            alert(response.data.result.message)
            localStorage.setItem("admin_course", response.data.result.courses[0])
            // setCourseResponseData({ data: response.data.result })
            reset()
        // navigate to blow comp if course uploads successfully 
           //CourseView
           history.push('/admindashboard/courseview')
        }).catch(err=>{
            console.log(err);
            if(err.response?.statusText=='Unauthorized'){
              localStorage.clear();
              history.push('/adminlogin')
              window.location.reload();
            }else if(err.message == 'Request aborted'){
                window.location.reload()
              }else{
              //toast.warn(api_error_message,{toastId:random_number});
window.location.reload()
            }
        })

    }
    const options = LearningTrackList.map((item, index) => ({
        value: item._id,
        label: item.learning_track_name,
      }));

  return (
   <>
     <div>
     <div className="ml-5 mb-5">
            <h3 className="admin-tabs-heading">Course Upload</h3>
          </div>
       
                                <form >
                                <div className="row align-items-center flex-column">
                                  
                                    <div className=" col-lg-6">
                                        <label htmlFor="exampleInput">Course Name <span className="form-label-color">*</span></label>
                                        <input {...register("course_title", courseUploadValidations.course_title)}
                                            type="text"
                                            className="form-control"
                                            id="course_title"
                                            name="course_title"
                                            placeholder=" "
                                            required
                                            role="presentation" autoComplete="off"
                                            onChange={handleChangeInput}
                                        />
                                        {errors.course_title?.message && <span className="form-label-color">{errors.course_title?.message}</span>}
                                    </div>
                                    <div className=" col-lg-6 mt-3">
                                        <div className="row">
                                            
                                              <div className="col-lg-6">
                                        <label htmlFor="exampleInput"> Learning Track</label>
                                            <Select {...register("LearningTrack",courseUploadValidations.LearningTrack)}
                                            id="LearningTrack"
                                            name="LearningTrack"
                                            role="presentation"
                                            autoComplete="off"
                                            options={options}
                                            placeholder="Select"
                                            onChange={handleChange}
                                            isClearable
                                            classNamePrefix="react-select-Programme"
                                            />
                                        {errors.LearningTrack?.message && <span className="form-label-color">{errors.LearningTrack?.message}</span>}
                                    </div>
                                           
                                            <div className="col-lg-6">
                                            <label htmlFor="exampleInput">Course Cost <span className="form-label-color">*</span></label>
                                        <input {...register("course_cost", courseUploadValidations.course_cost)}
                                            type="text"
                                            className="form-control"
                                            id="course_cost"
                                            name="course_cost"
                                            placeholder=" "
                                            required
                                            value={coursecost}
                                            role="presentation" autoComplete="off"
                                            onChange={handleChangeInput}
                                        />
                                         {errors.course_cost?.message && <span className="form-label-color">{errors.course_cost?.message}</span>}
                                            </div>
                                        </div>
                                       
                                    </div>
                                    <div className=" col-lg-6 mt-3">
                                        <label htmlFor="exampleInput">Upload TOROUS Zip file <span className="form-label-color">*</span></label>
                                        <input
                                            {...register("file", courseUploadValidations.file)}
                                            type="file"
                                            className="form-control"
                                            id="file"
                                            name="file"
                                            placeholder="Please select a file"
                                            required
                                            role="presentation" autoComplete="off"
                                            onChange={handleChangeFileUpload}
                                        />
                                        {errors.file?.message && <span className="form-label-color">{errors.file?.message}</span>}
                                    </div>
                                    <div className="col-lg-6">
                                            <label htmlFor="exampleInput">Image Url</label>
                                        <input 
                                            type="text"
                                            className="form-control"
                                            id="image_url"
                                            name="image_url"
                                            placeholder=" "
                                            required
                                            role="presentation" autoComplete="off"
                                            onChange={handleChangeInput}
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                            <label htmlFor="exampleInput">Course Takeaway</label>
                                        <input 
                                            type="text"
                                            className="form-control"
                                            id="course_takeaway"
                                            name="course_takeaway"
                                            placeholder=" "
                                            required
                                            role="presentation" autoComplete="off"
                                            onChange={handleChangeInput}
                                        />
                                    </div>
                                    <div className=" col-lg-6 mt-3">
                                        <div className="row">
                                            
                                              <div className="col-lg-6">
                                        <label htmlFor="exampleInput">Duration Week </label>
                                        <input {...register("durtnweeks", courseUploadValidations.durtnweeks)}
                                            type="text"
                                            className="form-control"
                                            id="durtnweeks"
                                            name="durtnweeks"
                                            placeholder=" "
                                            required
                                            role="presentation" autoComplete="off"
                                            onChange={handleChangeInput}
                                        />
                                        {errors.durtnweeks?.message && <span className="form-label-color">{errors.durtnweeks?.message}</span>}
                                    </div>
                                           
                                            <div className="col-lg-6">
                                            <label htmlFor="exampleInput">Duration Per week </label>
                                        <input {...register("durtnrsperweek",courseUploadValidations.durtnrsperweek)}
                                            type="text"
                                            className="form-control"
                                            id="durtnrsperweek"
                                            name="durtnrsperweek"
                                            placeholder=" "
                                            required
                                            role="presentation" autoComplete="off"
                                            onChange={handleChangeInput}
                                        />
                                         {errors.durtnrsperweek?.message && <span className="form-label-color">{errors.durtnrsperweek?.message}</span>}
                                            </div>
                                        </div>
                                       
                                    </div>
                                    <div className=" col-lg-6 mt-1 text-area-font">
                                        <label htmlFor="exampleInput" for="course_title">Description</label>
                                        <textarea
                                            type="text"
                                            id="description"
                                            name="description"
                                            placeholder=" "
                                            required    
                                            role="presentation" autoComplete="off"
                                           onChange={handleChangeInput}
                                           cols="30" rows="1"
                                        />
                                    </div>
                                </div>

                            </form>

                            <div className="my_custom_submit d-flex justify-content-center ">
                                {!timer_flag ? (<>
                                    <div>
                                        <img className="atrium_loader" src="/images/atrium_loader.gif" alt="Loader" />
                                    </div>
                                </>
                                ) :
                                    <button
                                        type="button"
                                        className="btn my_btn_cls ms-2"
                                        value="Add Cohort"
                                        onClick={handleSubmit(UploadCourse)}
                                    >

                                        Submit
                                    </button>
                                }
                            </div>  
    </div>
   </>
  )
}

export default withMaintenanceCheck(CourseUpload,admin_maintenance_type)