import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import List from "../../listofStates";
import Countries from "../../ListofCountries";
import { Link, useLocation, useParams } from "react-router-dom";
import { button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useState, useEffect,Fragment } from "react";
import { base_url, setUrl,downloadPdf,date_formats,api_error_message,random_number} from "../../utilities";
import moment from "moment";
import badgeImgData from '../../BadgeImage'
import ReactTooltip from "react-tooltip";
import { OverlayTrigger,Tooltip } from 'react-bootstrap';
import axios from "../../axios-interceptor";
import html2canvas from 'html2canvas';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const colCls = "col-lg-6 col-md-6 col-sm-12 my_custom_col";
const rowcolCls = "col-lg-3 col-md-3 col-sm-12 my_custom_col";
function SamplePrevArrow(props) {
const { className, style, onClick } = props;
return (
  <div
    className={className}
    style={{ ...style}}
    onClick={onClick}>
        <img src="/images/arrow_backward.svg"></img>
    </div>

);
}
function SampleNextArrow(props) {
const { className, style, onClick } = props;
return (
  <div
    className={className}
    style={{ ...style }}
    onClick={onClick}>
      <img src="/images/arrow_forward.svg"></img>
  </div>
);
}
const UserCertificates = (props) => {
  let [loading_flag, setLoadingFlag] = useState(true);

  let [past_courses, setPastCourse] = useState([]);
  let [dynamic_badge, SetDynamicBadge] = useState({});
  let [learning_track,setLearningTrack] = useState([])
  let [score_flag,setScroreFlag] =useState(true)
  let [scores,setScores] = useState([])
  const badgeimageRef = useRef(null);
  const history = useHistory();
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows:true,
    autoplay:true,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
  };
//badge sharing 
const canvasRef = useRef(null);
const imgRef = useRef(null);

useEffect(() => {
  imgRef.current = new Image();
  imgRef.current.src =badgeImgData;
  imgRef.current.onload = () => {
  //  const noValue=""
  // generatePreview("Decision Making","CRITICAL THINKING");
  };
}, []);

const generatePreview = (ModuleNameInput,CourseNameInput) => {
  const canvas = canvasRef.current;
  const context = canvas.getContext("2d");

  const textInput = ModuleNameInput
  const courseText=CourseNameInput

  canvas.width = imgRef.current.width;
  canvas.height = imgRef.current.height;

  context.clearRect(0, 0, canvas.width, canvas.height);
  context.drawImage(imgRef.current, 0, 0);

  context.font = "36px Arial";
  context.fillStyle = "white";
  context.textAlign = "center";
  context.textBaseline = "middle";

  const maxWidth = 350; // Maximum width of the text
  const lineHeight = 42; // Line height for the text
  const x = canvas.width / 2; // Horizontal center position
  let y = ModuleNameInput.length >= 33?215:canvas.height / 2 - lineHeight; // Vertical center position

  // Split text into lines based on the maximum width
  const words = textInput.split(' ');
  const lines = [];
  let currentLine = words[0];
  for (let i = 1; i < words.length; i++) {
    const word = words[i];
    const width = context.measureText(currentLine + ' ' + word).width;
    if (width < maxWidth) {
      currentLine += ' ' + word;
    } else {
        lines.push(currentLine);
        currentLine = word;
    }
  }
    lines.push(currentLine);
  // Draw each line of text
  for (let j = 0; j < lines.length; j++) {
    context.fillText(lines[j], x, y + j * lineHeight);
  }
  // Display preview
  const previewContainer = document.getElementById("previewContainer");
  previewContainer.innerHTML = '';
  previewContainer.appendChild(canvas);

  if(ModuleNameInput!=""){
    generateBadge()
  }
  };

const generateBadge = () => {
  const canvas = canvasRef.current;
  canvas.toBlob((blob) => {
    if (blob) {
      const fd = new FormData();
      let user_id= localStorage.getItem("user_id")
      let course_id= selectedModuleInfo.course_id
      let module_id= selectedModuleInfo.module_id
      let cohort_schedule_id= selectedModuleInfo.cohort_schedule_id
       if(user_id&&course_id&&module_id&&cohort_schedule_id)
       {
         fd.append("user_id",user_id)
         fd.append("course_id",course_id)
         fd.append("module_id",module_id)
         fd.append("cohort_schedule_id",cohort_schedule_id)
         fd.append("file",blob,`${selectedModuleInfo.module_name}-${selectedModuleInfo.course_name}.jpg`)  
         axios.post(base_url+'badges/generate_badge_url',fd)
         .then(res=>{
           const certificate_id=res.data?.badge_url
           if(certificate_id)
           {
              window.location.assign(`/backend/credentials/${certificate_id}`)
           }
           else{
             setLoadingFlag(false)
             toast.warn("Failed to share", { toastId: random_number });
           }
         })
         .catch(err=>{
           setLoadingFlag(false)
           console.log(err);
         })
       }
       else{
         setLoadingFlag(false)
         toast.warning("Something went wrong")
       }
    }
  }, "image/jpeg");
};

  useEffect(() => {
    axios
    .post(base_url + "courses/get_my_certificates_scores", {
      user_id: localStorage.getItem("user_id")  //"auth0|63998ffd9c43cd6f74e7adfb",
    })
    .then((res) => {
      setScroreFlag(false)
      setScores(res.data.data)
    })
    .catch((err) => {
      setScroreFlag(false)
      console.log(err);
      if(err.response?.statusText=='Unauthorized'|| err.response?.data?.statusText=='Unauthorized'){
        localStorage.clear();
        history.push('/')
        window.location.reload();
      }else if(err.message == 'Request aborted'){
        window.location.reload()
      }else{
        //toast.warn(api_error_message,{toastId:random_number});
window.location.reload()
      }
    });
    axios
      .post(base_url + "courses/get_user_certificates", {
        user_id: localStorage.getItem("user_id")  //"auth0|63998ffd9c43cd6f74e7adfb",
      })
      .then((res) => {
        setLoadingFlag(false)
          if(res.data?.learning_track_data){
            let learning_trackData = res.data?.learning_track_data
            let foundRecords_length = 0;
            for (let index = 0; index < learning_trackData.length; index++) {
              const learning_item = learning_trackData[index];
              if ((learning_item.learning_track_certificate_url == null || learning_item.learning_track_certificate_url == '') && (learning_item.program_total_courses_count !== learning_item.recieved_certificates_count)) {
                foundRecords_length++;
              }
            }
            if(foundRecords_length !== learning_trackData.length){
              setLearningTrack(res.data?.learning_track_data)
            }
          }
          setPastCourse(res.data?.data);
      })
      .catch((err) => {
        console.log(err);
        if(err.response?.statusText=='Unauthorized'|| err.response?.data?.statusText=='Unauthorized'){
          localStorage.clear();
          history.push('/')
          window.location.reload();
        }else if(err.message == 'Request aborted'){
          window.location.reload()
        }else{
          //toast.warn(api_error_message,{toastId:random_number});
window.location.reload()
        }
      });
     
  }, []);


  const ShowDynamicBadge =  (id) => { 
    SetDynamicBadge({})
    SetDynamicBadge(prevFlags => ({ ...prevFlags, [id]: true }));
  }

  const HideDynamicBadge =  (id) => {
    SetDynamicBadge(prevFlags => ({ ...prevFlags, [id]: false }));
  }
  const shareCourseCertificate=(item)=>{
    axios.post(base_url+'get_course_certificate_shareable_meta_data',{
      user_id: localStorage.getItem("user_id"),
      course_id: item.course_id,
      cohort_schedule_id: item.cohort_schedule_id
    })
    .then(res=>{
      const certificate_id=res.data?.badge_url
      if(certificate_id)
      {
        // history.push(`/digital-credentials/${certificate_id}`)
        window.location.assign(`/backend/credentials/${certificate_id}`)
      }
      else{
        toast.warn("Failed to share", { toastId: random_number });
      }
    })
    .catch(err=>{
      console.log(err);
    })
     }
  const shareProgrameCertificate=(item,is_distinction_certificate)=>{
    axios.post(base_url+'get_program_certificate_shareable_meta_data',{
      user_id: localStorage.getItem("user_id"),
      "program_id": item.program_id,
      "is_distinction_certificate": is_distinction_certificate
    })
    .then(res=>{
      const certificate_id=res.data?.badge_url
      if(certificate_id)
      {
        // history.push(`/digital-credentials/${certificate_id}`)
        window.location.assign(`/backend/credentials/${certificate_id}`)
      }
      else{
        toast.warn("Failed to share", { toastId: random_number });
      }
    })
    .catch(err=>{
      console.log(err);
    })
     }

     let [selectedModuleName,setSelectedModuleName]=useState('')
     let [selectedCourseName,setSelectedCourseName]=useState('')
     let [selectedModuleInfo,setSelectedModuleInfo]=useState({})
     const shareHandler=(module,item)=>{
        let stateObject={
          cohort_schedule_id:item.cohort_schedule_id,
          course_id:item.course_id,
          course_name:item.course_title,
          module_id:module._id,
          module_name:module.module_title
        }
           setSelectedModuleInfo(stateObject)
           setSelectedModuleName(module.module_title)
           setSelectedCourseName(item.course_title)
        }

    useEffect(() => {
      let modulename=selectedModuleName.toUpperCase()
      let courseName=selectedCourseName.toUpperCase()
      if(modulename&&courseName)
      {
        generatePreview(modulename,courseName)
      }
    }, [selectedModuleName,selectedCourseName])
    
  return (
    <>
      {loading_flag ? (
           <div>
           <img className="atrium_loader_custom" src="images/atrium_loader.gif" alt="Loader" />
       </div>
      ) : (
        <>
               <div className='mt-4' data-testid="certificate_maincontainer">
            <Tabs
            defaultActiveKey="course-tab"
            id="fill-tab-example"
            className="mb-3 user_profile_tabs  mobile-tabs "
            data-testid='user_profile_tabs'
            >
            <Tab eventKey="course-tab" data-testid='course_certificates_tab' title="Course Certificate">
            <div>
            {past_courses.map((item, index) => {
              return (
                <Fragment key={index}>
                  <div className="container container-onlineApp mt-5">
                    {item.show_overview &&
                    <div className="form_field_container_cls_online_applictn">
                  {window.innerWidth>993?
                  <>
                    <div className="row">
                      <div className="col-9 certificatesHead" data-testid="course_title">
                        {item.course_title} {" "}
                      </div>
                      <div className="col-3">
                        {item.course_takeaway && (item.course_takeaway!='null' )? ( 
                        <div className=""><a data-testid="course_takeaway" className={`btn submit_button fw-bold my_certificate_btn m-0 my_btn_cls top-0 end-0 my_btn_userCertificates lesson_page_outline px-4 ${date_formats.convert_current_date_utc_format() > item.cohort_end_date?'':'disabled'}`} href={item.course_takeaway} target="_blank" download>Download Takeaway</a></div>) : "" }
                      </div>
                    </div>
                      <div className="row mt-4">
                        <div className="col-3">
                          <p className="blue_text badges-class" data-testid="your_score">Your Score</p>
                          {score_flag ? <div className="pl-35"> <div className="spinner-border text-primary spinner-border-sm" role="status"><span className="visually-hidden">Loading...</span></div></div>:
                          <p data-testid="your_score_values"><b>{scores[index]?.total_earned_score} </b>/ {scores[index]?.total_course_score}  Points</p>}
                          <p className="blue_text badges-class mt-4" data-testid="time_spent">Time Spent</p>
                          {score_flag ? <div className="pl-35"> <div className="spinner-border text-primary spinner-border-sm" role="status"><span className="visually-hidden">Loading...</span></div></div>: <p data-testid="time_spent_values"><b>{Math.floor(moment.duration(scores[index]?.total_time_spent,'seconds').asHours())!=0? Math.floor(moment.duration(scores[index]?.total_time_spent,'seconds').asHours()) + ' hrs ' + moment.duration(scores[index]?.total_time_spent,'seconds').minutes() + ' min ' :moment.duration(scores[index]?.total_time_spent,'seconds').minutes() + ' min ' }</b></p>}
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <p className="blue_text badges-class" data-testid="badges_title">Badges</p>
                            {item.modules.map((module,ind)=>{
                              return(
                                <Fragment key={ind}>
                                  { ( module.module_badge == 0 ) ? 

                                    <> { module.is_module_completed == true ? dynamic_badge["registerTip0" + module._id +module.start_date] ? <OverlayTrigger placement="top" overlay={
                                      <Tooltip className="dashboard_tooltip"
                                      id={"registerTip0" + module._id +module.start_date}>
                                        <span>
                                        {module?.module_title}
                                        </span>
                                        <br />
                                        <span>
                                        You Missed This Badge
                                        </span>
                                        
                                      </Tooltip>} offset={[0,20]}>
                                      <div  onMouseEnter={() => ShowDynamicBadge("registerTip0" + module._id +module.start_date)} className="transparent_isb_badge" data-testid="transparent_isb_badge"  id={"registerTip0" + module._id +module.start_date} onMouseLeave={() => HideDynamicBadge("registerTip0" + module._id +module.start_date)} >
                                        <div className="isb_badge_text">
                                          <p className="text-center mb-2 white_color" data-testid="transparent_isb_badge_title">{module?.module_title?.length >= 43 ?  module?.module_title.slice(0, 43)+ '...' : module?.module_title }</p>
                                          {/* <p className="text-center white_color">{item?.course_title?.length >= 43 ? item?.course_title.slice(0, 43) + '...' : item?.course_title }</p> */}
                                        </div>
                                      </div>
                                    </OverlayTrigger> :  
                                    <div  onMouseEnter={() => ShowDynamicBadge("registerTip0" + module._id +module.start_date)} className="transparent_isb_badge" data-testid="transparent_isb_badge"  id={"registerTip0" + module._id +module.start_date}>
                                      <div className="isb_badge_text">
                                        <p className="text-center mb-2 white_color" data-testid="transparent_isb_badge_title">{module?.module_title}</p>
                                        {/* <p className="text-center white_color">{item.course_title}</p> */}
                                      </div>
                                    </div>
                                  : dynamic_badge["registerTip0" + module._id +module.start_date] ? <OverlayTrigger placement="top" overlay={
                                    <Tooltip className="dashboard_tooltip"
                                    id={"registerTip0" + module._id +module.start_date}>
                                      <span>
                                      {module?.module_title}
                                      </span>
                                      <br />
                                      {/* <span>
                                      You Missed This Badge
                                      </span> */}
                                      
                                    </Tooltip>} offset={[0,20]}>
                                    <div  onMouseEnter={() => ShowDynamicBadge("registerTip0" + module._id +module.start_date)} className="transparent_isb_badge" data-testid="transparent_isb_badge"  id={"registerTip0" + module._id +module.start_date} onMouseLeave={() => HideDynamicBadge("registerTip0" + module._id +module.start_date)} >
                                      <div className="isb_badge_text">
                                        <p className="text-center mb-2 white_color" data-testid="transparent_isb_badge_title">{module?.module_title?.length >= 43 ?  module?.module_title.slice(0, 43)+ '...' : module?.module_title }</p>
                                        {/* <p className="text-center white_color">{item?.course_title?.length >= 43 ? item?.course_title.slice(0, 43) + '...' : item?.course_title }</p> */}
                                      </div>
                                    </div>
                                  </OverlayTrigger> :  
                                  <div  onMouseEnter={() => ShowDynamicBadge("registerTip0" + module._id +module.start_date)} className="transparent_isb_badge" data-testid="transparent_isb_badge"  id={"registerTip0" + module._id +module.start_date}>
                                    <div className="isb_badge_text">
                                      <p className="text-center mb-2 white_color" >{module?.module_title}</p>
                                      <p className="text-center white_color">{item.course_title}</p>
                                    </div>
                                  </div>}</>
                                  :
                                  <>{
                                    module.is_module_completed == true &&
                                  dynamic_badge["registerTip2" + module._id +module.start_date] ? <OverlayTrigger placement="top" overlay={
                                    <Tooltip className="dashboard_tooltip"
                                    id={"registerTip2" + module._id +module.start_date}>
                                      <span>
                                        {module?.module_title}
                                      </span>
                                      <br />
                                      <span>
                                        You Won The Badge
                                      </span>
                                    
                                    </Tooltip>} offset={[0,20]}>
                                    <div  onClick={()=>{ if (module.badge_sharing === 0) {shareHandler(module, item)}}} onMouseEnter={() => ShowDynamicBadge("registerTip2" + module._id +module.start_date)} data-testid="isb_badge" className="isb_badge"  id={"registerTip2" + module._id +module.start_date} onMouseLeave={() => HideDynamicBadge("registerTip2" + module._id +module.start_date)} >
                                      <div className="isb_badge_text">
                                        <p className="text-center mb-2 white_color">{module?.module_title?.length >= 43 ?  module?.module_title.slice(0, 43)+ '...' : module?.module_title }</p>
                                        {/* <p className="text-center white_color">{item?.course_title?.length >= 43 ? item?.course_title.slice(0, 43) + '...' : item?.course_title }</p> */}
                                      </div>
                                    </div>
                                  </OverlayTrigger> :  
                                  <div  onClick={()=>{ if (module.badge_sharing === 0) {shareHandler(module, item)}}} onMouseEnter={() => ShowDynamicBadge("registerTip2" + module._id +module.start_date)} data-testid="isb_badge" className="isb_badge"  id={"registerTip2" + module._id +module.start_date}>
                                    <div className="isb_badge_text">
                                      <p className="text-center mb-2 white_color">{module?.module_title}</p>
                                      {/* <p className="text-center white_color">{item?.course_title}</p> */}
                                    </div>
                                  </div>
                                  }
                                </>
                                  }
                                  </Fragment>
                              )
                            })}
                          </div>
                        </div>
                        <div className="col-3">
                          {item.certificate_url ? <p data-testid="certificate_title" className="blue_text text-left certificate-class pl-3 mb-0">Certificate</p>:<></>}
                            {item.certificate_url ?  
                            <div className="course_complete_margin_forimage">
                              <img data-testid="certificate_image" className={`img-fluid ${item.certificate_url?"":"opacity-25"}`} src={item.certificate_thumbnail_url}></img>
                            </div>
                            :(item.course_score_percentage >=item.certificate_pass_percentage? 
                              <>
                               <img className={`img-fluid  ${item.course_score_percentage < item.certificate_pass_percentage?"":"opacity-25"}`} src="/images/course_certificate.svg"></img>
                                {item.course_custom_feedback == 1 ?
                                    <> <br /><br /><span>Congratulations! Your <br /> certificate  will be released <br /> on {date_formats.human_date_format(item.program_hard_deadline)}.</span></>
                                    : <> <br /><br /><span className='ms-0'> Congratulations! Just one step left for your certificate.<br /> Share feedback to access it.</span></>}
                              </>:
                              
                            <div className='module_certificate_class p-0' data-testid="courseandcertificateInfo">
                              <span>This course will end on {item.cohort_expiry_date?date_formats.human_date_format(item.cohort_expiry_date):date_formats.human_date_time_format(item.cohort_end_date)}. Your certificate will be issued after achieving {Math.round(scores[index]?.course_certificate_pass_percentage)?Math.round(scores[index]?.course_certificate_pass_percentage):<img src="/images/loading_dots.gif" className="dots_loader" alt="Loader" ></img>} % by {item.cohort_expiry_date?date_formats.human_date_format(item.cohort_expiry_date):date_formats.human_date_time_format(item.cohort_end_date)}.</span>
                              <br/>
                              <br/>
                              <br/>
                              <span>You only need to complete {Math.round(scores[index]?.score_percentage_required_for_certificate)?Math.round(scores[index]?.score_percentage_required_for_certificate):<img src="/images/loading_dots.gif" className="dots_loader" alt="Loader" ></img>}% more to be awarded your certificate.</span>
                              </div>)}
                            <div className='d-flex gap-3 mt-3'>
                            <>
                              { (item.certificate_url) ? 
                              <>
                              <button data-testid="certificate_download" className="btn my_outline_button_cls submit_button fw-bold my_certificate_btn m-0 px-4" onClick={() => downloadPdf(item.certificate_url)} download>Download</button>
                              <button data-testid="certificate_share" className="btn my_btn_cls mt-0 my_share_btn" onClick={()=>shareCourseCertificate(item)}>Share</button>
                              </>
                              :''
                              }
                            </>
                          </div>
                        </div>
                      </div></>:
                      <>
                      
                      <div className="row">
                        <div className="certificatesHead">
                          {item.course_title}   
                        </div>
                        <div className="mt-3">
                          {item.course_takeaway && (item.course_takeaway!='null' )? ( <a className={`btn submit_button fw-bold my_certificate_btn m-0 my_btn_cls top-0 end-0 my_btn_cl2 lesson_page_outline ${date_formats.convert_current_date_utc_format() > item.cohort_end_date?'':'disabled'}`} href={item.course_takeaway} target="_blank"  download>Download Takeaway</a>) : "" }
                        </div>
                      </div>
                        <div className="row mt-4">
                          <div className="col-6">
                            <p className="blue_text badges-class">Your Score</p>
                            {score_flag ? <div className="pl-35"> <div className="spinner-border text-primary spinner-border-sm" role="status"><span className="visually-hidden">Loading...</span></div></div>:
                            <p><b>{scores[index]?.total_earned_score} </b>/ {scores[index]?.total_course_score}  Points</p>}
                            <p className="blue_text badges-class mt-4">Time Spent</p>
                            {score_flag ? <div className="pl-35"> <div className="spinner-border text-primary spinner-border-sm" role="status"><span className="visually-hidden">Loading...</span></div></div>: <p><b>{Math.floor(moment.duration(scores[index]?.total_time_spent,'seconds').asHours())!=0? Math.floor(moment.duration(scores[index]?.total_time_spent,'seconds').asHours()) + ' hrs ' + moment.duration(scores[index]?.total_time_spent,'seconds').minutes() + ' min ' :moment.duration(scores[index]?.total_time_spent,'seconds').minutes() + ' min ' }</b></p>}
                           <div className="col-12 mt-4">
                            <div className="row mobile-module-row">
                              <p className="ml-0 blue_text badges-class">Badges</p>
                              {item.modules.map(module=>{
                                return(
                                    <>
                                    { ( module.module_badge == 0 ) ? 
                                      <> { module.is_module_completed == true ? dynamic_badge["registerTip0" + module._id +module.start_date] ? 
                                      <OverlayTrigger placement="top" overlay={
                                        <Tooltip className="dashboard_tooltip"
                                        id={"registerTip0" + module._id +module.start_date}>
                                          <span>
                                          {module?.module_title}
                                          </span>
                                          <br />
                                          <span>
                                          You Missed This Badge
                                          </span>
                                          
                                        </Tooltip>} offset={[0,20]}>
                                        <div  onMouseEnter={() => ShowDynamicBadge("registerTip0" + module._id +module.start_date)} className="transparent_isb_badge"  id={"registerTip0" + module._id +module.start_date} onMouseLeave={() => HideDynamicBadge("registerTip0" + module._id +module.start_date)} >
                                          <div className="isb_badge_text">
                                            <p className="text-center mb-2 white_color">{module?.module_title?.length >= 43 ?  module?.module_title.slice(0, 43)+ '...' : module?.module_title }</p>
                                            {/* <p className="text-center white_color">{item?.course_title?.length >= 43 ? item?.course_title.slice(0, 43) + '...' : item?.course_title }</p> */}
                                          </div>
                                        </div>
                                      </OverlayTrigger> :  
                                      <div  onMouseEnter={() => ShowDynamicBadge("registerTip0" + module._id +module.start_date)} className="transparent_isb_badge"  id={"registerTip0" + module._id +module.start_date}>
                                        <div className="isb_badge_text">
                                          <p className="text-center mb-2 white_color">{module?.module_title}</p>
                                          {/* <p className="text-center white_color">{item.course_title}</p> */}
                                        </div>
                                      </div>
                                    : dynamic_badge["registerTip0" + module._id +module.start_date] ? <OverlayTrigger placement="top" overlay={
                                      <Tooltip className="dashboard_tooltip"
                                      id={"registerTip0" + module._id +module.start_date}>
                                        <span>
                                        {module?.module_title}
                                        </span>
                                        <br />
                                        {/* <span>
                                        You Missed This Badge
                                        </span> */}
                                        
                                      </Tooltip>} offset={[0,20]}>
                                      <div  onMouseEnter={() => ShowDynamicBadge("registerTip0" + module._id +module.start_date)} className="transparent_isb_badge"  id={"registerTip0" + module._id +module.start_date} onMouseLeave={() => HideDynamicBadge("registerTip0" + module._id +module.start_date)} >
                                        <div className="isb_badge_text">
                                          <p className="text-center mb-2 white_color">{module?.module_title?.length >= 43 ?  module?.module_title.slice(0, 43)+ '...' : module?.module_title }</p>
                                          {/* <p className="text-center white_color">{item?.course_title?.length >= 43 ? item?.course_title.slice(0, 43) + '...' : item?.course_title }</p> */}
                                        </div>
                                      </div>
                                    </OverlayTrigger> :  
                                    <div  onMouseEnter={() => ShowDynamicBadge("registerTip0" + module._id +module.start_date)} className="transparent_isb_badge"  id={"registerTip0" + module._id +module.start_date}>
                                      <div className="isb_badge_text">
                                        <p className="text-center mb-2 white_color">{module?.module_title}</p>
                                        <p className="text-center white_color">{item.course_title}</p>
                                      </div>
                                    </div>}</>
                                    :
                                    <>{
                                      module.is_module_completed == true &&
                                    dynamic_badge["registerTip2" + module._id +module.start_date] ? <OverlayTrigger placement="top" overlay={
                                      <Tooltip className="dashboard_tooltip"
                                      id={"registerTip2" + module._id +module.start_date}>
                                        <span>
                                          {module?.module_title}
                                        </span>
                                        <br />
                                        <span>
                                          You Won The Badge
                                        </span>
                                      
                                      </Tooltip>} offset={[0,20]}>
                                      <div  onClick={()=>{ if (module.badge_sharing === 0) {shareHandler(module, item)}}} onMouseEnter={() => ShowDynamicBadge("registerTip2" + module._id +module.start_date)} className="isb_badge"  id={"registerTip2" + module._id +module.start_date} onMouseLeave={() => HideDynamicBadge("registerTip2" + module._id +module.start_date)} >
                                        <div className="isb_badge_text">
                                          <p className="text-center mb-2 white_color">{module?.module_title?.length >= 43 ?  module?.module_title.slice(0, 43)+ '...' : module?.module_title }</p>
                                          {/* <p className="text-center white_color">{item?.course_title?.length >= 43 ? item?.course_title.slice(0, 43) + '...' : item?.course_title }</p> */}
                                        </div>
                                      </div>
                                    </OverlayTrigger> :  
                                    <div  onClick={()=>{ if (module.badge_sharing === 0) {shareHandler(module, item)}}} onMouseEnter={() => ShowDynamicBadge("registerTip2" + module._id +module.start_date)} className="isb_badge"  id={"registerTip2" + module._id +module.start_date}>
                                      <div className="isb_badge_text">
                                        <p className="text-center mb-2 white_color">{module?.module_title}</p>
                                        {/* <p className="text-center white_color">{item?.course_title}</p> */}
                                      </div>
                                    </div>
                                    }
                                  </>
                                    }
                                    </>
                                )
                              })}
                            </div>
                            </div>
                          </div>
                          
                          <div className="col-6">
                            {item.certificate_url ? <p className="blue_text text-left certificate-class pl-2 mb-0 ">Certificate</p>:<></>}
                              {item.certificate_url ?  <img className={`course_complete_margin_forimage w-100 ${item.certificate_url?"":"opacity-25"}`} src="/images/course_certificate.svg"></img>:
                              (item.course_score_percentage >= item.certificate_pass_percentage? 
                                <>
                                 <img className={`course_complete_margin_forimage w-100  ${item.course_score_percentage < item.certificate_pass_percentage?"":"opacity-25"}`} src="/images/course_certificate.svg"></img>
                                </>:
                              <div className='module_certificate_class p-0'>
                                <span>This course will end on {item.cohort_expiry_date?date_formats.human_date_format(item.cohort_expiry_date):date_formats.human_date_time_format(item.cohort_end_date)}. Your certificate will be issued after achieving {Math.round(scores[index]?.course_certificate_pass_percentage)?Math.round(scores[index]?.course_certificate_pass_percentage):<img src="/images/loading_dots.gif" className="dots_loader" alt="Loader" ></img>}% by {item.cohort_expiry_date?date_formats.human_date_format(item.cohort_expiry_date):date_formats.human_date_time_format(item.cohort_end_date)}.</span>
                                <br/>
                                <br/>
                                <span>You only need to complete {Math.round(scores[index]?.score_percentage_required_for_certificate)?Math.round(scores[index]?.score_percentage_required_for_certificate):<img src="/images/loading_dots.gif" className="dots_loader" alt="Loader" ></img>}% more to be awarded your certificate.</span>
                                </div>)}
                             
                          </div>
                          <div className='d-flex justify-content-center gap-3'>
                              <>
                                { (item.certificate_url) ? 
                                <>
                                <button className="btn my_outline_button_cls submit_button fw-bold my_certificate_btn m-0 px-4" onClick={() => downloadPdf(item.certificate_url)} download>Download</button>
                                <button data-testid="certificate_share_mobile" className="btn my_btn_cls mt-0 my_share_btn" onClick={()=>shareCourseCertificate(item)}>Share</button>
                                </>
                                :''
                                }
                              </>
                            </div>
                        </div></>
            }
                    </div>}
                  </div>
                </Fragment>
              );
            })}

            <div className="visible-hidden mt-3">
              <span>Ending</span>
            </div>

          </div>
          {past_courses.length == 0 &&  <div data-testid="no_certificates_msg" className=" text-center my-5">
            <img height="62px" width="62px" src="./images/no_certificate.svg" data-testid="no_certificates_img"></img>
            <h5 className="past_course_title text-center mt-4" data-testid="no_certificates_yet">No Certificates yet</h5>
            <p className="text-center ash" data-testid="no_certificates_yetcontent">You will see your certificate here once you have completed <br></br>at least one course. Click below to return to the dashboard<br></br>to continue your current course.</p>
            <button data-testid="no_certificates_yetBtn" className="btn my_btn_cls submit_button m-0 px-4 py-2" onClick={() => history.push("/dashboard")}>Go to Dashboard</button>
          </div>}   
            </Tab>
            <Tab eventKey="track-tab" data-testid='programme_certificates_tab' title="Programme Certificate">
            {window.innerWidth>993?
            <div>
          {/* <Slider {...settings}> */}
                {
            learning_track.map((item, index) => {
              return (
                <Fragment key={index}>
                  {/* hide section when programme certificate is null and courses certificate count mismatch */}
                  {(item.learning_track_certificate_url == null || item.learning_track_certificate_url == '') && (item.program_total_courses_count !== item.recieved_certificates_count) ?
                    <>
                      {/* dont show contemt */}
                    </>
                    : <>
                      {/* show content */}
                      <>
                        <div className="programme_certificate_heading">
                          <span>{item?.learning_track_title}</span>
                        </div>
                        <div className="programmeCrossedDeadline_msg">
                       { (item.program_end_date < new Date().getTime())&& (item.learning_track_certificate_url==null || item.learning_track_certificate_url=='') && <span> The programme hard deadline has passed. Unfortunately, your scores do not qualify for a certificate. </span>}
                        </div>
                        <div className="d-flex justify-content-evenly flex-row">
                          {/* if programme certificate is not released and certificate count is matched then blur the programme certificate card */}
                          {(item.learning_track_certificate_url == null || item.learning_track_certificate_url == '') && (item.program_total_courses_count == item.recieved_certificates_count) ?
                            <>
                              <div className="track_container ml-5 mb-5 programmme_certificate_dummy_container">
                                <div className="card border-0 programmme_certificate_dummy_container_child" data-testid="programme_certificate">
                                  <div className="">
                                    <div className="text-center track_img">
                                      <img className='distinction_certificate_blurImg' data-testid="programme_certificate_img " width={"200px"} src="/images/Programme_certificate_thumbnail_latest.png"></img>
                                    </div>
                                    <div className="text-center mt-4">
                                      <div data-testid="programme_certificate_title" className="blue_text" style={{ fontSize: "18px", height: '35px' }}>Certificate of Completion</div>
                                      {/* <div className="ash mt-2">Completed <span className="fw-bold">{item.last_course_completed_date}</span></div> */}
                                    </div>
                                    {(item.program_end_date > new Date().getTime()) && <>
                                    <div className="text-center mt-2 programme_certificate_access_msg">
                                    {item.program_custom_feedback==1?<span>
                                      Congratulations! Your certificate will be released on {date_formats.human_date_format(item.program_hard_deadline)}.
                                    </span>:  <> <span>
                                        Congratulations! Just one step left for your certificate.
                                      </span>
                                      <span>
                                        Share feedback to access it.
                                      </span>
                                      </> }
                                    </div></>}
                                  </div>
                                </div>
                              </div>
                            </>
                            :
                            <>

                              {/* show the programme certificate when it is available and certificate count is matched then display the certificate with download and share buttons */}
                              {(item.learning_track_certificate_url !== null && item.learning_track_certificate_url !== '') && (item.program_total_courses_count == item.recieved_certificates_count) ?
                                <>
                                  <div className="track_container ml-5 mb-5">
                                    <div className="card border-0" data-testid="programme_certificate">
                                      <div className="">
                                        <div className="text-center track_img">
                                          <img className='' data-testid="programme_certificate_img" width={"200px"} src={item.learning_track_certificate_thumbnail_url==null||item.learning_track_certificate_thumbnail_url==''?'/images/Programme_certificate_template.png': item.learning_track_certificate_thumbnail_url }></img>
                                        </div>
                                        <div className="text-center mt-4">
                                          <div data-testid="programme_certificate_title" className="blue_text" style={{ fontSize: "18px", height: '35px' }}>Certificate of Completion</div>
                                          {/* <div className="ash mt-2">Completed <span className="fw-bold">{item.last_course_completed_date}</span></div> */}
                                        </div>
                                        <div className="text-center mt-3 d-flex gap-3">
                                          {(item.learning_track_certificate_url) ?
                                            <>
                                              <button data-testid="programme_certificate_download" className="btn my_outline_button_cls submit_button fw-bold mt-2 rounded-1 download_certificate_myprogms" onClick={() => downloadPdf(item.learning_track_certificate_url)} download>Download</button>
                                              <button data-testid="programme_certificate_share" className="btn my_btn_cls mt-2 my_share_btn download_certificate_myprogms_share" onClick={() => shareProgrameCertificate(item, false)} >Share</button>
                                            </>
                                            :
                                            <a data-testid="programme_certificate_static_url" className="btn my_outline_button_cls submit_button fw-bold rounded-1 mt-2 py-2 px-3" href="/images/track_certificate.svg" download>Download</a>
                                          }
                                          {/* <button className="btn my_btn_cls submit_button ms-3 py-2 px-3">Share</button> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </> :
                                <>
                                  <div className="track_container ml-5 mb-5">
                                    <div className="card border-0" data-testid="programme_certificate">
                                      <div className="">
                                        <div className="text-center track_img">
                                          <img className='' data-testid="programme_certificate_img" width={"200px"} src={item.learning_track_certificate_thumbnail_url==null||item.learning_track_certificate_thumbnail_url==''?'/images/Programme_certificate_template.png': item.learning_track_certificate_thumbnail_url }></img>
                                        </div>
                                        <div className="text-center mt-4">
                                          <div data-testid="programme_certificate_title" className="blue_text" style={{ fontSize: "18px", height: '35px' }}>Certificate of Completion</div>
                                          {/* <div className="ash mt-2">Completed <span className="fw-bold">{item.last_course_completed_date}</span></div> */}
                                        </div>
                                        <div className="text-center mt-3 d-flex gap-3">
                                          {(item.learning_track_certificate_url) ?
                                            <>
                                              <button data-testid="programme_certificate_download" className="btn my_outline_button_cls submit_button fw-bold mt-2 rounded-1 download_certificate_myprogms" onClick={() => downloadPdf(item.learning_track_certificate_url)} download>Download</button>
                                              <button data-testid="programme_certificate_share" className="btn my_btn_cls mt-2 my_share_btn download_certificate_myprogms_share" onClick={() => shareProgrameCertificate(item, false)} >Share</button>
                                            </>
                                            :
                                            <a data-testid="programme_certificate_static_url" className="btn my_outline_button_cls submit_button fw-bold rounded-1 mt-2 py-2 px-3" href="/images/track_certificate.svg" download>Download</a>
                                          }
                                          {/* <button className="btn my_btn_cls submit_button ms-3 py-2 px-3">Share</button> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              }
                            </>
                          }
                          {/* distinction certificate */}
                          {item.is_distinction_certificate_released ? (
                            <div className="track_container ml-5 mb-5">
                              <div className="card border-0" data-testid="programme_certificate">
                                <div className="">
                                  <div className="text-center track_img">
                                    <img className='' data-testid="programme_certificate_img" width={"200px"} src={item?.program_distinction_certificate_thumbnail_url}></img>
                                  </div>
                                  <div className="text-center mt-4">
                                    <div data-testid="programme_certificate_title" className="blue_text" style={{ fontSize: "18px", height: '35px' }}>Certificate of Distinction</div>
                                    {/* <div className="ash mt-2">Completed <span className="fw-bold">{item.last_course_completed_date}</span></div> */}
                                  </div>
                                  <div className="text-center mt-3 d-flex gap-3">
                                    {(item.program_distinction_certificate_url) ?
                                      <>
                                        <button data-testid="programme_certificate_download" className="btn my_outline_button_cls submit_button fw-bold mt-2 rounded-1 download_certificate_myprogms" onClick={() => downloadPdf(item.program_distinction_certificate_url)} download>Download</button>
                                        <button data-testid="programme_certificate_share" className="btn my_btn_cls mt-2 my_share_btn download_certificate_myprogms_share" onClick={() => shareProgrameCertificate(item, true)} >Share</button>

                                      </>
                                      :
                                      <a data-testid="programme_certificate_static_url" className="btn my_outline_button_cls submit_button fw-bold rounded-1 mt-2 py-2 px-3" href="/images/track_certificate.svg" download>Download</a>
                                    }
                                    {/* <button className="btn my_btn_cls submit_button ms-3 py-2 px-3">Share</button> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : ( item.show_distinction_certificate  &&
                            (<div className="track_container ml-5 mb-5 distinction_certificate_dummy_container" >
                              <div className="card border-0 distinction_certificate_dummy_container_child" data-testid="programme_certificate">
                                <div className="">
                                  <div className="text-center track_img">
                                    <img className='distinction_certificate_blurImg' data-testid="programme_certificate_img" width={"200px"} src={(item?.distinction_certificate_thumbnail_url != "")? item.distinction_certificate_thumbnail_url:"/images/Certificate_of_Distinction_V3(1).jpg"}></img>
                                  </div>
                                  {/* dont show the message when programme certificate is not released and required percentage is 0 */}
                                  {(item.learning_track_certificate_url == null || item.learning_track_certificate_url == '') && item.percentage_required_to_get_distinction_certificate == 0 ?
                                    <>
                                    </> :
                                    <>
                                    {/* {show message on certificate when criteria met } */}
                                    {
                                      item.percentage_required_to_get_distinction_certificate >90 &&item.percentage_required_to_get_distinction_certificate <100 ?
                                      <div className="distinction_certificate_msg">
                                        <span>
                                        Congratulations! Your certificate will be released
                                        on {date_formats.human_date_format(item.program_hard_deadline)}.
                                        </span>
                                      </div>
                                      :
                                      <>
                                        {/* hard deadline not crossed and criteria met  */}
                                    {  
                                     item.program_end_date > ((new Date()).getTime())  ?
                                      <div className="distinction_certificate_msg">
                                        {/* <span>
                                          Your Certificate of Distinction will be issued upon achieving {item?.distinction_certificate_issuing_percentage}% before {date_formats.human_date_format(item.program_end_date)}
                                        </span>
                                        <br /><br />
                                        <span>
                                          You only need to complete {item?.percentage_required_to_get_distinction_certificate}% more to be awarded your certificate.
                                        </span> */}
                                        <span>
                                        You need to achieve {item?.percentage_required_to_get_distinction_certificate}% more to be awarded your Certificate of Distinction
                                        </span>
                                      </div>
                                    : <></> }
                                    {/* hard dealine crossed and criteria not met */}
                                    {((item.program_end_date < ((new Date()).getTime())) && item?.program_distinction_certificate_thumbnail_url == null)?
                                     <div className="distinction_certificate_msg_two">
                                      <span>
                                      The programme hard deadline has passed.
                                        </span>
                                        <br/><br/>
                                        <span>
                                        Unfortunately, your scores do not qualify for a distinction certificate. 
                                        </span>
                                      </div>
                                    :<></>}
                                      </>
                                    }
                                    </>
                                  }
                                  <div className="text-center mt-4">
                                    <div data-testid="programme_certificate_title" className="distinction_certificate_title_blurred" style={{ fontSize: "18px", height: '35px' }}>Certificate of Distinction</div>
                                  </div>
                                  <div className="text-center mt-3 d-flex gap-3 distinction_btn_grp">
                                    <button data-testid="programme_certificate_download" className="btn my_outline_button_cls submit_button fw-bold mt-2 rounded-1 download_certificate_myprogms no_cursor" download>Download</button>
                                    <button data-testid="programme_certificate_share" className="btn my_btn_cls mt-2 my_share_btn download_certificate_myprogms_share no_cursor">Share</button>
                                  </div>
                                </div>
                              </div>
                            </div>)
                          )}
                        </div>

                      </>
                    </>
                  }
                </Fragment>
              )
            })}

          {/* </Slider> */}
          </div>: <div>
          {/* <Slider {...settings}> */}
          <div className="row justify-content-center text-center">   
                {
               
learning_track.map((item,index)=>{
  return(
    <Fragment key={index}>
          <div className="programme_certificate_headingMobile">
             <span>{item?.learning_track_title}</span>
          </div>
    <div className="track_container_mobile mt-3" data-testid="programe_certificates_mobile">
    <div className="card border-0">
       <div className="">
         <div className="text-center track_img">
           <img data-testid="programe_certificates_img_mobile" className='' width={"200px"} src="/images/track_certificate.svg"></img>
         </div>
          <div className="mt-4">
          <div data-testid="programe_certificates_title_mobile" className="blue_text" style={{fontSize:"18px",height:'35px',textAlign: "center"}}>{item?.learning_track_title}</div>
          {/* <div className="d-flex justify-content-center ash mt-2"><span>Completed</span> <span className="fw-bold">{item.last_course_completed_date}</span></div> */}
          </div>
          <div className="tmt-3 d-flex justify-content-center gap-3">
            { (item.learning_track_certificate_url) ? 
            <>
            <button data-testid="programe_certificates_download_mobile" className="btn my_outline_button_cls submit_button fw-bold mt-2 rounded-1 download_certificate_myprogms" onClick={() => downloadPdf(item.learning_track_certificate_url)} download>Download</button>
            <button data-testid="programe_certificates_share_mobile" className="btn my_btn_cls mt-2 my_share_btn download_certificate_myprogms_share" onClick={()=>shareProgrameCertificate(item,false)} >Share</button>
            
            </>
              :
              <a data-testid="programe_certificates_dummy_mobile" className="btn my_outline_button_cls submit_button fw-bold rounded-1 mt-2 py-2 px-3" href="/images/track_certificate.svg"  download>Download</a>
            }
            {/* <button className="btn my_btn_cls submit_button ms-3 py-2 px-3">Share</button> */}
          </div>
       </div>
    </div>
   </div>
   {item.is_distinction_certificate_released&&(
  <div className="track_container_mobile mt-3" data-testid="programe_certificates_mobile">
  <div className="card border-0">
     <div className="">
       <div className="text-center track_img">
         <img data-testid="programe_certificates_img_mobile" className='' width={"200px"} src="/images/track_certificate.svg"></img>
       </div>
        <div className="mt-4">
        <div data-testid="programe_certificates_title_mobile" className="blue_text" style={{fontSize:"18px",height:'35px',    textAlign: "center"}}>{item?.learning_track_title}</div>
        {/* <div className="d-flex justify-content-center ash mt-2"><span>Completed</span> <span className="fw-bold">{item.last_course_completed_date}</span></div> */}
        </div>
        <div className="tmt-3 d-flex justify-content-center gap-3">
          { (item.program_distinction_certificate_url) ? 
          <>
          <button data-testid="programe_certificates_download_mobile" className="btn my_outline_button_cls submit_button fw-bold mt-2 rounded-1 download_certificate_myprogms" onClick={() => downloadPdf(item.program_distinction_certificate_url)} download>Download</button>
          <button data-testid="programe_certificates_share_mobile" className="btn my_btn_cls mt-2 my_share_btn download_certificate_myprogms_share" onClick={()=>shareProgrameCertificate(item,true)} >Share</button>
          
          </>
            :
            <a data-testid="programe_certificates_dummy_mobile" className="btn my_outline_button_cls submit_button fw-bold rounded-1 mt-2 py-2 px-3" href="/images/track_certificate.svg"  download>Download</a>
          }
          {/* <button className="btn my_btn_cls submit_button ms-3 py-2 px-3">Share</button> */}
        </div>
     </div>
  </div>
 </div>
   )}
   </Fragment>
  )
})
                }
                </div>
          {/* </Slider> */}
          </div>}
          {learning_track.length == 0 &&  <div className="text-center my-5">
            <img height="62px" width="62px" src="./images/no_certificate.svg"></img>
            <h5 className="past_course_title text-center mt-4">No Certificates yet</h5>
            <p className="text-center ash">You will see your certificate here once you have completed at least <br></br>one programme with a minimum of 65% in each course. Click below<br></br>to return to the dashboard to continue to your current course.</p>
            <button data-testid="go_to_dashboard" className="btn my_btn_cls submit_button m-0 px-4 py-2" onClick={() => history.push("/dashboard")}>Go to Dashboard</button>
          </div>}
            </Tab>
            </Tabs>
        </div>
             
        </>
      )}
      {/* badge sharing */}
      <canvas ref={canvasRef}></canvas>
      <div className="hidden_badge_sharing" id="previewContainer"></div>
    </>
  );
};
export default UserCertificates;
