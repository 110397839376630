

import { useHistory, useLocation } from "react-router-dom";
import { base_url,api_error_message,random_number, admin_maintenance_type } from "../../utilities";
import { useForm } from "react-hook-form";
import axios from "../../axios-interceptor";
import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import withMaintenanceCheck from "../../HigherOrderComponents/maintenancePageChecker";

const CourseView = (props) => {
    const history = useHistory()
    let [modules, setModules] = useState([])
    let [courseName, setCourse] = useState([])

    if(!localStorage.getItem('Admin_Acesstoken'))
    {
      history.push('/adminlogin')
    }
    useEffect(() => {
        // getting LearningTrack schedules list
        var admin_course = localStorage.getItem("admin_course")
        axios.post(base_url + "courses/get_course_details", {
            "course_id": admin_course
        })
            .then((res) => {
                let modules = res.data.result.course.modules[0].filter(item=>{
                  if(item.module_id){
                    return item
                  }
                })
                setModules(modules)
                setCourse(res.data.result.course.title)
            }).catch(err=>{
                console.log(err);
                if(err.response?.statusText=='Unauthorized' || err.response?.data?.statusText=='Unauthorized'){
                  localStorage.clear();
                  history.push('/adminlogin')
                  window.location.reload();
                }else if(err.message == 'Request aborted'){
                  window.location.reload()
                }else{
                  //toast.warn(api_error_message,{toastId:random_number});
window.location.reload()
                }
            })

    }, [])
    const setupCourse = () => {
        history.push('/admindashboard/listcourses')
    }
    return (
        <>
            <Container className='bg-transparent mt-3 pb-0'>
                <h6 className="course-header">Course Successfully Imported .</h6>
            </Container>
            
               
                    <Container className='bg-transparent pb-0 d-flex flex-row justify-content-between'>
                        <div><h3 className="course-header2">{courseName}</h3></div>
                      <div>
                      <button 
                      data-testid='button_element'
                        type="button"
                        className="btn btn-secondary  ms-2 float-end d-flex w-auto admin_button"
                        value="Add Cohort"
                        onClick={setupCourse}
                    ><span className="text-light fw-bold">Setup Course
                    <i class="fa fa-arrow-right ml-2 fw-bolder" aria-hidden="true"></i></span> 
                    </button></div>  
                    </Container>
              
            <div className="progress_bar container bg-transparent">
                {modules.map(item => {
                    return (<>
                        <div className="Module0 p-2 ps-4 h3 custm_shadow_cls progress_img"><img src="/images/moduleprogress.svg" alt="" className="progress_bar_img" />
                            <div className="header" >
                                <div className='d-flex justify-content-between ' key={item.id} >
                                    <h3 > {item?.title}</h3>
                                </div>
                            </div>
                        </div>
                        {item?.lessons && item?.lessons[0]?.map((item) => {
                            return (
                                <>
                                    <div key={item.id}

                                        className=
                                        'd-flex justify-content-between flex-column' >
                                        <div className="module_navs custm_shadow_cls " style={{ cursor: "pointer" }} >

                                            <h5 className='d-flex justify-content-between row p-3 m-auto'>
                                                <div className="col-lg-8 col-md-5 col-sm- 2 text-black text-wrap me-2 " >
                                                    {item.title.length > 55 ? item.title.slice(0, 50) + '..' : item.title}
                                                </div>
                                                <div className="col-lg-1 d-flex gap-1" >


                                                </div>

                                            </h5>


                                        </div>

                                    </div>

                                </>

                            )

                        })}     </>
                    )
                })}



                {/* <div  >
      <div className="content">
        <ul>
          {modules.map((item) => {
            return (
              <>
                <div key={item.id} 
                
                className=
                'd-flex justify-content-between flex-column' >
                  <div className="module_navs custm_shadow_cls " style={{cursor:"pointer"}} >
                  
                    <h5 className='d-flex justify-content-between row p-3 m-auto'>
                      <div className="col-lg-8 col-md-5 col-sm- 2 text-black text-wrap me-2 " >
                       {item.title.length > 55 ? item.title.slice(0, 50)+ '..' : item.title }
                      </div>
                      <div className="col-lg-1 d-flex gap-1" >
                      
                      
                      </div>
                      
                    </h5>
                    

                  </div>

                </div>
             
                </> 
                
            )
            
          })}
                        
        </ul>

      </div>
    </div> */}
            </div>
        </>
    )
}
export default withMaintenanceCheck(CourseView,admin_maintenance_type) 